import React  from 'react';
import './App.css';
import './Model.css';
import { BiCaretRight, BiCaretDown } from "react-icons/bi"

class ModelNewFromWebSiteJobView extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			modelID1: 0,
			modelID2: 0,
			webSite: "",
			modelName: "",
			modelType: "",
			jobSubmittedUnixTimeSec: 0,
			downloadUnixTimeSec1: 0,
			downloadUnixTimeSec2: 0,
			processUnixTimeSec1: 0,
			processUnixTimeSec2: 0,
			status: "",
			email: "",
			fullName: "",
			llmModel: "",
			inputWordCount: 0,
			outputWordCount: 0,
			inputTokenCount: 0,
			outputTokenCount: 0,
			bucketID: "",
			allResultsJson: null,
			fileArrayJson: [],
		};

		this.serverGetModelFromWebSiteData = this.serverGetModelFromWebSiteData.bind(this);
		this.serverDownloadFile = this.serverDownloadFile.bind(this);
		this.downloadAllDocFiles = this.downloadAllDocFiles.bind(this);
		this.findDocJsonObj = this.findDocJsonObj.bind(this);		
		this.onHandleClickArrow = this.onHandleClickArrow.bind(this);
		this.onHandleClickShowText = this.onHandleClickShowText.bind(this);
		this.renderTop = this.renderTop.bind(this);
		this.boldSentenceInText = this.boldSentenceInText.bind(this);
		this.renderOneFile = this.renderOneFile.bind(this);
		this.renderAllFiles = this.renderAllFiles.bind(this);
		this.renderOneDoc = this.renderOneDoc.bind(this);
		this.renderDetails = this.renderDetails.bind(this);
	}
	
	componentDidMount() {
		var modelFromWebSiteID = this.props.modelFromWebSiteID;
		this.serverGetModelFromWebSiteData(modelFromWebSiteID);
	}
	
	async serverGetModelFromWebSiteData(modelFromWebSiteID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getmodelfromwebsitedata?modelfromwebsiteid=" + modelFromWebSiteID;

		var res = await fetch(url);
		var jsonObj = await res.json();

		var modelID1 = jsonObj.modelID1;
		var modelID2 = jsonObj.modelID2;
		var webSite = jsonObj.webSite;
		var modelName = jsonObj.modelName;
		var modelType = jsonObj.modelType;
		var jobSubmittedUnixTimeSec = jsonObj.jobSubmittedUnixTimeSec;
		var downloadUnixTimeSec1 = jsonObj.downloadUnixTimeSec1;
		var downloadUnixTimeSec2 = jsonObj.downloadUnixTimeSec2;
		var processUnixTimeSec1 = jsonObj.processUnixTimeSec1;
		var processUnixTimeSec2 = jsonObj.processUnixTimeSec2;
		var status = jsonObj.status;
		var email = jsonObj.email;
		var fullName = jsonObj.fullName;
		var llmModel = jsonObj.llmModel;
		var inputWordCount = jsonObj.inputWordCount;
		var outputWordCount = jsonObj.outputWordCount;
		var inputTokenCount = jsonObj.inputTokenCount;
		var outputTokenCount = jsonObj.outputTokenCount;

		var bucketID = "modelcreate-" + modelFromWebSiteID;

		var fileText = await this.serverDownloadFile(bucketID, "aaa-results-index.json");
		var allResultsJson = null;
		if (fileText !== "") {
			allResultsJson = JSON.parse(fileText);
		}
		
		await this.downloadAllDocFiles(bucketID, allResultsJson);	

		this.setState({
			modelID1: modelID1,
			modelID2: modelID2,
			webSite: webSite,
			modelName: modelName,
			modelType: modelType,
			jobSubmittedUnixTimeSec: jobSubmittedUnixTimeSec,
			downloadUnixTimeSec1: downloadUnixTimeSec1,
			downloadUnixTimeSec2: downloadUnixTimeSec2,
			processUnixTimeSec1: processUnixTimeSec1,
			processUnixTimeSec2: processUnixTimeSec2,
			status: status,
			email: email,
			fullName: fullName,
			llmModel: llmModel,
			inputWordCount: inputWordCount,
			outputWordCount: outputWordCount,
			inputTokenCount: inputTokenCount,
			outputTokenCount: outputTokenCount,
			bucketID: bucketID,
			allResultsJson: allResultsJson,
		});
	}
	
	async serverDownloadFile(bucketID, filename) {

		var storageBaseURLAPI = this.props.storageBaseURLAPI;
		var url = storageBaseURLAPI + "/" + bucketID + "/" + filename;

		var responseText = "";
		try {
			var res = await fetch(url);
			if (res.status === 200) {
				responseText = await res.text();
			}
		} catch (err) {
		}

		return responseText;
	}
	
	async downloadAllDocFiles(bucketID, allResultsJson) {
		
		if (allResultsJson === null) {
			return;
		}
		
		var i;
		var itemObj;
		var doc;
		var filename;
		var fileText;
		var jsonObj;
		var fileArrayJson = [];
		
		for(i=0; i<allResultsJson.length; i++) {
			itemObj = allResultsJson[i];
			doc = itemObj.doc;
			filename = doc + ".json";
			fileText = await this.serverDownloadFile(bucketID, filename);
			jsonObj = null;
			if (fileText !== "") {
				jsonObj = JSON.parse(fileText);
			}
			if (jsonObj !== null) {
				fileArrayJson.push(jsonObj);
			}
		}
		
		this.setState({
			fileArrayJson: fileArrayJson,
		});
	}

	renderTop() {

		var modelFromWebSiteID = this.props.modelFromWebSiteID;
		var modelID1 = this.state.modelID1;
		var modelID2 = this.state.modelID2;
		var webSite = this.state.webSite;
		var modelName = this.state.modelName;
		var modelType = this.state.modelType;
		var jobSubmittedUnixTimeSec = this.state.jobSubmittedUnixTimeSec;
		var downloadUnixTimeSec1 = this.state.downloadUnixTimeSec1;
		var downloadUnixTimeSec2 = this.state.downloadUnixTimeSec2;
		var processUnixTimeSec1 = this.state.processUnixTimeSec1;
		var processUnixTimeSec2 = this.state.processUnixTimeSec2;
		var status = this.state.status;
		var email = this.state.email;
		var fullName = this.state.fullName;
		var llmModel = this.state.llmModel;
		var inputWordCount = this.state.inputWordCount;
		var outputWordCount = this.state.outputWordCount;
		var inputTokenCount = this.state.inputTokenCount;
		var outputTokenCount = this.state.outputTokenCount;
		var downloadSec = 0;
		var processSec = 0;
		var jsxDownload = <div></div>;
		var jsxProcess = <div></div>;
		
		var submittedString = this.props.getDateTimeStringFromUnixTimeSec(jobSubmittedUnixTimeSec);
		
		if (downloadUnixTimeSec2 > 0) {
			downloadSec = downloadUnixTimeSec2 - downloadUnixTimeSec1;
			var downloadTimeString = this.props.getDateTimeStringFromUnixTimeSec(downloadUnixTimeSec1) + " - " + this.props.getTimeStringFromSeconds(downloadSec);
			jsxDownload = <div><b>Download:</b> {downloadTimeString}</div>;
		}
		
		if (processUnixTimeSec2 > 0) {
			processSec = processUnixTimeSec2 - processUnixTimeSec1;
			var processTimeString = this.props.getDateTimeStringFromUnixTimeSec(processUnixTimeSec1) + " - " + this.props.getTimeStringFromSeconds(processSec);
			jsxProcess = <div><b>Process:</b> {processTimeString}</div>;
		}

		var bucketID = this.state.bucketID;
		var storageBaseURLAPI = this.props.storageBaseURLAPI;
		var questionsURL = storageBaseURLAPI + "/" + bucketID + "/aaa-all-questions.txt";
		
		var llmModelString = "";
		if (llmModel === "llama2-7b") {
			llmModelString = "LLAMA2-7b";
		}
		if (llmModel === "chatgpt-3.5") {
			llmModelString = "ChatGPT 3.5";
		}
		
		var totalWordCount = inputWordCount + outputWordCount;
		
		var totalTokenCount = inputTokenCount + outputTokenCount;
		var usdPerToken = 0.000002;
		var cost = Math.floor(totalTokenCount * usdPerToken*1000)/1000;
		
		return (
			<div className="ModelFromWebSiteViewTopContainer">
				<div><b>Website:</b> {webSite}</div>
				<div><b>Model name:</b> {modelName}</div>
				<div><b>Model ID:</b> {modelID2}</div>
				<div><b>Model type:</b> {modelType}</div>
				<div><b>Status:</b> {status}</div>
				<div><b>User:</b> {fullName} / {email}</div>
				<div><b>Submitted:</b> {submittedString}</div>
				<div><b>LLM model:</b> {llmModelString}</div>
				<div><b>LLM words:</b> {inputWordCount} + {outputWordCount} = {totalWordCount}</div>
				{(inputTokenCount > 0) && (
					<div><b>LLM tokens:</b> {inputTokenCount} + {outputTokenCount} = {totalTokenCount} - ChatGPT 3.5 cost USD: {cost}</div>
				)}
				{jsxDownload}
				{jsxProcess}
				
				<div><b>Job ID:</b> {modelFromWebSiteID}</div>
				<div><b>Questions:</b> <a href={questionsURL} target="_blank">link</a></div>
				<div style={{height: "10px"}}></div>
				<hr/>
			</div>
		);
	}
	
	findDocJsonObj(doc1) {
		
		var i;
		var itemObj;
		var doc2;
		var fileArrayJson = this.state.fileArrayJson;
		
		for(i=0; i<fileArrayJson.length; i++) {
			itemObj = fileArrayJson[i];
			doc2 = itemObj.doc;
			if (doc1 === doc2) {
				return itemObj;
			}
		}
		
		return null;
	}
	
	onHandleClickArrow(doc1, fileIndex) {
		
		var i;
		var itemObj;
		var doc2;
		var filesArray;
		var itemObj2;
		var fileArrayJson = this.state.fileArrayJson;
		
		for(i=0; i<fileArrayJson.length; i++) {
			itemObj = fileArrayJson[i];
			doc2 = itemObj.doc;
			if (doc1 === doc2) {
				filesArray = itemObj.files;
				itemObj2 = filesArray[fileIndex];
				if (itemObj2.openYN) {
					itemObj2.openYN = false;
				} else {
					itemObj2.openYN = true;
				}
			}
		}
		
		this.setState({fileArrayJson: fileArrayJson});
	}
	
	onHandleClickShowText(doc1, fileIndex) {
		
		var i;
		var itemObj;
		var doc2;
		var filesArray;
		var itemObj2;
		var fileArrayJson = this.state.fileArrayJson;
		
		for(i=0; i<fileArrayJson.length; i++) {
			itemObj = fileArrayJson[i];
			doc2 = itemObj.doc;
			if (doc1 === doc2) {
				filesArray = itemObj.files;
				itemObj2 = filesArray[fileIndex];
				if (itemObj2.openTextYN) {
					itemObj2.openTextYN = false;
				} else {
					itemObj2.openTextYN = true;
				}
			}
		}
		
		this.setState({fileArrayJson: fileArrayJson});
	}
	
	boldSentenceInText(sentence, fileText1) {

		var lineArray = this.props.separateTextIntoArrayOfLines(fileText1);
		var jsxArray = [];
		
		var i;
		var len = lineArray.length;
		var oneLineText;
		var variable = "sentence";
		var key;
		var className = "";
		
		for(i=0; i<len; i++) {

			key = variable + "-" + i;
			oneLineText = lineArray[i];

			var index = oneLineText.indexOf(sentence);
			
			if (index >= 0) {
			
				var text1 = oneLineText.substring(0, index);
				var text2 = oneLineText.substring(index, index+sentence.length);
				var text3 = oneLineText.substring(index+sentence.length, oneLineText.length);

				jsxArray.push(<div key={key} className={className}>{text1} <b>{text2}</b> {text3}</div>);

			} else {
				if (oneLineText === "") {
					jsxArray.push(<div key={key} style={{height: "10px"}}></div>);
				} else {
					jsxArray.push(<div key={key} className={className}>{oneLineText}</div>);
				}
			}
		}
		
		return (<div>{jsxArray}</div>);
	}

	renderOneFile(index1, index2, doc, itemObj) {
		
		var sentence = itemObj.sentence;
		var distance = itemObj.distance;
		var answer = itemObj.answer;
		var fileText = itemObj.text;
		var openYN = itemObj.openYN;
		var openTextYN = itemObj.openTextYN;
		var key = index1 + "-" + index2;
		var fileTextBr = this.boldSentenceInText(sentence, fileText);
		
		return (
			<div key={key}>
				<div
				  className="ModelFromWebSiteViewFileContainer"
				  onClick={() => this.onHandleClickArrow(doc, index2)}
				>
					<div className="ModelFromWebSiteViewFileIcon">
						{openYN && (<BiCaretDown style={{ color: "blue", width: "20px", height: "20px" }} />)}
						{!openYN && (<BiCaretRight style={{ color: "blue", width: "20px", height: "20px" }} />)}
					</div>
					<div className="ModelFromWebSiteViewFileText">{sentence} - {distance}</div>
				</div>
				{openYN && (
					<div>
						<div className="ModelFromWebSiteViewFileAnswer">{answer}</div>
						{openTextYN && (
							<div>
								<div className="ModelFromWebSiteViewFileShowHideLargeText" onClick={() => this.onHandleClickShowText(doc, index2)}>hide source text</div>
								<div className="ModelFromWebSiteViewFileLargeText">{fileTextBr}</div>
							</div>
						)}
						{!openTextYN && (
							<div className="ModelFromWebSiteViewFileShowHideLargeText" onClick={() => this.onHandleClickShowText(doc, index2)}>show source text</div>
						)}
					</div>
				)}
			</div>
		);
	}

	renderAllFiles(index, doc, filesArray) {

		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<filesArray.length; i++) {
			itemObj = filesArray[i];
			jsxCode = this.renderOneFile(index, i, doc, itemObj);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}

	renderOneDoc(index, itemObj) {
		
		var doc = itemObj.doc;
		var question = itemObj.question;
		var search = itemObj.search;
		var jsonObj = this.findDocJsonObj(doc);
		var answerFinal = "";
		var filesArray = [];
		var inputWordCount = 0;
		var outputWordCount = 0;
		var inputTokenCount = 0;
		var outputTokenCount = 0;
		
		var i;
		var oneSearch;
		var searchText = "";

		if (search.length > 0) {
			oneSearch = search[0];
			searchText = oneSearch;		
			for(i=1; i<search.length; i++) {
				oneSearch = search[i];
				searchText = searchText + ", " + oneSearch;		
			}
		}
				
		if (jsonObj !== null) {
			answerFinal = jsonObj.answerFinal;
			filesArray = jsonObj.files;
			inputWordCount = jsonObj.inputWordCount;
			outputWordCount = jsonObj.outputWordCount;
			inputTokenCount = jsonObj.inputTokenCount;
			outputTokenCount = jsonObj.outputTokenCount;
		}
		
		var llmString = "LLM words: " + inputWordCount + "/" + outputWordCount;
		if (inputTokenCount > 0) {
			llmString = llmString + " - LLM tokens: " + inputTokenCount + "/" + outputTokenCount;
		}
				
		return (
			<div key={index} className="ModelFromWebSiteViewTopContainer">
				<div><b>{doc} - {question}</b> </div>
				<div>{answerFinal}</div>
				<div style={{height: "10px"}}></div>
				<div className="ModelFromWebSiteViewSearch">{searchText}</div>
				{this.renderAllFiles(index, doc, filesArray)}
				<div style={{height: "10px"}}></div>
				<div className="ModelFromWebSiteViewSearch">{llmString}</div>
				<div style={{height: "10px"}}></div>
				<hr/>
			</div>
		);
	}

	renderDetails() {

		var allResultsJson = this.state.allResultsJson;
		if (allResultsJson === null) {
			return;
		}

		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<allResultsJson.length; i++) {
			itemObj = allResultsJson[i];
			jsxCode = this.renderOneDoc(i, itemObj);
			jsxArray.push(jsxCode);
		}

		return jsxArray;
	}
	
	render() {
		
		var modelFromWebSiteID = this.props.modelFromWebSiteID;
		
		return (
			<div>
				<div style={{height: "20px"}}></div>
				{this.renderTop()}
				{this.renderDetails()}
				<div style={{height: "20px"}}></div>
			</div>
		);
	}
}

export default ModelNewFromWebSiteJobView;

