import React  from 'react';
import './App.css';
import './Page.css';

import { useNavigate } from "react-router-dom";

class PageNewSubfolder extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			subfolder: "",
		};
		
		this.onKeyDown = this.onKeyDown.bind(this);
		this.normalizeFilename = this.normalizeFilename.bind(this);
		this.onSubfolderChanged = this.onSubfolderChanged.bind(this);
		this.serverCreatePageSubfolder = this.serverCreatePageSubfolder.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	componentDidMount() {
	}
	
	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	normalizeFilename(filename1) {
		
		var i;
		var ch;
		var filename2 = "";
		var allowedYN;
		
		filename1 = filename1.toLowerCase();
		
		for(i=0; i<filename1.length; i++) {

			ch = filename1.charAt(i);

			allowedYN = false;
			if (ch === "_") {
				allowedYN = true;
			}
			if ((ch >= "0") && (ch <= "9")) {
				allowedYN = true;
			}
			if ((ch >= "a") && (ch <= "z")) {
				allowedYN = true;
			}
			
			if (allowedYN) {
				filename2 = filename2 + ch;
			}
		}
		
		return filename2;
	}

	onSubfolderChanged(value) {
		var subfolder = this.normalizeFilename(value);
		this.setState({subfolder: subfolder});
	}
	
	async serverCreatePageSubfolder(modelID, folder, subfolder) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/createpagesubfolder?modelid=" + modelID;
		url = url + "&folder=" + encodeURIComponent(folder);
		url = url + "&subfolder=" + encodeURIComponent(subfolder);
		console.log(url);
		
		const res = await fetch(url);
		var responseText = await res.text();

		if (responseText === "0") {
			this.props.showToast("New page created");
		} else {
			this.props.showToast("New page NOT created");
		}
		const navigate = this.props.navigate;
  		navigate("/pages");
	}
		
	onClickSaveButton() {
		
		var subfolder = this.state.subfolder;
		subfolder = subfolder.trim();
		if (subfolder === "") {
			this.props.showToast("Page name cannot be empty");
			return;
		}

		var modelID = this.props.modelID;
		var pageFolder = this.props.pageFolder;
		this.serverCreatePageSubfolder(modelID, pageFolder, subfolder);

		var title;
		var changeJsonObj;
		var changeJSON;

		title = "New page: " + pageFolder + "/" + subfolder;
		changeJsonObj = {};
		changeJsonObj.section = "pages";
		changeJsonObj.operation = "newpage";
		changeJsonObj.pageFolder = pageFolder;
		changeJsonObj.pageSubfolder = subfolder;
		var changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/pages");
	}
	
	render() {

		var pageFolder = this.props.pageFolder;

		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">New page</div>
				<div className="ViewTopText">Enter page name and press Create Subfolder bottun</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">Page Info</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="PageNewSubfolderFieldset">
							<legend className="PageNewSubfolderLegend">Folder</legend> 
							<input
								className="PageNewSubfolderInput" 
								id="folder" 
								type="text" 
								value={pageFolder}
								disabled={true}
							/>
						</fieldset>
						<div style={{height: "20px"}}></div>
						<fieldset className="PageNewSubfolderFieldset">
							<legend className="PageNewSubfolderLegend">Page</legend> 
							<input
								className="PageNewSubfolderInput" 
								id="subfolder" 
								type="text" 
								autoFocus={true}
								value={this.state.subfolder}
								onChange={(event) => this.onSubfolderChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>Cancel</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>Create Page</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function PageNewSubfolderFunction(props) {
	const navigate = useNavigate();
	return <PageNewSubfolder {...props} navigate={navigate} />;
}

