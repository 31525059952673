import React  from 'react';
import './App.css';
import './Document.css';
import DocumentLang from './DocumentLang.json';

import { useNavigate } from "react-router-dom";

class DocumentNew extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			documentFilename: "",
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.normalizeFilename = this.normalizeFilename.bind(this);
		this.onDocumentFilenameChanged = this.onDocumentFilenameChanged.bind(this);
		this.serverInsertDocumentRecord = this.serverInsertDocumentRecord.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}
	
	normalizeFilename(filename1) {
		
		var i;
		var ch;
		var filename2 = "";
		var allowedYN;
		
		filename1 = filename1.toLowerCase();
		
		for(i=0; i<filename1.length; i++) {

			ch = filename1.charAt(i);

			allowedYN = false;
			if ((ch >= "0") && (ch <= "9")) {
				allowedYN = true;
			}
			if ((ch >= "a") && (ch <= "z")) {
				allowedYN = true;
			}
			
			if (allowedYN) {
				filename2 = filename2 + ch;
			}
		}
		
		return filename2;
	}

	onDocumentFilenameChanged(value) {
		var filename = this.normalizeFilename(value);
		this.setState({documentFilename: filename});
	}
	
	async serverInsertDocumentRecord(modelID, filename) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/createdocument?modelid=" + modelID;
		url = url + "&filename=" + encodeURIComponent(filename);
		console.log(url);
		
		const res = await fetch(url);
		var responseText = await res.text();

		if (responseText === "0") {
			this.props.showToast(this.props.getLang(DocumentLang, "newDocumentCreated"));
		} else {
			this.props.showToast(this.props.getLang(DocumentLang, "newDocumentNotCreated"));
		}
		const navigate = this.props.navigate;
  		navigate("/docs");
	}
		
	onClickSaveButton() {
		
		var documentFilename = this.state.documentFilename;
		if (documentFilename === "") {
			this.props.showToast("Document filename cannot be empty");
			return;
		}

		var modelID = this.props.modelID;
		this.serverInsertDocumentRecord(modelID, documentFilename);

		var title = "New document: " + documentFilename;
		var changeJsonObj = {};
		changeJsonObj.section = "docs";
		changeJsonObj.operation = "new";
		changeJsonObj.filename = documentFilename;
		var changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/docs");
	}
	
	render() {		

		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(DocumentLang, "newDocument")}</div>
				<div className="ViewTopText">{this.props.getLang(DocumentLang, "newDocumentInfoText")}</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(DocumentLang, "documentInfo")}</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<p></p>
						<fieldset className="DocumentNewFieldset">
							<legend className="DocumentNewLegend">{this.props.getLang(DocumentLang, "documentFilename")}</legend> 
							<input
								className="DocumentNewInput" 
								id="Document" 
								type="text" 
								autoFocus={true}
								value={this.state.documentFilename}
								onChange={(event) => this.onDocumentFilenameChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(DocumentLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(DocumentLang, "save")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function DocumentNewFunction(props) {
	const navigate = useNavigate();
	return <DocumentNew {...props} navigate={navigate} />;
}

