import React  from 'react';
import './App.css';
import './Page.css';

import { useNavigate } from "react-router-dom";

class PageEditPronounce extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			fileText: "",
			lineText: "",
			filenameMax: -1,
			dataLoadedYN: false,
		};

		this.onKeyDown = this.onKeyDown.bind(this);		
		this.downloadTextFile = this.downloadTextFile.bind(this);
		this.serverGetPageFilenameMax = this.serverGetPageFilenameMax.bind(this);
		this.serverSetPageTextFile = this.serverSetPageTextFile.bind(this);
		this.serverPronounceGenerateMP3Files = this.serverPronounceGenerateMP3Files.bind(this);
		this.onTextChanged = this.onTextChanged.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	componentDidMount() {

		var baseURLAPI = this.props.baseURLAPI;
		var modelID = this.props.modelID;
		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;
		
		var i;
		var answerArray = [];
		for(i=0; i<10; i++) {
			answerArray.push("");
		}
		
		this.setState({
			answerArray: answerArray,
		});

		var newYN = this.props.newYN;
		if (newYN) {
			this.serverGetPageFilenameMax(modelID, pageFolder, pageSubfolder);
			return;
		}

		var pageFilenameExt = this.props.pageFilenameExt;
		var pageURL = baseURLAPI + "/model/" + modelID + "/pages/" + pageFolder + "/" + pageSubfolder;
		var fileURL = pageURL + "/" + pageFilenameExt;
		this.downloadTextFile(fileURL);
	}

	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	async downloadTextFile(fileURL) {

		var res = await fetch(fileURL);
		var responseText = "";

		try {
			responseText = await res.text();
		} catch {
			responseText = "";
		}

		if (res.status !== 200) {
			responseText = "";
		}
		
		if (responseText === "") {
			return;
		}
		
		var jsonObj = JSON.parse(responseText);
		
		var lineText = jsonObj.text;

		this.setState({
			fileText: responseText,
			lineText: lineText,
			dataLoadedYN: true,
		});
	}
	
	async serverGetPageFilenameMax(modelID, pageFolder, pageSubfolder) {

		var baseURLAPI = this.props.baseURLAPI;
		var folder = pageFolder + "/" + pageSubfolder;
		var url = baseURLAPI + "/getpagefilenamemax?modelid=" + modelID;
		url = url + "&folder=" + encodeURIComponent(folder);

		var filenameMax = -1;

		var res = await fetch(url);
		var responseText = "";

		try {
			responseText = await res.text();
			filenameMax = parseInt(responseText);
		} catch {
			filenameMax = -1;
		}

		this.setState({
			filenameMax: filenameMax,
			dataLoadedYN: true,
		});
		
	}
	
	async serverSetPageTextFile(modelID, pageFolder, pageSubfolder, pageFilenameExt, fileText) {
		
		var baseURLAPI = this.props.baseURLAPI;
		var folder = pageFolder + "/" + pageSubfolder;
		var url = baseURLAPI + "/setpagetextfile?modelid=" + modelID;
		url = url + "&folder=" + encodeURIComponent(folder);
		url = url + "&filename=" + encodeURIComponent(pageFilenameExt);
		
		var formData = new FormData();
		formData.append("filetext", fileText);

		const res = await fetch(url, {
			method: 'POST',
			body: formData,
		});		

		await res.text();

		this.props.showToast("Pronounce section saved");
		const navigate = this.props.navigate;
  		navigate("/page");
	}
	
	
	async serverPronounceGenerateMP3Files(modelID, pageFolder, pageSubfolder, fileText) {

		var bucketName = "modelmedia-" + modelID + "-lesson-" + pageFolder + "_" + pageSubfolder;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/pronouncegeneratemp3files?bucketname=" + encodeURIComponent(bucketName);
		
		var formData = new FormData();
		formData.append("filetext", fileText);

		const res = await fetch(url, {
			method: 'POST',
			body: formData,
		});		

		await res.text();
	}

	onTextChanged(value) {
		this.setState({lineText: value});
	}

	async onClickSaveButton() {
		
		var modelID = this.props.modelID;
		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;

		var newYN = this.props.newYN;
		var pageFilenameExt;
		var uniqueID;

		if (newYN) {
			var filenameMax = this.state.filenameMax;
			filenameMax = filenameMax + 1;
			pageFilenameExt = filenameMax + ".pronjson";
			var dateObj = new Date();
			var unixTimeSec = Math.floor(dateObj.getTime() / 1000);
			uniqueID = modelID + "-" + unixTimeSec;
		} else {
			pageFilenameExt = this.props.pageFilenameExt;
			uniqueID = this.state.uniqueID;
		}

		var lineText = this.state.lineText;
		
		var jsonObj = {};
		jsonObj.language = "en";
		jsonObj.text = lineText;
		
		var fileText = JSON.stringify(jsonObj);
		await this.serverPronounceGenerateMP3Files(modelID, pageFolder, pageSubfolder, lineText);
		this.serverSetPageTextFile(modelID, pageFolder, pageSubfolder, pageFilenameExt, fileText);

		var title;
		var changeJsonObj;
		var changeJSON;

		if (newYN) {
			title = "New page pronounce section: " + pageFolder + "/" + pageSubfolder;
			changeJsonObj = {};
			changeJsonObj.section = "pages";
			changeJsonObj.operation = "newpronouncesection";
			changeJsonObj.pageFolder = pageFolder;
			changeJsonObj.pageSubfolder = pageSubfolder;
			changeJsonObj.fileText = fileText;
			var changeJSON = JSON.stringify(changeJsonObj);
			this.props.serverInsertChangeLogRecord(title, changeJSON);
		} else {
			title = "Edit page pronounce section: " + pageFolder + "/" + pageSubfolder;
			changeJsonObj = {};
			changeJsonObj.section = "pages";
			changeJsonObj.operation = "editpronouncesection";
			changeJsonObj.pageFolder = pageFolder;
			changeJsonObj.pageSubfolder = pageSubfolder;
			changeJsonObj.fileText = fileText;
			var changeJSON = JSON.stringify(changeJsonObj);
			this.props.serverInsertChangeLogRecord(title, changeJSON);
		}
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/page");
	}
	
	render() {
		
		var dataLoadedYN = this.state.dataLoadedYN;
		if (!dataLoadedYN) {
			return null;
		}

		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;

		return (
			<div className="PageEditPronounceContainer">
				<div style={{height: "10px"}}></div>
				<div className="PageEditPronounceTitle">{pageFolder}_{pageSubfolder}</div>
				<div style={{height: "20px"}}></div>
				<textarea
					className="PageEditPronounceInput" 
					id="txtFileText" 
					type="text" 
					rows={25}
					value={this.state.lineText}
					autoFocus={true}
					onChange={(event) => this.onTextChanged(event.target.value)}
				/>
				<div style={{height: "20px"}}></div>
				<div className="ViewContainerButtons">
					<div style={{width: "40px"}}></div>
					<button className="ViewCancelButton" onClick={this.onClickCancelButton}>Cancel</button>
					<div style={{width: "20px"}}></div>
					<button className="ViewSaveButton" onClick={this.onClickSaveButton}>Save</button>
				</div>
				<div style={{height: "20px"}}></div>
			</div>
		);
	}
}

export default function PageEditPronounceFunction(props) {
	const navigate = useNavigate();
	return <PageEditPronounce {...props} navigate={navigate} />;
}
