import React  from 'react';
import './App.css';
import { useNavigate } from "react-router-dom";

class PageListToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(page) {		
		const navigate = this.props.navigate;
  		navigate(page);
	}
	
	renderLink(renderYN, linkTitle, linkValue) {
		
		if (!renderYN) {
			return;
		}

		var classNameString = "PageListToolbarLink";
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	render() {

		var modelName = this.props.modelName;		
		var newQuestionSampleString = "New folder";
		
		return (
			<div className="PageListToolbarContainer1">
				<div className="PageListToolbarLeft">
					<div 
						className="PageListToolbarLink"
						onClick={() => this.handleSelectLink("/selectmodel")}
					>
						{modelName}
					</div>
				</div>
				<div className="PageListToolbarRight">
					<div className="PageListToolbarContainer2">
						{this.renderLink(true, newQuestionSampleString, "/pages/newfolder")}
					</div>
				</div>
			</div>
		);
	}
}

export default function PageListToolbarFunction(props) {
	const navigate = useNavigate();
	return <PageListToolbar {...props} navigate={navigate} />;
}

