import React  from 'react';
import './App.css';
import { useNavigate } from "react-router-dom";

class DocumentViewToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(page) {		
	
		if (page === "delete") {
			this.props.handleDeleteDocument();
			return;
		}
	
		const navigate = this.props.navigate;
  		navigate(page);
	}
	
	renderLink(renderYN, linkTitle, linkValue) {
		
		if (!renderYN) {
			return;
		}

		var classNameString = "DocumentViewToolbarLink";
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	render() {

		var modelName = this.props.modelName;		
		var editQuestionsString = "Edit questions";
		var editTextString = "Edit text";
		var deleteString = "Delete";
		
		return (
			<div className="DocumentViewToolbarContainer1">
				<div className="DocumentViewToolbarLeft">
					<div 
						className="DocumentViewToolbarLink"
						onClick={() => this.handleSelectLink("/selectmodel")}
					>
						{modelName}
					</div>
				</div>
				<div className="DocumentViewToolbarRight">
					<div className="DocumentViewToolbarContainer2">
						{this.renderLink(true, editQuestionsString, "/docs/editquestions")}
						<div className="DocumentViewToolbarText">|</div>
						{this.renderLink(true, editTextString, "/docs/edittext")}
						<div className="DocumentViewToolbarText">|</div>
						{this.renderLink(true, deleteString, "delete")}
					</div>
				</div>
			</div>
		);
	}
}

export default function DocumentViewToolbarFunction(props) {
	const navigate = useNavigate();
	return <DocumentViewToolbar {...props} navigate={navigate} />;
}

