import React  from 'react';
import './App.css';
import './UserLesson.css';
import { BsCheck, BsX } from "react-icons/bs"

class UserLessonLogView extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			userLessonListJsonObj: null,
			index: 0,
			count: 10,
		};

		this.serverGetUserLessonLogList = this.serverGetUserLessonLogList.bind(this);
		this.serverGetLessonData = this.serverGetLessonData.bind(this);
		this.handlePrev = this.handlePrev.bind(this);
		this.handleNext = this.handleNext.bind(this);
		this.pronounceGetColloredTitle = this.pronounceGetColloredTitle.bind(this);
		this.renderOneAnswer = this.renderOneAnswer.bind(this);
		this.renderAllAnswers = this.renderAllAnswers.bind(this);
		this.renderPrevNext = this.renderPrevNext.bind(this);
	}
	
	componentDidMount() {
		this.serverGetUserLessonLogList(0);
	}

	async serverGetUserLessonLogList(index) {

		this.setState({
			userLessonListJsonObj: null,
		});
		
		var studentID = this.props.studentID;
		var count = this.state.count;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getuserlessonloglist?userid=" + studentID;
		url = url + "&index=" + index;
		url = url + "&count=" + count;

		const res = await fetch(url);

		var userLessonListJsonObj = null;
		try {
			userLessonListJsonObj = await res.json();
		} catch (err) {
			userLessonListJsonObj = null;
		}
		
		var i;
		var itemObj;
		var jsonObj;
		var lessonID;
		var lessonName;
		
		if (userLessonListJsonObj !== null) {
			for(i=0; i<userLessonListJsonObj.length; i++) {
				itemObj = userLessonListJsonObj[i];
				lessonID = itemObj.lessonID;
				jsonObj = await this.serverGetLessonData(lessonID);
				lessonName = jsonObj.lessonSubfolder;
				lessonName = lessonName.replaceAll("_", " ");
				itemObj.lessonName = lessonName;
				userLessonListJsonObj[i] = itemObj;
			}
		}
		
		this.setState({
			userLessonListJsonObj: userLessonListJsonObj,
		});
	}
	
	async serverGetLessonData(lessonID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getlessondata?lessonid=" + lessonID;

		const res = await fetch(url);
		var jsonObj = null;
		try {
			jsonObj = await res.json();
		} catch (err) {
			jsonObj = null;
		}

		return jsonObj;
	}

	handlePrev() {
		var index = this.state.index - 10;
		this.setState({index: index});
		this.serverGetUserLessonLogList(index);
	}

	handleNext() {
		var index = this.state.index + 10;
		this.setState({index: index});
		this.serverGetUserLessonLogList(index);
	}
	
	pronounceGetColloredTitle(userLessonLogID, title, isLetterCorrect) {

		var i;
		var ch1;
		var ch2;
		var color;
		var key;
		var jsxCode;
		var jsxArray = [];

		for(i=0; i<title.length; i++) {
			key = userLessonLogID + "-" + i;
			ch1 = title.charAt(i);
			ch2 = isLetterCorrect.charAt(i);
			color = "black";
			if (ch2 === "0") {
				color = "red";
			}
			if (ch2 === "1") {
				color = "green";
			}
			var style = { color: color };
			jsxCode = <span key={key} style={style}>{ch1}</span>
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}

	renderOneAnswer(itemObj) {
		
		var storageBaseURLAPI = this.props.storageBaseURLAPI;
		var studentID = this.props.studentID;
		var userLessonLogID = itemObj.userLessonLogID;
		var activity = itemObj.activity;
		var lessonID = itemObj.lessonID;
		var questionText = itemObj.questionText;
		var answerText = itemObj.answerText;
		var correct = itemObj.correct;
		var filename = itemObj.filename;
		var unixTimeSec = itemObj.unixTimeSec;
		
		var timeString = this.props.getDateTimeStringFromUnixTimeSec(unixTimeSec);
		var lessonName = itemObj.lessonName;
		
		if (activity === "pronounce") {
			var bucketName = "pronouncelog-" + studentID;
			var url = "";
			var audioJsxCode = null;
			if (filename !== "") {
				url = storageBaseURLAPI + "/" + bucketName + "/" + filename;
				audioJsxCode = <audio src={url} controls />
			}
			var answerTextJsxCode = this.pronounceGetColloredTitle(userLessonLogID, questionText, answerText);
			return (
				<tr key={userLessonLogID}>
					<td className="UserLessonLogViewPronounceItem">{correct}%</td>
					<td className="UserLessonLogViewQuestionItem">{questionText}</td>
					<td className="UserLessonLogViewAnswerItem">{answerTextJsxCode}<br/>{audioJsxCode}</td>
					<td className="UserLessonLogViewLessonItem">{lessonName}</td>
					<td className="UserLessonLogViewDateTimeItem">{timeString}</td>
				</tr>
			);
		}

		if (activity === "question") {
			var iconJsxCode;
			if (correct === 1) {
				iconJsxCode = <BsCheck style={{ color: "green", width: "25px", height: "25px" }} />
			} else {
				iconJsxCode = <BsX style={{ color: "red", width: "25px", height: "25px" }} />
			}
			return (
				<tr key={userLessonLogID}>
					<td className="UserLessonLogViewCorrectItem">{iconJsxCode}</td>
					<td className="UserLessonLogViewQuestionItem">{questionText}</td>
					<td className="UserLessonLogViewAnswerItem">{answerText}</td>
					<td className="UserLessonLogViewLessonItem">{lessonName}</td>
					<td className="UserLessonLogViewDateTimeItem">{timeString}</td>
				</tr>
			);
		}
	}

	renderAllAnswers() {

		var userLessonListJsonObj = this.state.userLessonListJsonObj;

		var length = userLessonListJsonObj.length;
		if (length === 0) {
			return (
				<div className="UserLessonLogListText">
					No answers found.
				</div>
			);
		}
		
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<length; i++) {
			itemObj = userLessonListJsonObj[i];
			jsxCode = this.renderOneAnswer(itemObj);
			jsxArray.push(jsxCode);
		}
		
		return (
			<table className="ListContainer2">
				<tbody>
					<tr>
						<td className="UserLessonLogViewCorrectHeader"></td>
						<td className="UserLessonLogViewQuestionHeader"></td>
						<td className="UserLessonLogViewAnswerHeader"></td>
						<td className="UserLessonLogViewLessonHeader"></td>
						<td className="UserLessonLogListDateTimeHeader"></td>
					</tr>
					{jsxArray}
				</tbody>
			</table>
		);
	}

	renderPrevNext() {
		
		var userLessonListJsonObj = this.state.userLessonListJsonObj;

		var prevYN = false;
		var nextYN = false;
		
		var index = this.state.index;
		
		if (index > 0) {
			prevYN = true;
		}
		
		if (userLessonListJsonObj.length === this.state.count) {
			nextYN = true;
		}
		
		return (
			<div className="ListContainer3">
				{prevYN && (<div 
					className="ListPrevNextLink"
					onClick={() => this.handlePrev()}
				>
					&lt;&lt; Previous
				</div>)}
				{!prevYN && (<div 
					className="ListPrevNextLinkDisabled"
				>
					&lt;&lt; Previous
				</div>)}
				<div className="ListPrevNextLinkDisabled">|</div>
				{nextYN && (<div 
					className="ListPrevNextLink"
					onClick={() => this.handleNext()}
				>
					Next &gt;&gt;
				</div>)}
				{!nextYN && (<div 
					className="ListPrevNextLinkDisabled"
				>
					Next &gt;&gt;
				</div>)}
			</div>
		);
	}
	
	render() {
		
		var userLessonListJsonObj = this.state.userLessonListJsonObj;
		if (userLessonListJsonObj === null) {
			return;
		}

		var studentID = this.props.studentID;
		var studentFullName = this.props.studentFullName;
		
		return (
			<div className="UserLessonLogViewTopContainer">
				<div style={{height: "10px"}}></div>
				<div className="UserLessonLogViewFullName">{studentFullName}</div>
				<div style={{height: "10px"}}></div>
				{this.renderAllAnswers()}
				<div style={{height: "40px"}}></div>
				{this.renderPrevNext()}
			</div>
		);
	}
}

export default UserLessonLogView;

