import React  from 'react';
import './App.css';
import './Page.css';

import { useNavigate } from "react-router-dom";

class PageEditSingleChoice extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			fileText: "",
			uniqueID: "",
			question: "",
			answerArray: [],
			correctAnswer: 0,
			filenameMax: -1,
			dataLoadedYN: false,
		};

		this.onKeyDown = this.onKeyDown.bind(this);		
		this.downloadTextFile = this.downloadTextFile.bind(this);
		this.serverGetPageFilenameMax = this.serverGetPageFilenameMax.bind(this);
		this.serverSetPageTextFile = this.serverSetPageTextFile.bind(this);
		this.onQuestionChanged = this.onQuestionChanged.bind(this);
		this.onAnswerChanged = this.onAnswerChanged.bind(this);
		this.onCorrectAnswerCheckboxClick = this.onCorrectAnswerCheckboxClick.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
		this.renderOneAnswer = this.renderOneAnswer.bind(this);
		this.renderAllAnswers = this.renderAllAnswers.bind(this);
	}
	
	componentDidMount() {

		var baseURLAPI = this.props.baseURLAPI;
		var modelID = this.props.modelID;
		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;
		
		var i;
		var answerArray = [];
		for(i=0; i<10; i++) {
			answerArray.push("");
		}
		
		this.setState({
			answerArray: answerArray,
		});

		var newYN = this.props.newYN;
		if (newYN) {
			this.serverGetPageFilenameMax(modelID, pageFolder, pageSubfolder);
			return;
		}

		var pageFilenameExt = this.props.pageFilenameExt;
		var pageURL = baseURLAPI + "/model/" + modelID + "/pages/" + pageFolder + "/" + pageSubfolder;
		var fileURL = pageURL + "/" + pageFilenameExt;
		this.downloadTextFile(fileURL);
	}

	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	async downloadTextFile(fileURL) {

		var res = await fetch(fileURL);
		var responseText = "";

		try {
			responseText = await res.text();
		} catch {
			responseText = "";
		}

		if (res.status !== 200) {
			responseText = "";
		}
		
		if (responseText === "") {
			return;
		}
		
		var jsonObj = JSON.parse(responseText);
		
		var uniqueID = jsonObj.uniqueID;
		var question = jsonObj.question;
		var correctAnswer = jsonObj.correctAnswer;
		var answerArray = jsonObj.answers;

		this.setState({
			fileText: responseText,
			uniqueID: uniqueID,
			question: question,
			correctAnswer: correctAnswer,
			answerArray: answerArray,
			dataLoadedYN: true,
		});
	}
	
	async serverGetPageFilenameMax(modelID, pageFolder, pageSubfolder) {

		var baseURLAPI = this.props.baseURLAPI;
		var folder = pageFolder + "/" + pageSubfolder;
		var url = baseURLAPI + "/getpagefilenamemax?modelid=" + modelID;
		url = url + "&folder=" + encodeURIComponent(folder);

		var filenameMax = -1;

		var res = await fetch(url);
		var responseText = "";

		try {
			responseText = await res.text();
			filenameMax = parseInt(responseText);
		} catch {
			filenameMax = -1;
		}

		this.setState({
			filenameMax: filenameMax,
			dataLoadedYN: true,
		});
		
	}
	
	async serverSetPageTextFile(modelID, pageFolder, pageSubfolder, pageFilenameExt, fileText) {
		
		var baseURLAPI = this.props.baseURLAPI;
		var folder = pageFolder + "/" + pageSubfolder;
		var url = baseURLAPI + "/setpagetextfile?modelid=" + modelID;
		url = url + "&folder=" + encodeURIComponent(folder);
		url = url + "&filename=" + encodeURIComponent(pageFilenameExt);
		
		var formData = new FormData();
		formData.append("filetext", fileText);

		const res = await fetch(url, {
			method: 'POST',
			body: formData,
		});		

		await res.text();

		this.props.showToast("Single choice section saved");
		//await this.props.serverGetFolderList();
		const navigate = this.props.navigate;
  		navigate("/page");
	}

	onQuestionChanged(value) {
		this.setState({question: value});
	}

	onAnswerChanged(index, value) {

		var answerArray = this.state.answerArray;

		answerArray[index] = value;
		
		this.setState({
			answerArray: answerArray,
		});
	}
	
	onCorrectAnswerCheckboxClick(index) {
		this.setState({
			correctAnswer: index,
		});
	}
	
	onClickSaveButton() {
		
		var modelID = this.props.modelID;
		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;

		var newYN = this.props.newYN;
		var pageFilenameExt;
		var uniqueID;

		if (newYN) {
			var filenameMax = this.state.filenameMax;
			filenameMax = filenameMax + 1;
			pageFilenameExt = filenameMax + ".singlejson";
			var dateObj = new Date();
			var unixTimeSec = Math.floor(dateObj.getTime() / 1000);
			uniqueID = modelID + "-" + unixTimeSec;
		} else {
			pageFilenameExt = this.props.pageFilenameExt;
			uniqueID = this.state.uniqueID;
		}

		var question = this.state.question;
		var answerArray = this.state.answerArray;
		var correctAnswer = this.state.correctAnswer;
		
		var jsonObj = {};
		jsonObj.uniqueID = uniqueID;
		jsonObj.question = question;
		jsonObj.correctAnswer = correctAnswer;
		jsonObj.answers = answerArray;
		
		var fileText = JSON.stringify(jsonObj);
		this.serverSetPageTextFile(modelID, pageFolder, pageSubfolder, pageFilenameExt, fileText);

		var title;
		var changeJsonObj;
		var changeJSON;

		if (newYN) {
			title = "New page single choice section: " + pageFolder + "/" + pageSubfolder;
			changeJsonObj = {};
			changeJsonObj.section = "pages";
			changeJsonObj.operation = "newsinglechoicesection";
			changeJsonObj.pageFolder = pageFolder;
			changeJsonObj.pageSubfolder = pageSubfolder;
			changeJsonObj.fileText = fileText;
			var changeJSON = JSON.stringify(changeJsonObj);
			this.props.serverInsertChangeLogRecord(title, changeJSON);
		} else {
			title = "Edit page single choice section: " + pageFolder + "/" + pageSubfolder;
			changeJsonObj = {};
			changeJsonObj.section = "pages";
			changeJsonObj.operation = "editsinglechoicesection";
			changeJsonObj.pageFolder = pageFolder;
			changeJsonObj.pageSubfolder = pageSubfolder;
			changeJsonObj.fileText = fileText;
			var changeJSON = JSON.stringify(changeJsonObj);
			this.props.serverInsertChangeLogRecord(title, changeJSON);
		}
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/page");
	}
	
	renderOneAnswer(index) {
		
		var answerArray = this.state.answerArray;
		var correctAnswer = this.state.correctAnswer;
		var answerText = answerArray[index];
		
		var correctAnswerYN = false;
		if (index === correctAnswer) {
			correctAnswerYN = true;
		}
		
		var disabledCheckboxYN = false;
		if (answerText === "") {
			disabledCheckboxYN = true;
		}
		
		var id1 = "answer-" + index;
		var id2 = "correct-" + index;

		return (
			<div key={index}>
				<fieldset className="PageEditSingleChoiceFieldset">
					<legend className="PageEditSingleChoiceLegend">Answer {index+1}</legend> 
					<input
						className="PageEditSingleChoiceInput" 
						id={id1} 
						type="text"
						value={answerText}
						onChange={(event) => this.onAnswerChanged(index, event.target.value)}
						onKeyDown={(event) => this.onKeyDown(event.keyCode)}
					/>
				</fieldset>

				<input 
					type="checkbox" 
					className="ViewItemCheckbox" 
					id={id2}
					checked={correctAnswerYN}
					disabled={disabledCheckboxYN}
					onChange={(event) => this.onCorrectAnswerCheckboxClick(index)}
				/>
				<label htmlFor={id2} className="ViewItemCheckboxLabel">Correct</label>

				<div style={{height: "10px"}}></div>
			</div>
		);
	}
	
	renderAllAnswers() {

		var i;
		var jsxCode;
		var jsxArray = [];
		var answerArray = this.state.answerArray;
		
		for(i=0; i<answerArray.length; i++) {
			jsxCode = this.renderOneAnswer(i);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}

	render() {
		
		var dataLoadedYN = this.state.dataLoadedYN;
		if (!dataLoadedYN) {
			return null;
		}

		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;

		return (
			<div className="PageEditSingleChoiceContainer">
				<div style={{height: "10px"}}></div>
				<div className="PageEditSingleChoiceTitle">{pageFolder}_{pageSubfolder}</div>
				<div style={{height: "20px"}}></div>
				<fieldset className="PageEditSingleChoiceFieldset">
					<legend className="PageEditSingleChoiceLegend">Question</legend> 
					<input
						className="PageEditSingleChoiceInput" 
						id="question" 
						type="text" 
						autoFocus={true}
						value={this.state.question}
						onChange={(event) => this.onQuestionChanged(event.target.value)}
						onKeyDown={(event) => this.onKeyDown(event.keyCode)}
					/>
				</fieldset>
				<div style={{height: "20px"}}></div>
				{this.renderAllAnswers()}
				<div style={{height: "40px"}}></div>
				<div className="ViewContainerButtons">
					<div style={{width: "40px"}}></div>
					<button className="ViewCancelButton" onClick={this.onClickCancelButton}>Cancel</button>
					<div style={{width: "20px"}}></div>
					<button className="ViewSaveButton" onClick={this.onClickSaveButton}>Save</button>
				</div>
				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function PageEditSingleChoiceFunction(props) {
	const navigate = useNavigate();
	return <PageEditSingleChoice {...props} navigate={navigate} />;
}
