import React  from 'react';
import './App.css';
import { useNavigate } from "react-router-dom";

class PageMainToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(page) {
		this.props.setSelectedTab(page);
	}
	
	renderLink(linkTitle, linkValue) {
		
		var selectedTab = this.props.selectedTab;
		var classNameString = "PageMainToolbarLink";
		
		if (selectedTab === linkValue) {
			classNameString = "PageMainToolbarSelectedLink";
		}
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	render() {

		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;

		var viewString = "View";
		var editString = "Edit";
		var reorderString = "Reorder";
		var renameString = "Rename";
		var deleteString = "Delete";
		
		return (
			<div className="PageMainToolbarContainer1">
				<div className="PageMainToolbarLeft">
					<div className="PageMainToolbarText">
						{pageFolder}_{pageSubfolder}
					</div>
				</div>
				<div className="PageMainToolbarRight">
					<div className="PageMainToolbarContainer2">
						{this.renderLink(viewString, "view")}
						<div className="DocumentViewToolbarText">|</div>
						{this.renderLink(editString, "edit")}
						<div className="DocumentViewToolbarText">|</div>
						{this.renderLink(reorderString, "reorder")}
						<div className="DocumentViewToolbarText">|</div>
						{this.renderLink(renameString, "rename")}
						<div className="DocumentViewToolbarText">|</div>
						{this.renderLink(deleteString, "delete")}
					</div>
				</div>
			</div>
		);
	}
}

export default function PageMainToolbarFunction(props) {
	const navigate = useNavigate();
	return <PageMainToolbar {...props} navigate={navigate} />;
}

