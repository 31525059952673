import React  from 'react';
import './App.css';
import './Page.css';

import { useNavigate } from "react-router-dom";

class PageRenameFolder extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			folder: "",
		};
		
		this.onKeyDown = this.onKeyDown.bind(this);
		this.normalizeFilename = this.normalizeFilename.bind(this);
		this.onFolderChanged = this.onFolderChanged.bind(this);
		this.serverSetFolderName = this.serverSetFolderName.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	componentDidMount() {
		var pageFolder = this.props.pageFolder;
		this.setState({folder: pageFolder});
	}
	
	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	normalizeFilename(filename1) {
		
		var i;
		var ch;
		var filename2 = "";
		var allowedYN;
		
		filename1 = filename1.toLowerCase();
		
		for(i=0; i<filename1.length; i++) {

			ch = filename1.charAt(i);

			allowedYN = false;
			if ((ch >= "0") && (ch <= "9")) {
				allowedYN = true;
			}
			if ((ch >= "a") && (ch <= "z")) {
				allowedYN = true;
			}
			
			if (allowedYN) {
				filename2 = filename2 + ch;
			}
		}
		
		return filename2;
	}

	onFolderChanged(value) {
		var folder = this.normalizeFilename(value);
		this.setState({folder: folder});
	}
	
	async serverSetFolderName(modelID, folder1, folder2) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/setfoldername?modelid=" + modelID;
		url = url + "&folder1=" + encodeURIComponent(folder1);
		url = url + "&folder2=" + encodeURIComponent(folder2);
		
		const res = await fetch(url);
		var responseText = await res.text();

		if (responseText === "0") {
			this.props.showToast("Folder renamed");
		} else {
			this.props.showToast("Folder NOT renamed");
		}
		const navigate = this.props.navigate;
  		navigate("/pages");
	}
		
	onClickSaveButton() {
		
		var folder2 = this.state.folder;
		folder2 = folder2.trim();
		if (folder2 === "") {
			this.props.showToast("Folder cannot be empty");
			return;
		}

		var folder1 = this.props.pageFolder;
		if (folder1 === folder2) {
			this.props.showToast("Folder not changed");
			return;
		}

		var modelID = this.props.modelID;
		this.serverSetFolderName(modelID, folder1, folder2);

		var title;
		var changeJsonObj;
		var changeJSON;

		title = "Rename folder: " + folder2;
		changeJsonObj = {};
		changeJsonObj.section = "pages";
		changeJsonObj.operation = "renamefolder";
		changeJsonObj.pageFolder = folder2;
		var changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/pages");
	}
	
	render() {

		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">Rename folder</div>
				<div className="ViewTopText">Enter folder name and press Rename Folder bottun</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">Folder Info</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<p></p>
						<fieldset className="PageRenameFolderFieldset">
							<legend className="PageRenameFolderLegend">Folder</legend> 
							<input
								className="PageRenameFolderInput" 
								id="Document" 
								type="text" 
								value={this.state.folder}
								autoFocus={true}
								onChange={(event) => this.onFolderChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>Cancel</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>Rename Folder</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function PageRenameFolderFunction(props) {
	const navigate = useNavigate();
	return <PageRenameFolder {...props} navigate={navigate} />;
}

