import React  from 'react';
import './App.css';
import './Model.css';

import { useNavigate } from "react-router-dom";

class ModelNewFromWebSite extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			modelType: "hotel",
			webSite: "",
			llmModel: "llama2-7b",
			helloMessageOrig: "",
			systemMessageOrig: "",
		};

		this.refresh = this.refresh.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.serverInsertModelFromWebSiteRecord = this.serverInsertModelFromWebSiteRecord.bind(this);
		this.modelTypeChanged = this.modelTypeChanged.bind(this);
		this.onClickLLMModel = this.onClickLLMModel.bind(this);
		this.onClickModelType = this.onClickModelType.bind(this);
		this.onWebSiteChanged = this.onWebSiteChanged.bind(this);
		this.onModelNameChanged = this.onModelNameChanged.bind(this);
		this.onHelloMessageChanged = this.onHelloMessageChanged.bind(this);
		this.onSystemMessageChanged = this.onSystemMessageChanged.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
		this.renderCopyProgress = this.renderCopyProgress.bind(this);
		this.renderForm = this.renderForm.bind(this);
	}
	
	async componentDidMount() {

		var modelCopyHelpersObj = this.props.modelCopyHelpersObj;
		modelCopyHelpersObj.init();
		modelCopyHelpersObj.refresh = this.refresh;

		this.modelTypeChanged("hotel");
	}
	
	refresh() {
		this.setState({refresh: 1});
	}
	
	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}
	
	async serverInsertModelFromWebSiteRecord(modelID1, modelID2, modelName, modelType, webSite, userID, fullName, email, llmModel) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/insertmodelfromwebsiterecord?modelid1=" + modelID1;
		url = url + "&modelid2=" + modelID2;
		url = url + "&modelname=" + encodeURIComponent(modelName);
		url = url + "&modeltype=" + modelType;
		url = url + "&website=" + encodeURIComponent(webSite);
		url = url + "&userid=" + userID;
		url = url + "&fullname=" + encodeURIComponent(fullName);
		url = url + "&email=" + encodeURIComponent(email);
		url = url + "&llmmodel=" + encodeURIComponent(llmModel);
		
		const res = await fetch(url);
		await res.text();
	}

	async modelTypeChanged(modelType) {
		
		this.setState({modelType: modelType});
		
		var sourceModelID = 0;

		if (modelType === "hotel") {
			sourceModelID = 38;
		}
		if (modelType === "camp") {
			sourceModelID = 79;
		}
		if (modelType === "marina") {
			sourceModelID = 92;
		}
		if (modelType === "orthodontist") {
			sourceModelID = 101;
		}
		if (modelType === "highschool") {
			sourceModelID = 78;
		}

		var modelCopyHelpersObj = this.props.modelCopyHelpersObj;
		modelCopyHelpersObj.sourceModelID = sourceModelID;

		await modelCopyHelpersObj.serverGetModelData(sourceModelID);
		this.setState({
			helloMessageOrig: modelCopyHelpersObj.helloMessage,
			systemMessageOrig: modelCopyHelpersObj.systemMessage,
		});
		
		modelCopyHelpersObj.serverGetQuestionSampleCategoryList(sourceModelID);
		modelCopyHelpersObj.serverGetFolderList(sourceModelID);
		modelCopyHelpersObj.serverGetPageList(sourceModelID);
	}
	
	onClickLLMModel() {
		var llmModel = this.state.llmModel;
		if (llmModel === "chatgpt-3.5") {
			this.setState({llmModel: "llama2-7b"});
			return;
		}
		if (llmModel === "llama2-7b") {
			this.setState({llmModel: "chatgpt-3.5"});
			return;
		}
	}

	onClickModelType() {
		var modelType = this.state.modelType;
		if (modelType === "hotel") {
			this.modelTypeChanged("camp");
			return;
		}
		if (modelType === "camp") {
			this.modelTypeChanged("marina");
			return;
		}
		if (modelType === "marina") {
			this.modelTypeChanged("orthodontist");
			return;
		}
		if (modelType === "orthodontist") {
			this.modelTypeChanged("highschool");
			return;
		}
		if (modelType === "highschool") {
			this.modelTypeChanged("hotel");
			return;
		}
	}

	onWebSiteChanged(value) {
		this.setState({webSite: value});
	}

	onModelNameChanged(value) {

		var modelCopyHelpersObj = this.props.modelCopyHelpersObj;
		var modelName = value;
		modelCopyHelpersObj.modelName = modelName;

		var helloMessageOrig = this.state.helloMessageOrig;
		var systemMessageOrig = this.state.systemMessageOrig;
		
		helloMessageOrig = helloMessageOrig.replace("[MODEL_NAME]", modelName);
		systemMessageOrig = systemMessageOrig.replace("[MODEL_NAME]", modelName);
		modelCopyHelpersObj.helloMessage = helloMessageOrig;
		modelCopyHelpersObj.systemMessage = systemMessageOrig;

		this.setState({refresh: 1});
	}
	
	onHelloMessageChanged(value) {
		var modelCopyHelpersObj = this.props.modelCopyHelpersObj;
		modelCopyHelpersObj.helloMessage = value;
		this.setState({refresh: 1});
	}
	
	onSystemMessageChanged(value) {
		var modelCopyHelpersObj = this.props.modelCopyHelpersObj;
		modelCopyHelpersObj.systemMessage = value;
		this.setState({refresh: 1});
	}
	
	async onClickSaveButton() {
		
		var modelCopyHelpersObj = this.props.modelCopyHelpersObj;
		var sourceModelID = modelCopyHelpersObj.sourceModelID;

		var webSite = this.state.webSite;
		webSite = webSite.trim();
		if (webSite === "") {
			this.props.showToast("Web site cannot be empty");
			return;
		}
		var index = webSite.indexOf("https:");
		if (index >= 0) {
			this.props.showToast("Web site cannot contain https://");
			return;
		}
		index = webSite.indexOf("http:");
		if (index >= 0) {
			this.props.showToast("Web site cannot contain http://");
			return;
		}
		index = webSite.indexOf("www.");
		if (index >= 0) {
			this.props.showToast("Web site cannot contain www.f");
			return;
		}

		var modelName = modelCopyHelpersObj.modelName;
		modelName = modelName.trim();
		if (modelName === "") {
			this.props.showToast("Model name cannot be empty");
			return;
		}

		var helloMessage = modelCopyHelpersObj.helloMessage;
		var systemMessage = modelCopyHelpersObj.systemMessage;
		var llmModel = modelCopyHelpersObj.llmModel;
		var modelID = await modelCopyHelpersObj.serverInsertModelRecord(modelName, helloMessage, systemMessage, llmModel);

		var title;
		var changeJsonObj;
		var changeJSON;

		modelCopyHelpersObj.copyInProgressYN = true;

		var message = "";
		var progressMessageArray = modelCopyHelpersObj.progressMessageArray;

		message = "Model '" + modelName + "' created.";
		progressMessageArray.push(message);		
		message = "Model ID: " + modelID;
		progressMessageArray.push(message);
		progressMessageArray.push("-");
		modelCopyHelpersObj.progressMessageArray = progressMessageArray;
		this.setState({refresh: 1});
		
		await modelCopyHelpersObj.serverCopyQuestionSampleCategory(modelID);
		this.setState({refresh: 1});
		await modelCopyHelpersObj.serverCopyAllDocs(sourceModelID, modelID);
		this.setState({refresh: 1});
		await modelCopyHelpersObj.serverCopyAllPages(sourceModelID, modelID);
		this.setState({refresh: 1});
		await modelCopyHelpersObj.serverCopyModelCSSJS(sourceModelID, modelID);
		this.setState({refresh: 1});
		
		var modelType = this.state.modelType;
		var userID = this.props.userID;
		var fullName = this.props.fullName;
		var email = this.props.email;
		var llmModel2 = this.state.llmModel;
		this.serverInsertModelFromWebSiteRecord(sourceModelID, modelID, modelName, modelType, webSite, userID, fullName, email, llmModel2);

		progressMessageArray.push("-");
		message = "*** NEW MODEL FROM WEB SITE - JOB SUBMITTED ***";
		progressMessageArray.push(message);
		progressMessageArray.push("");
		progressMessageArray.push("");
		modelCopyHelpersObj.progressMessageArray = progressMessageArray;
		this.setState({refresh: 1});

		title = "New model from web site - " + webSite;
		changeJsonObj = {};
		changeJsonObj.section = "model";
		changeJsonObj.operation = "newmodelfromwebsite";
		changeJsonObj.webSite = webSite;
		changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);		
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/models");
	}
	
	renderCopyProgress() {
		
		var modelCopyHelpersObj = this.props.modelCopyHelpersObj;
		var progressMessageArray = modelCopyHelpersObj.progressMessageArray;
		
		var i;
		var message;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<progressMessageArray.length; i++) {
			message = progressMessageArray[i];
			jsxCode = (<div key={i}>{message}</div>);
			jsxArray.push(jsxCode);
		}

		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				{jsxArray}
			</div>
		);
	}
	
	renderForm() {

		var modelCopyHelpersObj = this.props.modelCopyHelpersObj;
		var sourceModelName = modelCopyHelpersObj.sourceModelName;
		var modelName = modelCopyHelpersObj.modelName;
		var helloMessage = modelCopyHelpersObj.helloMessage;
		var systemMessage = modelCopyHelpersObj.systemMessage;
		
		var modelType = this.state.modelType;
		var llmModel = this.state.llmModel;

		var modelTypeString = "";
		if (modelType === "hotel") {
			modelTypeString = "Hotel";
		}
		if (modelType === "camp") {
			modelTypeString = "Camp";
		}
		if (modelType === "marina") {
			modelTypeString = "Marina";
		}
		if (modelType === "orthodontist") {
			modelTypeString = "Orthodontist";
		}
		if (modelType === "highschool") {
			modelTypeString = "High school";
		}
		
		var llmModelString = "";
		if (llmModel === "chatgpt-3.5") {
			llmModelString = "ChatGPT 3.5";
		}
		if (llmModel === "llama2-7b") {
			llmModelString = "LLAMA2 7b";
		}

		var webSite = this.state.webSite;
		
		if (typeof sourceModelName === "undefined") {
			return;
		}

		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">New model from web site</div>
				<div className="ViewTopText">Enter model data and press "Create Model from Web Site" button</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">Model info</div>

					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelCopyFieldset">
							<legend className="ModelCopyLegend">Model type</legend> 
							<div className="ModelNewFromWebSiteModelType" onClick={this.onClickModelType}>
								{modelTypeString}
							</div>
						</fieldset>
					</div>
					
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelCopyFieldset">
							<legend className="ModelCopyLegend">Web site</legend> 
							<input
								className="ModelCopyInput" 
								id="webSite" 
								type="text" 
								autoFocus={true}
								value={webSite}
								onChange={(event) => this.onWebSiteChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>

					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelCopyFieldset">
							<legend className="ModelCopyLegend">Model name</legend> 
							<input
								className="ModelCopyInput" 
								id="modelName" 
								type="text" 
								value={modelName}
								onChange={(event) => this.onModelNameChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>

					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelCopyFieldset">
							<legend className="ModelCopyLegend">Hello message</legend> 
							<textarea
								className="ModelCopyInput" 
								id="helloMessage" 
								type="text" 
								rows={5}
								value={helloMessage}
								onChange={(event) => this.onHelloMessageChanged(event.target.value)}
							/>
						</fieldset>
					</div>

					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelCopyFieldset">
							<legend className="ModelCopyLegend">System message</legend> 
							<input
								className="ModelCopyInput" 
								id="systemMessage" 
								type="text" 
								value={systemMessage}
								onChange={(event) => this.onSystemMessageChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>

					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelCopyFieldset">
							<legend className="ModelCopyLegend">LLM model</legend> 
							<div className="ModelNewFromWebSiteModelType" onClick={this.onClickLLMModel}>
								{llmModelString}
							</div>
						</fieldset>
					</div>

					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>Cancel</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>New Model from Web Site</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}

	render() {

		var modelCopyHelpersObj = this.props.modelCopyHelpersObj;
		var copyInProgressYN = modelCopyHelpersObj.copyInProgressYN;

		if (copyInProgressYN) {
			return this.renderCopyProgress();
		} else {
			return this.renderForm();
		}
	}
}

export default function ModelNewFromWebSiteFunction(props) {
	const navigate = useNavigate();
	return <ModelNewFromWebSite {...props} navigate={navigate} />;
}

