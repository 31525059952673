import React  from 'react';
import './App.css';
import './Model.css';

import { useNavigate } from "react-router-dom";

class ModelCopy extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
		};

		this.refresh = this.refresh.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onModelNameChanged = this.onModelNameChanged.bind(this);
		this.onHelloMessageChanged = this.onHelloMessageChanged.bind(this);
		this.onSystemMessageChanged = this.onSystemMessageChanged.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
		this.renderCopyProgress = this.renderCopyProgress.bind(this);
		this.renderForm = this.renderForm.bind(this);
	}
	
	componentDidMount() {
		var modelCopyHelpersObj = this.props.modelCopyHelpersObj;
		modelCopyHelpersObj.init();
		modelCopyHelpersObj.refresh = this.refresh;
		var sourceModelID = this.props.modelID;
		modelCopyHelpersObj.sourceModelID = sourceModelID;
		modelCopyHelpersObj.serverGetModelData(sourceModelID);
		modelCopyHelpersObj.serverGetQuestionSampleCategoryList(sourceModelID);
		modelCopyHelpersObj.serverGetFolderList(sourceModelID);
		modelCopyHelpersObj.serverGetPageList(sourceModelID);
	}
	
	refresh() {
		this.setState({refresh: 1});
	}
	
	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	onModelNameChanged(value) {
		var modelCopyHelpersObj = this.props.modelCopyHelpersObj;
		modelCopyHelpersObj.modelName = value;
		this.setState({refresh: 1});
	}
	
	onHelloMessageChanged(value) {
		var modelCopyHelpersObj = this.props.modelCopyHelpersObj;
		modelCopyHelpersObj.helloMessage = value;
		this.setState({refresh: 1});
	}
	
	onSystemMessageChanged(value) {
		var modelCopyHelpersObj = this.props.modelCopyHelpersObj;
		modelCopyHelpersObj.systemMessage = value;
		this.setState({refresh: 1});
	}
	
	async onClickSaveButton() {
		
		var modelCopyHelpersObj = this.props.modelCopyHelpersObj;
		var sourceModelID = modelCopyHelpersObj.sourceModelID;
		var modelName = modelCopyHelpersObj.modelName;
		modelName = modelName.trim();
		if (modelName === "") {
			this.props.showToast("Model name cannot be empty");
			return;
		}

		var helloMessage = modelCopyHelpersObj.helloMessage;
		var systemMessage = modelCopyHelpersObj.systemMessage;
		var llmModel = modelCopyHelpersObj.llmModel;
		var modelID = await modelCopyHelpersObj.serverInsertModelRecord(modelName, helloMessage, systemMessage, llmModel);

		var title;
		var changeJsonObj;
		var changeJSON;

		modelCopyHelpersObj.copyInProgressYN = true;

		var message = "";
		var progressMessageArray = modelCopyHelpersObj.progressMessageArray;

		message = "Model '" + modelName + "' created.";
		progressMessageArray.push(message);		
		message = "Model ID: " + modelID;
		progressMessageArray.push(message);
		progressMessageArray.push("-");
		modelCopyHelpersObj.progressMessageArray = progressMessageArray;
		this.setState({refresh: 1});
		
		await modelCopyHelpersObj.serverCopyQuestionSampleCategory(modelID);
		this.setState({refresh: 1});
		await modelCopyHelpersObj.serverCopyAllDocs(sourceModelID, modelID);
		this.setState({refresh: 1});
		await modelCopyHelpersObj.serverCopyAllPages(sourceModelID, modelID);
		this.setState({refresh: 1});
		await modelCopyHelpersObj.serverCopyModelCSSJS(sourceModelID, modelID);
		this.setState({refresh: 1});

		progressMessageArray.push("-");
		message = "*** COPY MODEL FINISHED ***";
		progressMessageArray.push(message);
		modelCopyHelpersObj.progressMessageArray = progressMessageArray;
		this.setState({refresh: 1});

		title = "Copy model: " + modelName;
		changeJsonObj = {};
		changeJsonObj.section = "model";
		changeJsonObj.operation = "copy";
		changeJsonObj.modelName = modelName;
		changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);		
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/models");
	}
	
	renderCopyProgress() {
		
		var modelCopyHelpersObj = this.props.modelCopyHelpersObj;
		var progressMessageArray = modelCopyHelpersObj.progressMessageArray;
		
		var i;
		var message;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<progressMessageArray.length; i++) {
			message = progressMessageArray[i];
			jsxCode = (<div key={i}>{message}</div>);
			jsxArray.push(jsxCode);
		}

		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				{jsxArray}
			</div>
		);
	}
	
	renderForm() {

		var modelCopyHelpersObj = this.props.modelCopyHelpersObj;
		var sourceModelName = modelCopyHelpersObj.sourceModelName;
		var modelName = modelCopyHelpersObj.modelName;
		var helloMessage = modelCopyHelpersObj.helloMessage;
		var systemMessage = modelCopyHelpersObj.systemMessage;
		
		if (typeof sourceModelName === "undefined") {
			return;
		}

		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">Copy model</div>
				<div className="ViewTopText">Enter model data and press Copy Model button</div>
				<div className="ViewTopText">Caution when using "Copy Model" - deleting model takes a lot of time</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">Model info</div>

					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelCopyFieldset">
							<legend className="ModelCopyLegend">Source model</legend> 
							<input
								className="ModelCopyInput" 
								id="originalModelName" 
								type="text" 
								disabled={true}
								value={sourceModelName}
							/>
						</fieldset>
					</div>

					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelCopyFieldset">
							<legend className="ModelCopyLegend">Model name</legend> 
							<input
								className="ModelCopyInput" 
								id="modelName" 
								type="text" 
								autoFocus={true}
								value={modelName}
								onChange={(event) => this.onModelNameChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>

					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelCopyFieldset">
							<legend className="ModelCopyLegend">Hello message</legend> 
							<textarea
								className="ModelCopyInput" 
								id="helloMessage" 
								type="text" 
								rows={5}
								value={helloMessage}
								onChange={(event) => this.onHelloMessageChanged(event.target.value)}
							/>
						</fieldset>
					</div>

					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelCopyFieldset">
							<legend className="ModelCopyLegend">System message</legend> 
							<input
								className="ModelCopyInput" 
								id="systemMessage" 
								type="text" 
								value={systemMessage}
								onChange={(event) => this.onSystemMessageChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>

					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>Cancel</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>Copy Model</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}

	render() {

		var modelCopyHelpersObj = this.props.modelCopyHelpersObj;
		var copyInProgressYN = modelCopyHelpersObj.copyInProgressYN;

		if (copyInProgressYN) {
			return this.renderCopyProgress();
		} else {
			return this.renderForm();
		}
	}
}

export default function ModelCopyFunction(props) {
	const navigate = useNavigate();
	return <ModelCopy {...props} navigate={navigate} />;
}

