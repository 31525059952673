import React  from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './FileViewPopup.css';
import ChatLang from './ChatLang.json';

class FileViewPopup extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			filename: "",
			fileText: "",
		};

		this.serverDownloadFile = this.serverDownloadFile.bind(this);
		this.popUpClosed = this.popUpClosed.bind(this);
		this.onCloseButton = this.onCloseButton.bind(this);
		this.renderPopup = this.renderPopup.bind(this);
	}
	
	componentDidMount() {
		var modelID = this.props.modelID;
		var filename = this.props.filename;
		this.serverDownloadFile(modelID, filename);
		this.setState({filename: filename});
	}

	componentDidUpdate() {

		var filename1 = this.state.filename;
		var filename2 = this.props.filename;
		
		if (filename1 === filename2) {
			return;
		}

		var modelID = this.props.modelID;
		this.serverDownloadFile(modelID, filename2);
		this.setState({filename: filename2});
	}

	async serverDownloadFile(modelID, filename) {
		
		if (filename === "") {
			return;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/model/" + modelID + "/txt/" + filename + ".txt";
		
		const res = await fetch(url);
		var fileText = await res.text();

		this.setState({
			fileText: fileText,
		});
	}

	popUpClosed() {
		this.props.setShowFileViewPopupYN(false);
	}
	
	onNotesChanged(value) {
		this.setState({notes: value});
	}
	
	onCloseButton() {
		this.props.setShowFileViewPopupYN(false);
	}
	
	renderPopup() {
		
		var filename = this.props.filename;
		var questionText = this.props.questionText;
		var similarityScore = this.props.similarityScore;
		var fileText = this.state.fileText;

		var titleString = filename.toUpperCase() + " - " + similarityScore.toFixed(2) + " - " + questionText;

		return (
			<Popup
				className="file-view-popup"
				open={true}
				closeOnDocumentClick={true}
				onClose={this.popUpClosed}
			>
				<div className="FileViewPopupTopContainer">
					<div className="FileViewPopupHeaderContainer">
						<div className="FileViewPopupHeaderText">{titleString}</div>
					</div>
					<div style={{height: "20px"}}></div>
					<div className="FileViewPopupContainer">
						<textarea
							className="FileViewPopupInput" 
							id="fileText" 
							value={fileText}
							rows={30}
							disabled={true}
						/>
					</div>
					
					<div style={{height: "30px"}}></div>
					<div className="FileViewPopupContainerButtons">
						<button className="ViewSaveButton" onClick={this.onCloseButton}>{this.props.getLang(ChatLang, "close")}</button>
					</div>
					<div style={{height: "30px"}}></div>
				</div>
			</Popup>
		);
	}

	render() {
		
		var renderPopupYN = this.props.renderPopupYN;
		
		if (!renderPopupYN) {
			return null;
		}
		
		return (
			<div className={this.props.className}>
				{this.renderPopup()}
			</div>
		);
	}
}

export default FileViewPopup;

