import React  from 'react';
import './App.css';
import './Page.css';
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai"
import Iframe from 'react-iframe'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { useNavigate } from "react-router-dom";

class PageEdit extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			pageURL: "",
		};

		this.handleNewImage = this.handleNewImage.bind(this);
		this.handleNewText = this.handleNewText.bind(this);
		this.handleNewRichText = this.handleNewRichText.bind(this);
		this.handleNewHtml = this.handleNewHtml.bind(this);
		this.handleNewLink = this.handleNewLink.bind(this);
		this.handleNewSingleChoice = this.handleNewSingleChoice.bind(this);
		this.handleNewPronounce = this.handleNewPronounce.bind(this);
		this.handleEditImage = this.handleEditImage.bind(this);
		this.handleEditText = this.handleEditText.bind(this);
		this.handleEditHtml = this.handleEditHtml.bind(this);
		this.handleEditLink = this.handleEditLink.bind(this);
		this.handleEditSingleChoice = this.handleEditSingleChoice.bind(this);
		this.handleEditPronounce = this.handleEditPronounce.bind(this);
		this.handleEditSection = this.handleEditSection.bind(this);
		this.handleDeleteSection = this.handleDeleteSection.bind(this);
		this.renderImage = this.renderImage.bind(this);
		this.renderText = this.renderText.bind(this);
		this.renderRichText = this.renderRichText.bind(this);
		this.renderHtml = this.renderHtml.bind(this);
		this.renderLink = this.renderLink.bind(this);
		this.renderSingleChoice = this.renderSingleChoice.bind(this);
		this.renderPronounce = this.renderPronounce.bind(this);
		this.renderOneSection = this.renderOneSection.bind(this);
		this.renderAllSections = this.renderAllSections.bind(this);
		this.renderNewHeaderFooter = this.renderNewHeaderFooter.bind(this);		
	}
	
	componentDidMount() {
		var baseURLAPI = this.props.baseURLAPI;
		var modelID = this.props.modelID;
		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;
		var pageURL = baseURLAPI + "/model/" + modelID + "/pages/" + pageFolder + "/" + pageSubfolder;
		this.setState({pageURL: pageURL});
	}

	async serverDeletePageSection(modelID, pageFolder, pageSubfolder, filename) {
		
		var baseURLAPI = this.props.baseURLAPI;
		var folder = pageFolder + "/" + pageSubfolder;
		var url = baseURLAPI + "/deletepagesection?modelid=" + modelID;
		url = url + "&folder=" + encodeURIComponent(folder);
		url = url + "&filename=" + encodeURIComponent(filename);

		var res = await fetch(url);
		await res.text();
		this.props.showToast("Section deleted");
		
		var pageURL = this.state.pageURL;
		this.props.serverGetFolderList("pages/" + folder);

		var title = "Delete page section: " + pageFolder + "/" + pageSubfolder + "/" + filename;
		var changeJsonObj = {};
		changeJsonObj.section = "pages";
		changeJsonObj.operation = "deletesection";
		changeJsonObj.pageFolder = pageFolder;
		changeJsonObj.pageSubfolder = pageSubfolder;
		changeJsonObj.filename = filename;
		var changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);
	}
	
	handleNewImage() {
		this.props.setSelectedPageFilenameExt("");
		const navigate = this.props.navigate;
  		navigate("/page/newimage");
	}

	handleNewText() {
		this.props.setSelectedPageFilenameExt("");
		const navigate = this.props.navigate;
  		navigate("/page/newtext");
	}
	
	handleNewRichText() {
		this.props.setSelectedPageFilenameExt("");
		const navigate = this.props.navigate;
  		navigate("/page/newrichtext");
	}
	
	handleNewHtml() {
		this.props.setSelectedPageFilenameExt("");
		const navigate = this.props.navigate;
  		navigate("/page/newhtml");
	}
	
	handleNewLink() {
		this.props.setSelectedPageFilenameExt("");
		const navigate = this.props.navigate;
  		navigate("/page/newlink");
	}
	
	handleNewSingleChoice() {
		this.props.setSelectedPageFilenameExt("");
		const navigate = this.props.navigate;
  		navigate("/page/newsinglechoice");
	}
	
	handleNewPronounce() {
		this.props.setSelectedPageFilenameExt("");
		const navigate = this.props.navigate;
  		navigate("/page/newpronounce");
	}

	handleEditImage(filenameExt) {
		const navigate = this.props.navigate;
  		navigate("/page/editimage");
	}
	
	handleEditText(filenameExt) {
		const navigate = this.props.navigate;
  		navigate("/page/edittext");
	}

	handleEditRichText(filenameExt) {
		const navigate = this.props.navigate;
  		navigate("/page/editrichtext");
	}

	handleEditHtml(filenameExt) {
		const navigate = this.props.navigate;
  		navigate("/page/edithtml");
	}

	handleEditLink(filenameExt) {
		const navigate = this.props.navigate;
  		navigate("/page/editlink");
	}
	
	handleEditSingleChoice(filenameExt) {
		const navigate = this.props.navigate;
  		navigate("/page/editsinglechoice");
	}

	handleEditPronounce(filenameExt) {
		const navigate = this.props.navigate;
  		navigate("/page/editpronounce");
	}

	handleEditSection(filenameExt, imageYN, textYN, richTextYN, htmlYN, linkYN, singleChoiceYN, pronounceYN) {

		this.props.setSelectedPageFilenameExt(filenameExt);

		if (imageYN) {
			this.handleEditImage(filenameExt);
			return;
		}

		if (textYN) {
			this.handleEditText(filenameExt);
			return;
		}

		if (richTextYN) {
			this.handleEditRichText(filenameExt);
			return;
		}

		if (htmlYN) {
			this.handleEditHtml(filenameExt);
			return;
		}
		
		if (linkYN) {
			this.handleEditLink(filenameExt);
			return;
		}
		
		if (singleChoiceYN) {
			this.handleEditSingleChoice(filenameExt);
			return;
		}

		if (pronounceYN) {
			this.handleEditPronounce(filenameExt);
			return;
		}
	}
	
	handleDeleteSection(pageFilenameExt, imageYN, textYN, richTextYN, htmlYN, linkYN, singleChoiceYN, pronounceYN) {

		var modelID = this.props.modelID;
		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;

		var filename;
		var ext;
		[filename, ext] = this.props.getFilenameAndExtFromFilenameExt(pageFilenameExt);

		confirmAlert({
			title: 'Delete section',
			message: 'Are you sure you want to delete this section?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => this.serverDeletePageSection(modelID, pageFolder, pageSubfolder, filename)
				},
				{
					label: 'No',
				}
			]
		});
	}

	renderImage(filenameExt, fileURL) {
		
		return (
			<div onClick={() => this.handleEditSection(filenameExt, true, false, false, false, false, false, false)}>
				<img 
					className="PageEditSectionImage"
					src={fileURL}
					border="0"
				/>
			</div>
		)
	}

	renderText(filenameExt, fileText) {
		
		if (typeof(fileText) === "undefined") {
			return;
		}
		var fileTextBr = this.props.convertTextNewlineToBr(fileText);
		
		return (
			<div className="PageEditSectionText" onClick={() => this.handleEditSection(filenameExt, false, true, false, false, false, false, false)}>
				{fileTextBr}
			</div>
		)
	}

	renderRichText(filenameExt, filename) {
		
		var pageURL = this.state.pageURL;
		var r = Math.floor(Math.random() * 1000000);
		var fileURL = pageURL + "/" + filename + ".drafthtml" + "?r=" + r;
		
		return (
			<div onClick={() => this.handleEditSection(filenameExt, false, false, true, false, false, false, false)}>
				<iframe
					className="PageEditSectionRichText"
					src={fileURL}
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
					title="html"
				/>
			</div>
		)
	}

	renderHtml(filenameExt, fileURL) {
		
		return (
			<div onClick={() => this.handleEditSection(filenameExt, false, false, false, true, false, false, false)}>
				<iframe
					className="PageEditSectionHtml"
					src={fileURL}
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
					title="html"
				/>
			</div>
		)
	}

	renderLink(filenameExt, fileText) {
		
		if (typeof(fileText) === "undefined") {
			return;
		}
		var fileTextBr = this.props.convertTextNewlineToBr(fileText);
		
		return (
			<div className="PageEditSectionLink" onClick={() => this.handleEditSection(filenameExt, false, false, false, false, true, false, false)}>
				{fileTextBr}
			</div>
		)
	}

	renderSingleChoice(filenameExt, fileText) {
		
		if (typeof(fileText) === "undefined") {
			return;
		}
		if (fileText === "") {
			return;
		}

		var jsonObj = null;
		
		try {
			jsonObj = JSON.parse(fileText);
		} catch (err) {
			return;
		}
		
		if (jsonObj === null) {
			return;
		}

		var question = jsonObj.question;
		var correctAnswer = jsonObj.correctAnswer;
		var answerArray = jsonObj.answers;
		var answerString = "";
		var answerStringBr;
		
		var i;
		var answer;
		
		for(i=0; i<answerArray.length; i++) {
			answer = answerArray[i];
			if (answer !== "") {
				if (correctAnswer === i) {
					answerString = answerString + "- " + answer + " [correct] \n";
				} else {
					answerString = answerString + "- " + answer + "\n";
				}
			}
		}
		
		var answerStringBr = this.props.convertTextNewlineToBr(answerString);

		return (
			<div className="PageEditSectionText" onClick={() => this.handleEditSection(filenameExt, false, false, false, false, false, true, false)}>
				{question}<br/>
				{answerStringBr}
			</div>
		)
	}

	renderPronounce(filenameExt, fileText) {
		
		if (typeof(fileText) === "undefined") {
			return;
		}
		if (fileText === "") {
			return;
		}

		var jsonObj = null;
		
		try {
			jsonObj = JSON.parse(fileText);
		} catch (err) {
			return;
		}
		
		if (jsonObj === null) {
			return;
		}

		var language = jsonObj.language;
		var lineText = jsonObj.text;
		
		var lineTextBr = this.props.convertTextNewlineToBr(lineText);

		return (
			<div className="PageEditSectionText" onClick={() => this.handleEditSection(filenameExt, false, false, false, false, false, false, true)}>
				{lineTextBr}
			</div>
		)
	}

	renderOneSection(index, filenameExt) {

		var filename;
		var ext;

		[filename, ext] = this.props.getFilenameAndExtFromFilenameExt(filenameExt);

		var pageURL = this.state.pageURL;
		var fileTextArray = this.props.fileTextArray;
		var r = Math.floor(Math.random() * 1000000);
		var fileURL = pageURL + "/" + filenameExt + "?r=" + r;
		var fileText = "";
		
		var imageYN = false;
		var textYN = false;
		var richTextYN = false;
		var htmlYN = false;
		var linkYN = false;
		var singleChoiceYN = false;
		var pronounceYN = false;
		var sectionType = "none";
		
		if ((ext === "jpg") || (ext === "jpeg") || (ext === "png")) {
			imageYN = true;
			sectionType = "image";
		}

		if (ext === "txt") {
			textYN = true;
			fileText = fileTextArray[index];
			sectionType = "text";
		}

		if (ext === "draftraw") {
			richTextYN = true;
			sectionType = "rich text";
		}

		if (ext === "html") {
			htmlYN = true;
			sectionType = "html";
		}
		
		if (ext === "link") {
			linkYN = true;
			fileText = fileTextArray[index];
			sectionType = "link";
		}
		
		if (ext === "singlejson") {
			singleChoiceYN = true;
			fileText = fileTextArray[index];
			sectionType = "single choice";
		}
		
		if (ext === "pronjson") {
			pronounceYN = true;
			fileText = fileTextArray[index];
			sectionType = "pronounce";
		}

		return (
			<div key={index} className="PageEditSectionContainer">
				<div className="PageEditSectionTypeContainer">
					<div className="PageEditSectionTypeTitle">{sectionType}</div>
					<div className="PageEditSectionTypeIcon" onClick={() => this.handleEditSection(filenameExt, imageYN, textYN, richTextYN, htmlYN, linkYN, singleChoiceYN, pronounceYN)}>
						<AiOutlineEdit style={{ color: "blue", width: "15px", height: "15px" }} />
					</div>
					<div className="PageEditSectionTypeIcon" onClick={() => this.handleDeleteSection(filenameExt, imageYN, textYN, richTextYN, htmlYN, linkYN, singleChoiceYN, pronounceYN)}>
						<AiOutlineDelete style={{ color: "blue", width: "15px", height: "15px" }} />
					</div>
				</div>
				{imageYN && (<div>{this.renderImage(filenameExt, fileURL)}</div>)}
				{textYN && (<div>{this.renderText(filenameExt, fileText)}</div>)}
				{richTextYN && (<div>{this.renderRichText(filenameExt, filename)}</div>)}
				{htmlYN && (<div>{this.renderHtml(filenameExt, fileURL)}</div>)}
				{linkYN && (<div>{this.renderLink(filenameExt, fileText)}</div>)}
				{singleChoiceYN && (<div>{this.renderSingleChoice(filenameExt, fileText)}</div>)}
				{pronounceYN && (<div>{this.renderPronounce(filenameExt, fileText)}</div>)}
			</div>
		);
	}
	
	renderAllSections() {
		
		var pageURL = this.state.pageURL;
		if (pageURL === "") {
			return null;
		}
		
		var sectionArray = this.props.sectionArray;

		var length = sectionArray.length;
		if (length === 0) {
			return (
				<div className="PageEditText">
					<br/>No sections found.
				</div>
			);
		}
		
		var pageURL = this.state.pageURL;
		var i;
		var filenameExt;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<length; i++) {
			filenameExt = sectionArray[i];
			jsxCode = this.renderOneSection(i, filenameExt);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}
	
	renderNewHeaderFooter() {
		
		var modelLearnYN = this.props.modelLearnYN;
		
		return (
			<div className="PageEditFooterContainer">
				<div className="PageEditFooterText"><b>New section:</b></div>
				<div className="PageEditFooterLink" onClick={() => this.handleNewImage()}>image</div>
				<div className="PageEditFooterText">|</div>
				<div className="PageEditFooterLink" onClick={() => this.handleNewText()}>text</div>
				<div className="PageEditFooterText">|</div>
				<div className="PageEditFooterLink" onClick={() => this.handleNewRichText()}>rich text</div>
				<div className="PageEditFooterText">|</div>
				<div className="PageEditFooterLink" onClick={() => this.handleNewHtml()}>html</div>
				<div className="PageEditFooterText">|</div>
				<div className="PageEditFooterLink" onClick={() => this.handleNewLink()}>link</div>
				{modelLearnYN && (<div className="PageEditFooterText">|</div>)}
				{modelLearnYN && (<div className="PageEditFooterLink" onClick={() => this.handleNewSingleChoice()}>single choice</div>)}
				{modelLearnYN && (<div className="PageEditFooterText">|</div>)}
				{modelLearnYN && (<div className="PageEditFooterLink" onClick={() => this.handleNewPronounce()}>pronounce</div>)}

			</div>
		);
	}
	
	render() {
		
		var sectionArray = this.props.sectionArray;

		var length = 0;
		if (sectionArray !== null) {
			length = sectionArray.length;
		}

		return (
			<div className="PageEditTopContainer">
				{(length>0) && this.renderNewHeaderFooter()}
				<div style={{height: "20px"}}></div>
				{this.renderAllSections()}
				<div style={{height: "20px"}}></div>
				{this.renderNewHeaderFooter()}
				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function PageEditFunction(props) {
	const navigate = useNavigate();
	return <PageEdit {...props} navigate={navigate} />;
}
