import React  from 'react';
import './App.css';
import './Page.css';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { PageReorderCard } from './PageReorderCard.js'

const style = {
  width: 1000,
}

class PageReorder extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			cards: null,
		};

		this.serverSetPageOrder = this.serverSetPageOrder.bind(this);
		this.initCards = this.initCards.bind(this);
		this.moveCard = this.moveCard.bind(this);
		this.saveCards = this.saveCards.bind(this);
		this.renderCard = this.renderCard.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	componentDidMount() {
		var fileTextArray = this.props.fileTextArray;
		this.initCards(fileTextArray);
	}
	
	async serverSetPageOrder() {

		var i;
		var itemObj;
		var id;
		var cards = this.state.cards;
		var length = cards.length;

		var baseURLAPI = this.props.baseURLAPI;
		var modelID = this.props.modelID;
		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;
		var folder = pageFolder + "/" + pageSubfolder;

		var url = baseURLAPI + "/setpageorder?modelid=" + modelID;
		url = url + "&folder=" + encodeURIComponent(folder);
		url = url + "&count=" + length;
		
		for(i=0; i<length; i++) {
			itemObj = cards[i];
			id = itemObj.id;
			url = url + "&id" + i + "=" + encodeURIComponent(id);
		}
		
		const res = await fetch(url);
		await res.text();

		this.props.showToast("New order saved");
		await this.props.serverGetFolderList();
		this.props.setSelectedTab("edit");

		var title;
		var changeJsonObj;
		var changeJSON;

		title = "Reorder page: " + pageFolder + "/" + pageSubfolder;
		changeJsonObj = {};
		changeJsonObj.section = "pages";
		changeJsonObj.operation = "reorderpage";
		changeJsonObj.pageFolder = pageFolder;
		changeJsonObj.pageSubfolder = pageSubfolder;
		var changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);
	}
	
	initCards(fileTextArray) {

		var sectionArray = this.props.sectionArray;
		var baseURLAPI = this.props.baseURLAPI;
		var modelID = this.props.modelID;
		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;
		var pageURL = baseURLAPI + "/model/" + modelID + "/pages/" + pageFolder + "/" + pageSubfolder;
		var i;
		var cards = [];
		var itemObj;
		var filenameExt;
		var filename;
		var ext;
		var imageYN;
		var textYN;
		var richTextYN;
		var htmlYN;
		var linkYN;
		var singleChoiceYN;
		var pronounceYN;
		var rand;
		var fileURL;
		var fileText;
		var fileTextBr;

		var length = sectionArray.length;

		for(i=0; i<length; i++) {

			filenameExt = sectionArray[i];
			[filename, ext] = this.props.getFilenameAndExtFromFilenameExt(filenameExt);

			rand = Math.floor(Math.random() * 1000000);
			fileURL = pageURL + "/" + filenameExt + "?r=" + rand;
			if (ext === "draftraw") {
				fileURL = pageURL + "/" + filename + ".drafthtml" + "?r=" + rand;
			}

			fileText = "";
			fileTextBr = "";
			imageYN = false;
			textYN = false;
			richTextYN = false;
			htmlYN = false;
			linkYN = false;
			singleChoiceYN = false;
			pronounceYN = false;
			
			if ((ext === "jpg") || (ext === "jpeg") || (ext === "png")) {
				imageYN = true;
			}

			if (ext === "txt") {
				textYN = true;
				fileText = fileTextArray[i];
				fileTextBr = this.props.convertTextNewlineToBr(fileText);
			}

			if (ext === "draftraw") {
				richTextYN = true;
			}

			if (ext === "html") {
				htmlYN = true;
			}

			if (ext === "link") {
				linkYN = true;
				fileText = fileTextArray[i];
				fileTextBr = this.props.convertTextNewlineToBr(fileText);
			}

			if (ext === "singlejson") {
				singleChoiceYN = true;
				fileText = fileTextArray[i];
				var jsonObj = JSON.parse(fileText);
				var question = jsonObj.question;
				var answerArray = jsonObj.answers;
				fileTextBr = question + "\n";
				var firstAnswerYN = true;
				var j;
				var oneAnswer;
				for(j=0; j<answerArray.length; j++) {
					oneAnswer = answerArray[j];
					oneAnswer = oneAnswer.trim();
					if (oneAnswer !== "") {
						if (firstAnswerYN) {
							fileTextBr = fileTextBr + oneAnswer;
						} else {
							fileTextBr = fileTextBr + ", " + oneAnswer;
						}
						firstAnswerYN = false;
					}
				}
			}

			if (ext === "pronjson") {
				pronounceYN = true;
				fileText = fileTextArray[i];
				var jsonObj = JSON.parse(fileText);
				var lineText = jsonObj.text;
				fileTextBr = this.props.convertTextNewlineToBr(lineText);
			}

			itemObj = {};
			itemObj.id = filenameExt;
			itemObj.text = filenameExt;
			itemObj.imageYN = imageYN;
			itemObj.textYN = textYN;
			itemObj.richTextYN = richTextYN;
			itemObj.htmlYN = htmlYN;
			itemObj.linkYN = linkYN;
			itemObj.singleChoiceYN = singleChoiceYN;
			itemObj.pronounceYN = pronounceYN;
			itemObj.fileURL = fileURL;
			itemObj.fileTextBr = fileTextBr;
			cards.push(itemObj);
		}
		
		this.setState({cards: cards});
	}
	
    moveCard(dragIndex, hoverIndex) {

		var cards = this.state.cards;
		var itemObj1 = cards[dragIndex];
		var itemObj;
		var i;
		var cards2 = [];
		if (dragIndex > hoverIndex) {
			for(i=0; i<cards.length; i++) {
				itemObj = cards[i];
				if (i === hoverIndex) {
					cards2.push(itemObj1);
				}
				if (i !== dragIndex) {
					cards2.push(itemObj);
				}
			}
		} else {
			for(i=0; i<cards.length; i++) {
				itemObj = cards[i];
				if (i !== dragIndex) {
					cards2.push(itemObj);
				}
				if (i === hoverIndex) {
					cards2.push(itemObj1);
				}
			}
		}
		
		this.setState({cards: cards2});
    }

    saveCards() {
	}

    renderCard(card, index) {
		return (
			<PageReorderCard
				key={card.id}
				index={index}
				id={card.id}
				imageYN={card.imageYN}
				textYN={card.textYN}
				richTextYN={card.richTextYN}
				htmlYN={card.htmlYN}
				linkYN={card.linkYN}
				singleChoiceYN={card.singleChoiceYN}
				pronounceYN={card.pronounceYN}
				fileURL={card.fileURL}
				fileTextBr={card.fileTextBr}
				text={card.text}
				moveCard={this.moveCard}
				saveCards={this.saveCards}
			/>
		);
    }

	onClickSaveButton() {
		this.serverSetPageOrder();
	}
	
	onClickCancelButton() {
		this.props.setSelectedTab("view");
	}
	
	render() {

		var cards = this.state.cards;
		if ((cards === null) || (cards.length === 0)) {
			return (
				<div className="PageEditText">
					<br/>No sections found.
				</div>
			);
		}
		
		return (
			<div>
				<div style={{height: "50px"}}></div>

				<div className="QuestionSampleReorderTopContainer">
					<DndProvider backend={HTML5Backend}>
						<div style={style}>{cards.map((card, i) => this.renderCard(card, i))}</div>
					</DndProvider>
				</div>

				<div style={{height: "40px"}}></div>
				<div className="QuestionSampleReorderTopContainer">
					<div className="ViewContainerButtons">
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>Cancel</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>Reorder</button>
					</div>
				</div>
				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default PageReorder;

