import React  from 'react';
import './App.css';
import { useNavigate } from "react-router-dom";

class ModelListToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(page) {		
		const navigate = this.props.navigate;
  		navigate(page);
	}
	
	renderLink(renderYN, linkTitle, linkValue) {
		
		if (!renderYN) {
			return;
		}

		var classNameString = "ModelListToolbarLink";
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	render() {

		var modelName = this.props.modelName;		
		var newString = "New model";
		var newFromWebSiteString = "New model from web site";
		var newJobsString = "New model jobs";
		
		return (
			<div className="ModelListToolbarContainer1">
				<div className="ModelListToolbarLeft">
					<div 
						className="ModelListToolbarLink"
						onClick={() => this.handleSelectLink("/selectmodel")}
					>
						{modelName}
					</div>
				</div>
				<div className="ModelListToolbarRight">
					<div className="ModelListToolbarContainer2">
						{this.renderLink(true, newString, "/models/new")}
						<div className="DocumentViewToolbarText">|</div>
						{this.renderLink(true, newFromWebSiteString, "/models/newfromwebsite")}
						<div className="DocumentViewToolbarText">|</div>
						{this.renderLink(true, newJobsString, "/models/newfromwebsitejoblist")}
					</div>
				</div>
			</div>
		);
	}
}

export default function ModelListToolbarFunction(props) {
	const navigate = useNavigate();
	return <ModelListToolbar {...props} navigate={navigate} />;
}

