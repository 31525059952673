import React  from 'react';
import './App.css';
import './Page.css';

import { useNavigate } from "react-router-dom";

class PageRename extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			pageSubfolder: "",
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.normalizeFilename = this.normalizeFilename.bind(this);
		this.onPageSubfolderChanged = this.onPageSubfolderChanged.bind(this);
		this.serverSetPageName = this.serverSetPageName.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	componentDidMount() {
		var pageSubfolder = this.props.pageSubfolder;
		this.setState({
			pageSubfolder: pageSubfolder,
		});
	}
	
	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	normalizeFilename(filename1) {
		
		var i;
		var ch;
		var filename2 = "";
		var allowedYN;
		
		filename1 = filename1.toLowerCase();
		
		for(i=0; i<filename1.length; i++) {

			ch = filename1.charAt(i);

			allowedYN = false;
			if (ch === "_") {
				allowedYN = true;
			}
			if ((ch >= "0") && (ch <= "9")) {
				allowedYN = true;
			}
			if ((ch >= "a") && (ch <= "z")) {
				allowedYN = true;
			}
			
			if (allowedYN) {
				filename2 = filename2 + ch;
			}
		}
		
		return filename2;
	}

	onPageSubfolderChanged(value) {
		var subfolder = this.normalizeFilename(value);
		this.setState({pageSubfolder: subfolder});
	}
	
	async serverSetPageName(modelID, folder, subfolder1, subfolder2) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/setpagename?modelid=" + modelID;
		url = url + "&folder=" + encodeURIComponent(folder);
		url = url + "&subfolder1=" + encodeURIComponent(subfolder1);
		url = url + "&subfolder2=" + encodeURIComponent(subfolder2);
		
		const res = await fetch(url);
		var responseText = await res.text();

		if (responseText === "0") {
			this.props.showToast("Page renamed");
			//this.props.setSelectedTab("view");
			//this.props.setSelectedPageSubfolder(subfolder2);
			//this.props.serverGetFolderList();
			const navigate = this.props.navigate;
			navigate("/pages");
		} else {
			this.props.showToast("Page NOT renamed");
		}
	}
		
	onClickSaveButton() {
		
		var pageSubfolder = this.state.pageSubfolder;
		if (pageSubfolder === "") {
			this.props.showToast("Page name cannot be empty");
			return;
		}

		pageSubfolder = pageSubfolder.trim();
		pageSubfolder = pageSubfolder.toLowerCase();

		if (pageSubfolder === this.props.pageSubfolder) {
			this.props.showToast("Page name was not changed");
			return;
		}
		
		var modelID = this.props.modelID;
		var pageFolder = this.props.pageFolder;
		this.serverSetPageName(modelID, pageFolder, this.props.pageSubfolder, pageSubfolder);

		var title;
		var changeJsonObj;
		var changeJSON;

		title = "Rename page: " + pageFolder + "/" + pageSubfolder;
		changeJsonObj = {};
		changeJsonObj.section = "pages";
		changeJsonObj.operation = "renamepage";
		changeJsonObj.pageFolder = pageFolder;
		changeJsonObj.pageSubfolder = pageSubfolder;
		var changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);
	}
	
	onClickCancelButton() {
		this.props.setSelectedTab("view");
	}
	
	render() {

		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">Rename page</div>
				<div className="ViewTopText">Change page name and press Save button</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">Page Info</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<p></p>
						<fieldset className="DocumentNewFieldset">
							<legend className="DocumentNewLegend">Page name</legend> 
							<input
								className="DocumentNewInput" 
								id="pageSubfolder" 
								type="text" 
								value={this.state.pageSubfolder}
								autoFocus={true}
								onChange={(event) => this.onPageSubfolderChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>Cancel</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>Rename</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function PageRenameFunction(props) {
	const navigate = useNavigate();
	return <PageRename {...props} navigate={navigate} />;
}


