import React  from 'react';
import './App.css';
import './QuestionLog.css';

class QuestionLogView extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			jsonObj: null,
		};

		this.serverGetQuestionLogData = this.serverGetQuestionLogData.bind(this);
		this.separateAnswerText = this.separateAnswerText.bind(this);
	}
	
	componentDidMount() {
		var questionLogID = this.props.questionLogID;
		this.serverGetQuestionLogData(questionLogID);
	}
	
	async serverGetQuestionLogData(questionLogID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getquestionlogdata?questionlogid=" + questionLogID;

		const res = await fetch(url);
		const jsonObj = await res.json();
		
		this.setState({
			jsonObj: jsonObj,
		});
	}
	
	separateAnswerText(answerText) {
		
		var index = answerText.indexOf("[DONE]");
		
		var answerText1; 
		var answerText2;
		
		if (index === -1) {
			answerText1 = answerText;
			answerText2 = "";
		} else {
			answerText1 = answerText.substring(0, index-1);
			answerText2 = answerText.substring(index+6, answerText.length);
		}

		return [answerText1, answerText2];
	}
	
	render() {
		
		var jsonObj = this.state.jsonObj;
		if (jsonObj === null) {
			return;
		}
		
		var questionLogID = this.props.questionLogID;
		var questionText = jsonObj.questionText;
		var createdUnixTimeSec = jsonObj.createdUnixTimeSec;
		var answerText = jsonObj.answerText;
		var inputWordCount = jsonObj.inputWordCount;
		var outputWordCount = jsonObj.outputWordCount;
		var channel = jsonObj.channel;
		var llmModel = jsonObj.llmModel;
		var fullName = jsonObj.fullName;
		
		var createdString = this.props.getDateTimeStringFromUnixTimeSec(createdUnixTimeSec);
		var tokenString = inputWordCount + "/" + outputWordCount;
		
		var answerText1 = "";
		var answerText2 = "";
		
		[answerText1, answerText2] = this.separateAnswerText(answerText);

		return (
			<div className="QuestionLogViewTopContainer">
				<div style={{height: "20px"}}></div>
				<div className="QuestionLogViewText">ID: {questionLogID} - {createdString} - {tokenString} - {llmModel} - {channel} - {fullName}</div>
				<div style={{height: "20px"}}></div>
				<div><b>{questionText}</b></div>
				<div style={{height: "20px"}}></div>
				<div>{this.props.convertTextNewlineToBr(answerText1)}</div>
				<div style={{height: "20px"}}></div>
				<div>{answerText2}</div>
			</div>
		);
	}
}

export default QuestionLogView;

