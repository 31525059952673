import React  from 'react';
import './App.css';
import './QuestionSample.css';
import QuestionSampleLang from './QuestionSampleLang.json';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { useNavigate } from "react-router-dom";

class QuestionSampleEdit extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			questionSampleCategoryListJsonObj: null,
			questionText: "",
			questionTextOriginal: "",
			questionSampleCategoryID: 0,
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.serverGetQuestionSampleCategoryList = this.serverGetQuestionSampleCategoryList.bind(this);
		this.serverGetQuestionSampleData = this.serverGetQuestionSampleData.bind(this);
		this.serverSetQuestionSampleText = this.serverSetQuestionSampleText.bind(this);
		this.serverDeleteQuestionSampleRecord = this.serverDeleteQuestionSampleRecord.bind(this);
		this.onQuestionTextChanged = this.onQuestionTextChanged.bind(this);
		this.onCategoryChanged = this.onCategoryChanged.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
		this.onClickDeleteButton = this.onClickDeleteButton.bind(this);
		this.renderOneCategory = this.renderOneCategory.bind(this);				
		this.renderAllCategories = this.renderAllCategories.bind(this);				
	}
	
	componentDidMount() {
		var questionSampleID = this.props.questionSampleID;
		this.serverGetQuestionSampleData(questionSampleID);
		this.serverGetQuestionSampleCategoryList();
	}

	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	async serverGetQuestionSampleCategoryList() {

		var modelID = this.props.modelID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getquestionsamplecategorylist?modelid=" + modelID;
		
		const res = await fetch(url);
		const questionSampleCategoryListJsonObj = await res.json();
		
		this.setState({
			questionSampleCategoryListJsonObj: questionSampleCategoryListJsonObj,
		});
	}
		
	onQuestionTextChanged(value) {
		this.setState({questionText: value});
	}
	
	onCategoryChanged(value) {
		this.setState({questionSampleCategoryID: value});
	}
	
	async serverGetQuestionSampleData(questionSampleID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getquestionsamplerecord?questionsampleid=" + questionSampleID;
		
		const res = await fetch(url);
		var jsonObj = await res.json();
		var questionText = jsonObj.questionText;
		var questionSampleCategoryID = jsonObj.questionSampleCategoryID;
		
		this.setState({
			questionText: questionText,
			questionTextOriginal: questionText,
			questionSampleCategoryID: questionSampleCategoryID,
		});
	}
	
	async serverSetQuestionSampleText(questionSampleID, questionText, questionSampleCategoryID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/setquestionsampletext?questionsampleid=" + questionSampleID;
		url = url + "&questionsamplecategoryid=" + questionSampleCategoryID;
		url = url + "&questiontext=" + encodeURIComponent(questionText);
		
		const res = await fetch(url);
		await res.text();

		this.props.showToast(this.props.getLang(QuestionSampleLang, "questionSampleSaved"));
		const navigate = this.props.navigate;
  		navigate("/samplequestions");
	}
	
	async serverDeleteQuestionSampleRecord(questionSampleID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/deletequestionsamplerecord?questionsampleid=" + questionSampleID;
		
		const res = await fetch(url);
		await res.text();

		var questionTextOriginal = this.state.questionTextOriginal;
		var title = "Delete sample question: " + questionTextOriginal;
		var changeJsonObj = {};
		changeJsonObj.section = "questionsample";
		changeJsonObj.operation = "delete";
		changeJsonObj.value = questionTextOriginal;
		var changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);

		this.props.showToast(this.props.getLang(QuestionSampleLang, "questionDeleted"));

		const navigate = this.props.navigate;
  		navigate("/samplequestions");
	}
		
	onClickSaveButton() {
		
		var questionText = this.state.questionText;
		if (questionText === "") {
			this.props.showToast("Sample question cannot be empty");
			return;
		}

		var questionSampleID = this.props.questionSampleID;
		var questionSampleCategoryID = this.state.questionSampleCategoryID;
		this.serverSetQuestionSampleText(questionSampleID, questionText, questionSampleCategoryID);

		var questionTextOriginal = this.state.questionTextOriginal;
		var title = "Edit sample question: " + questionText;
		var changeJsonObj = {};
		changeJsonObj.section = "questionsample";
		changeJsonObj.operation = "edit";
		changeJsonObj.oldValue = questionTextOriginal;
		changeJsonObj.newValue = questionText;
		var changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/samplequestions");
	}
	
	onClickDeleteButton() {
		var questionSampleID = this.props.questionSampleID;
		confirmAlert({
			title: 'Delete question',
			message: 'Are you sure you want to delete this question?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => this.serverDeleteQuestionSampleRecord(questionSampleID)
				},
				{
					label: 'No',
				}
			]
		});
	}
	
	renderOneCategory(itemObj) {

		var questionSampleCategoryID = itemObj.questionSampleCategoryID;
		var categoryTitle = itemObj.categoryTitle;
		var key = "category-" + questionSampleCategoryID;
		
		return (
			<option key={key} value={questionSampleCategoryID}>
				{categoryTitle}
			</option>
		);
	}

	renderAllCategories() {

		var questionSampleCategoryListJsonObj = this.state.questionSampleCategoryListJsonObj;
		if (questionSampleCategoryListJsonObj === null) {
			return;
		}

		var length = questionSampleCategoryListJsonObj.length;
		if (length === 0) {
			return;
		}
		
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<length; i++) {
			itemObj = questionSampleCategoryListJsonObj[i];
			jsxCode = this.renderOneCategory(itemObj);
			jsxArray.push(jsxCode);
		}

		var questionSampleCategoryID = this.state.questionSampleCategoryID;

		return (
			<select 
				className="QuestionSampleNewQuestionSampleInput" 
				value={questionSampleCategoryID} 
				onChange={(event) => this.onCategoryChanged(event.target.value)}
			>
				{jsxArray}
			</select>
		);
	}

	render() {		

		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(QuestionSampleLang, "editQuestionSample")}</div>
				<div className="ViewTopText">{this.props.getLang(QuestionSampleLang, "editQuestionSampleInfoText")}</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(QuestionSampleLang, "questionInfo")}</div>
					<div style={{height: "40px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="QuestionSampleEditFieldset">
							<legend className="QuestionSampleEditQuestionSampleLegend">{this.props.getLang(QuestionSampleLang, "questionSample")}</legend> 
							<input
								className="QuestionSampleEditQuestionSampleInput" 
								id="questionSample" 
								type="text" 
								value={this.state.questionText}
								autoFocus={true}
								onChange={(event) => this.onQuestionTextChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="QuestionSampleEditFieldset">
							<legend className="QuestionSampleEditQuestionSampleLegend">{this.props.getLang(QuestionSampleLang, "questionCategory")}</legend> 
							{this.renderAllCategories()}
						</fieldset>
					</div>

					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "300px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(QuestionSampleLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(QuestionSampleLang, "save")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>
				
				<div style={{height: "50px"}}></div>
				<button className="ViewSaveButton" onClick={this.onClickDeleteButton}>{this.props.getLang(QuestionSampleLang, "deleteQuestion")}</button>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function QuestionSampleEditFunction(props) {
	const navigate = useNavigate();
	return <QuestionSampleEdit {...props} navigate={navigate} />;
}

