import React  from 'react';
import './App.css';
import './Document.css';
import DocumentLang from './DocumentLang.json';

import { useNavigate } from "react-router-dom";

class DocumentEditQuestions extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			questionListJsonObj: null,
			questionTextArray: [],
			questionTextOrigArray: [],
			questionChangedYNArray: [],
		};

		this.vectorServerGetFilenameQuestionList = this.vectorServerGetFilenameQuestionList.bind(this);
		this.vectorServerInsertQuestion = this.vectorServerInsertQuestion.bind(this);
		this.vectorServerDeleteQuestion = this.vectorServerDeleteQuestion.bind(this);
		this.vectorServerSetQuestionText = this.vectorServerSetQuestionText.bind(this);
		this.navigateWithDelay = this.navigateWithDelay.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
		this.onQuestionTextChanged = this.onQuestionTextChanged.bind(this);
		this.renderOneQuestion = this.renderOneQuestion.bind(this);
		this.renderAllQuestions = this.renderAllQuestions.bind(this);
	}
	
	componentDidMount() {
		var modelID = this.props.modelID;
		var documentFilename = this.props.documentFilename;
		this.vectorServerGetFilenameQuestionList(modelID, documentFilename);
	}

	async vectorServerGetFilenameQuestionList(modelID, filename) {

		var vectorBaseURLAPI = this.props.vectorBaseURLAPI;
		var url = vectorBaseURLAPI + "/getfilenamequestionlist?modelid=" + modelID + "&filename=" + encodeURIComponent(filename);
		
		const res = await fetch(url);
		const questionListJsonObj = await res.json();
		
		var questionTextArray = [];
		var questionTextOrigArray = [];
		var questionChangedYNArray = [];
		
		var i;
		var itemObj;
		var questionText;

		for(i=0; i<questionListJsonObj.length; i++) {
			itemObj = questionListJsonObj[i];
			questionText = itemObj.questionText;
			questionTextArray.push(questionText);
			questionTextOrigArray.push(questionText);
			questionChangedYNArray.push(false);
		}
		
		for(i=0; i<5; i++) {
			questionText = "";
			questionTextArray.push(questionText);
			questionTextOrigArray.push(questionText);
			questionChangedYNArray.push(false);
		}

		this.setState({
			questionListJsonObj: questionListJsonObj,
			questionTextArray: questionTextArray,
			questionTextOrigArray: questionTextOrigArray,
			questionChangedYNArray: questionChangedYNArray,
		});
	}
	
	async vectorServerInsertQuestion(modelID, filename, questionText) {

		var vectorBaseURLAPI = this.props.vectorBaseURLAPI;
		var url = vectorBaseURLAPI + "/insertquestion?modelid=" + modelID;
		url = url + "&filename=" + encodeURIComponent(filename);
		url = url + "&question=" + encodeURIComponent(questionText);
		
		const res = await fetch(url);
		const jsonObj = await res.json();
	}
	
	async vectorServerDeleteQuestion(id) {

		var vectorBaseURLAPI = this.props.vectorBaseURLAPI;
		var url = vectorBaseURLAPI + "/deletequestion?id=" + encodeURIComponent(id);
		
		const res = await fetch(url);
		const jsonObj = await res.json();
	}
	
	async vectorServerSetQuestionText(id, questionText) {

		var vectorBaseURLAPI = this.props.vectorBaseURLAPI;
		var url = vectorBaseURLAPI + "/setquestiontext?id=" + encodeURIComponent(id);
		url = url + "&question=" + encodeURIComponent(questionText);
		
		const res = await fetch(url);
		const jsonObj = await res.json();
	}

	onQuestionTextChanged(index, value) {

		var questionTextArray = this.state.questionTextArray;
		var questionChangedYNArray = this.state.questionChangedYNArray;

		questionTextArray[index] = value;
		questionChangedYNArray[index] = true;
		
		this.setState({
			questionTextArray: questionTextArray,
			questionChangedYNArray: questionChangedYNArray,
		});
	}
	
	onClickSaveButton() {
		
		var modelID = this.props.modelID;
		var documentFilename = this.props.documentFilename;
		var questionTextArray = this.state.questionTextArray;
		var questionTextOrigArray = this.state.questionTextOrigArray;
		var questionChangedYNArray = this.state.questionChangedYNArray;
		var questionListJsonObj = this.state.questionListJsonObj;
		
		var i;
		var itemObj;
		var id;
		var questionText;
		var questionTextOrig;
		var questionChangedYN;
		var newValue;
		var oldValue;
		var title;
		var changeJsonObj;
		var changeJSON;

		var length1 = questionListJsonObj.length;
		var length2 = questionTextArray.length;
		
		// process existing questions - delete or update

		for(i=0; i<length1; i++) {

			questionText = questionTextArray[i];
			questionText = questionText.trim();
			questionTextOrig = questionTextOrigArray[i];

			questionChangedYN = questionChangedYNArray[i];

			if (questionChangedYN) {
				
				itemObj = questionListJsonObj[i];				
				id = itemObj._additional.id;
				
				if (questionText === "") {
					this.vectorServerDeleteQuestion(id);
					oldValue = questionTextOrig;
					title = "Delete document question: " + documentFilename;
					changeJsonObj = {};
					changeJsonObj.section = "docs";
					changeJsonObj.operation = "deletequestion";
					changeJsonObj.filename = documentFilename;
					changeJsonObj.oldValue = oldValue;
					changeJSON = JSON.stringify(changeJsonObj);
					this.props.serverInsertChangeLogRecord(title, changeJSON);
				} else {
					this.vectorServerSetQuestionText(id, questionText);
					newValue = questionText;
					oldValue = questionTextOrig;
					title = "Edit document question: " + documentFilename;
					changeJsonObj = {};
					changeJsonObj.section = "docs";
					changeJsonObj.operation = "editquestion";
					changeJsonObj.filename = documentFilename;
					changeJsonObj.newValue = newValue;
					changeJsonObj.oldValue = oldValue;
					changeJSON = JSON.stringify(changeJsonObj);
					this.props.serverInsertChangeLogRecord(title, changeJSON);
				}
			}
		}
		
		// process new questions - insert

		for(i=length1; i<length2; i++) {

			questionText = questionTextArray[i];
			questionText = questionText.trim();

			if (questionText !== "") {
				this.vectorServerInsertQuestion(modelID, documentFilename, questionText);
				newValue = questionTextOrig;
				title = "New document question: " + documentFilename;
				changeJsonObj = {};
				changeJsonObj.section = "docs";
				changeJsonObj.operation = "newquestion";
				changeJsonObj.filename = documentFilename;
				changeJsonObj.value = newValue;
				changeJSON = JSON.stringify(changeJsonObj);
				this.props.serverInsertChangeLogRecord(title, changeJSON);
			}
		}

		setTimeout(this.navigateWithDelay, 2000);
	}
	
	navigateWithDelay() {
		const navigate = this.props.navigate;
  		navigate("/docs/view");
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/docs/view");
	}
	
	renderOneQuestion(index, questionText) {
		
		var id = "questionText" + index;
		
		var autoFocusYN = false;
		if (index === 0) {
			autoFocusYN = true;
		}
		
		return (
			<div key={index}>
				<input
					className="DocumentEditQuestionsInput" 
					id={id}
					type="text" 
					defaultValue={questionText}
					autoFocus={autoFocusYN}
					onChange={(event) => this.onQuestionTextChanged(index, event.target.value)}
				/>
				<div style={{height: "10px"}}></div>
			</div>
		);
	}
	
	renderAllQuestions() {

		var questionTextArray = this.state.questionTextArray;
		
		var i;
		var length = questionTextArray.length;
		var questionText;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<length; i++) {
			questionText = questionTextArray[i];
			jsxCode = this.renderOneQuestion(i, questionText);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}

	render() {		

		var questionListJsonObj = this.state.questionListJsonObj;
		if (questionListJsonObj === null) {
			return;
		}

		return (
			<div className="ViewContainer1">

				<div style={{height: "30px"}}></div>
				{this.renderAllQuestions()}
				<div style={{height: "20px"}}></div>
				<div className="DocumentEditQuestionsCenter">
					<div className="ViewContainerButtons">
						<div style={{width: "40px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(DocumentLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(DocumentLang, "save")}</button>
					</div>
				</div>
				<div style={{height: "20px"}}></div>
			</div>
		);
	}
}

export default function DocumentEditQuestionsFunction(props) {
	const navigate = useNavigate();
	return <DocumentEditQuestions {...props} navigate={navigate} />;
}

