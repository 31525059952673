import React  from 'react';
import './App.css';
import { useNavigate } from "react-router-dom";

class QuestionLogListToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(page) {		
		const navigate = this.props.navigate;
  		navigate(page);
	}
	
	renderLink(renderYN, linkTitle, linkValue) {
		
		if (!renderYN) {
			return;
		}

		var classNameString = "QuestionLogListToolbarLink";
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	render() {

		var modelName = this.props.modelName;
		//var chatAppUserType = this.props.chatAppUserType;
		
		return (
			<div className="QuestionLogListToolbarContainer1">
				<div className="QuestionLogListToolbarLeft">
					<div 
						className="QuestionLogListToolbarLink"
						onClick={() => this.handleSelectLink("/selectmodel")}
					>
						{modelName}
					</div>
				</div>
				<div className="QuestionLogListToolbarRight">
				</div>
			</div>
		);
	}
}

export default function QuestionLogListToolbarFunction(props) {
	const navigate = useNavigate();
	return <QuestionLogListToolbar {...props} navigate={navigate} />;
}

