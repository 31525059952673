import React  from 'react';
import './App.css';
import './UserLesson.css';

import { useNavigate } from "react-router-dom";

class UserLessonLogList extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			userSettingsListJsonObj: null,
		};

		this.serverGetUserSettingsList = this.serverGetUserSettingsList.bind(this);
		this.handleSelectUser = this.handleSelectUser.bind(this);
		this.renderOneUser = this.renderOneUser.bind(this);
		this.renderAllUsers = this.renderAllUsers.bind(this);
	}
	
	componentDidMount() {
		this.serverGetUserSettingsList();
	}
	
	async serverGetUserSettingsList() {

		this.setState({
			userSettingsListJsonObj: null,
		});
		
		var companyID = this.props.companyID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getusersettingslist?companyid=" + companyID;

		const res = await fetch(url);

		var userSettingsListJsonObj = null;
		try {
			userSettingsListJsonObj = await res.json();
		} catch (err) {
			userSettingsListJsonObj = null;
		}
		
		this.setState({
			userSettingsListJsonObj: userSettingsListJsonObj,
		});
	}
	
	handleSelectUser(studentID, studentFullName) {
		this.props.setSelectedStudent(studentID, studentFullName);
		const navigate = this.props.navigate;
  		navigate("/userlessonlog/view");
	}

	renderOneUser(itemObj) {
		
		var userSettingsID = itemObj.userSettingsID;
		var userID = itemObj.userID;
		var userFullName = itemObj.userFullName;
		var lessonID = itemObj.lessonID;		
		var lastLessonUnixTimeSec = itemObj.lastLessonUnixTimeSec;
		
		if (userFullName === "") {
			return null;
		}
		
		var lastLessonString = this.props.getDateTimeStringFromUnixTimeSec(lastLessonUnixTimeSec);
		
		return (
			<tr key={userSettingsID} className="UserLessonLogListItemContainer" onClick={() => this.handleSelectUser(userID, userFullName)}> 
				<td className="UserLessonLogListFullNameItem">{userFullName}</td>
				<td className="UserLessonLogListDateTimeItem">{lastLessonString}</td>
			</tr>
		);
	}

	renderAllUsers() {

		var userSettingsListJsonObj = this.state.userSettingsListJsonObj;

		var length = userSettingsListJsonObj.length;
		if (length === 0) {
			return (
				<div className="UserLessonLogListText">
					No students found.
				</div>
			);
		}
		
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<length; i++) {
			itemObj = userSettingsListJsonObj[i];
			jsxCode = this.renderOneUser(itemObj);
			jsxArray.push(jsxCode);
		}
		
		return (
			<table className="ListContainer2">
				<tbody>
					<tr>
						<td className="UserLessonLogListFullNameHeader">Student</td>
						<td className="UserLessonLogListDateTimeHeader">Date/time</td>
					</tr>
					{jsxArray}
				</tbody>
			</table>
		);
	}

	render() {
		
		var userSettingsListJsonObj = this.state.userSettingsListJsonObj;
		if (userSettingsListJsonObj === null) {
			return;
		}

		return (
			<div className="UserLessonLogListTopContainer">
				<div style={{height: "20px"}}></div>
				{this.renderAllUsers()}
				<div style={{height: "40px"}}></div>
			</div>
		);
	}
}

export default function UserLessonLogListFunction(props) {
	const navigate = useNavigate();
	return <UserLessonLogList {...props} navigate={navigate} />;
}


