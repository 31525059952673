import React  from 'react';
import './App.css';
import './Document.css';
//import DocumentLang from './DocumentLang.json';

import DocumentListToolbar from './DocumentListToolbar.js';

import { useNavigate } from "react-router-dom";

class DocumentList extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			folderListJsonObj: null,
			docArray: [],
		};
		
		this.getFilenameAndExtFromFilenameExt = this.getFilenameAndExtFromFilenameExt.bind(this);
		this.serverGetFolderList = this.serverGetFolderList.bind(this);
		this.handleSelectDocument = this.handleSelectDocument.bind(this);
		this.renderOneFile = this.renderOneFile.bind(this);
		this.renderAllFiles = this.renderAllFiles.bind(this);
	}
	
	componentDidMount() {
		this.serverGetFolderList();
	}
	
	getFilenameAndExtFromFilenameExt(filenameExt) {
		var length = filenameExt.length;
		var filename = filenameExt.substring(0, length-4);
		var ext = filenameExt.substring(length-3, length);
		ext = ext.toLowerCase();
		return [filename, ext];
	}
	
	async serverGetFolderList() {
		
		var modelID = this.props.modelID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getfolderlist?modelid=" + modelID + "&folder=txt";
		
		const res = await fetch(url);
		const folderListJsonObj = await res.json();
		
		var i;
		var itemObj;
		var filenameExt;
		var filename;
		var ext;
		var docArray = [];
		
		for(i=0; i<folderListJsonObj.length; i++) {
			itemObj = folderListJsonObj[i];
			filenameExt = itemObj.filename;
			[filename, ext] = this.getFilenameAndExtFromFilenameExt(filenameExt);
			if (ext === "txt") {
				docArray.push(filename);
			}
		}
		
		this.setState({
			folderListJsonObj: folderListJsonObj,
			docArray: docArray,
		});
	}
	
	handleSelectDocument(filename) {
		this.props.setSelectedDocument(filename);
		const navigate = this.props.navigate;
  		navigate("/docs/view");
	}

	renderOneFile(index, filename) {
		return (
			<div key={index} className="DocumentListFilename" onClick={() => this.handleSelectDocument(filename)}>
				{filename}
			</div>
		);
	}
	
	renderAllFiles() {

		var folderListJsonObj = this.state.folderListJsonObj;
		if (folderListJsonObj === null) {
			return;
		}
		
		var docArray = this.state.docArray;
		
		var length = docArray.length;
		if (length === 0) {
			return (
				<div className="SampleQuestionsQuestionText">
					No documents found.
				</div>
			);
		}
		
		var i;
		var filename;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<length; i++) {
			filename = docArray[i];
			jsxCode = this.renderOneFile(i, filename);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}
	
	render() {

		return (
			<div>
				<DocumentListToolbar 
					modelName={this.props.modelName}
					chatAppUserType={this.props.chatAppUserType}
				/>
				<div style={{height: "20px"}}></div>
				{this.renderAllFiles()}
			</div>
		);
	}
}

export default function DocumentListFunction(props) {
	const navigate = useNavigate();
	return <DocumentList {...props} navigate={navigate} />;
}

