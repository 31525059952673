
export class ModelCopyHelpers {

	constructor() {
		this.baseURLAPI = "";
		this.vectorBaseURLAPI = "";
	}
	
	init() {
		this.sourceModelID = 0;
		this.sourceModelName = "";
		this.modelName = "";
		this.helloMessage = "";
		this.systemMessage = "";
		this.learn = 0;
		this.loginRequired = 0;
		this.llmDocCount = 1;
		this.urlShortcut = "";
		this.llmModel = "llama2-7b";
		this.copyInProgressYN = false;
		this.questionSampleCategoryListJsonObj = null;
		this.docArray = [];
		this.pageListJsonObj = null;
		this.progressMessageArray = [];
		this.refresh = null;
	}

	addTwoNumbers(a, b) {
		return a+b;
	}
	
	async serverGetModelData(modelID) {

		var baseURLAPI = this.baseURLAPI;
		var url = baseURLAPI + "/getmodeldata?modelid=" + modelID;
		
		const res = await fetch(url);
		var jsonObj = await res.json();

		this.sourceModelName = jsonObj.modelName;
		this.helloMessage = jsonObj.helloMessage;
		this.systemMessage = jsonObj.systemMessage;
		this.learn = jsonObj.learn;
		this.loginRequired = jsonObj.loginRequired;
		this.llmDocCount = jsonObj.llmDocCount;
		this.urlShortcut = jsonObj.urlShortcut;
		this.refresh();
	}
	
	async serverInsertModelRecord(modelName, helloMessage, systemMessage, llmModel) {
		
		var baseURLAPI = this.baseURLAPI;
		var url = baseURLAPI + "/insertmodelrecord?modelname=" + encodeURIComponent(modelName);
		url = url + "&hellomessage=" + encodeURIComponent(helloMessage);
		url = url + "&systemmessage=" + encodeURIComponent(systemMessage);
		url = url + "&llmmodel=" + encodeURIComponent(llmModel);
		url = url + "&copy=1";
		
		const res = await fetch(url);
		var responseText = await res.text();
		var modelID = parseInt(responseText);
		
		return modelID;
	}
		
	async serverGetQuestionSampleCategoryList(modelID) {

		var baseURLAPI = this.baseURLAPI;
		var url = baseURLAPI + "/getquestionsamplecategorylist?modelid=" + modelID;
		
		const res = await fetch(url);
		const questionSampleCategoryListJsonObj = await res.json();
		
		this.questionSampleCategoryListJsonObj = questionSampleCategoryListJsonObj;
	}

	async serverGetQuestionSampleList(questionSampleCategoryID) {

		var baseURLAPI = this.baseURLAPI;
		var url = baseURLAPI + "/getquestionsamplelist?questionsamplecategoryid=" + questionSampleCategoryID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();
		
		return jsonObj;		
	}	

	async serverInsertQuestionSampleCategoryRecord(modelID, categoryTitle) {
		
		var baseURLAPI = this.baseURLAPI;
		var url = baseURLAPI + "/insertquestionsamplecategoryrecord?modelid=" + modelID;
		url = url + "&categorytitle=" + encodeURIComponent(categoryTitle);
		
		const res = await fetch(url);
		var responseText = await res.text();
		var questionSampleCategoryID = parseInt(responseText);
		
		return questionSampleCategoryID;
	}
		
	async serverInsertQuestionSampleRecord(questionSampleCategoryID, questionText) {
		
		var baseURLAPI = this.baseURLAPI;
		var url = baseURLAPI + "/insertquestionsamplerecord?questionsamplecategoryid=" + questionSampleCategoryID;
		url = url + "&questiontext=" + encodeURIComponent(questionText);
		
		const res = await fetch(url);
		await res.text();
	}
		
	async serverCopyQuestionSampleCategory(modelID) {

		var message = "";
		var progressMessageArray = this.progressMessageArray;
		var questionSampleCategoryListJsonObj = this.questionSampleCategoryListJsonObj;
		
		// loop over all categories
		var i;
		var itemObj;
		var questionSampleCategoryID1;
		var questionSampleCategoryID2;
		var categoryTitle;

		for(i=0; i<questionSampleCategoryListJsonObj.length; i++) {

			itemObj = questionSampleCategoryListJsonObj[i];
			questionSampleCategoryID1 = itemObj.questionSampleCategoryID;
			categoryTitle = itemObj.categoryTitle;

			questionSampleCategoryID2 = await this.serverInsertQuestionSampleCategoryRecord(modelID, categoryTitle);

			await this.serverCopyQuestionSample(questionSampleCategoryID1, questionSampleCategoryID2);

			message = "Sample questions copied in category: '" + categoryTitle + "'";
			progressMessageArray.push(message);
			this.progressMessageArray = progressMessageArray;
			this.refresh();
		}

		progressMessageArray.push("-");
		this.progressMessageArray = progressMessageArray;
		this.refresh();
	}
	
	async serverCopyQuestionSample(questionSampleCategoryID1, questionSampleCategoryID2) {

		var jsonObj = await this.serverGetQuestionSampleList(questionSampleCategoryID1);

		// loop over all questions in one category
		var i;
		var itemObj;
		var questionText;

		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			questionText = itemObj.questionText;
			await this.serverInsertQuestionSampleRecord(questionSampleCategoryID2, questionText);
		}
	}

	getFilenameAndExtFromFilenameExt(filenameExt) {
		var length = filenameExt.length;
		var filename = filenameExt.substring(0, length-4);
		var ext = filenameExt.substring(length-3, length);
		ext = ext.toLowerCase();
		return [filename, ext];
	}
	
	async serverGetFolderList(modelID) {
		
		var baseURLAPI = this.baseURLAPI;
		var url = baseURLAPI + "/getfolderlist?modelid=" + modelID + "&folder=txt";
		
		const res = await fetch(url);
		const folderListJsonObj = await res.json();
		
		var i;
		var itemObj;
		var filenameExt;
		var filename;
		var ext;
		var docArray = [];
		
		for(i=0; i<folderListJsonObj.length; i++) {
			itemObj = folderListJsonObj[i];
			filenameExt = itemObj.filename;
			[filename, ext] = this.getFilenameAndExtFromFilenameExt(filenameExt);
			if (ext === "txt") {
				docArray.push(filename);
			}
		}
		
		this.docArray = docArray;
	}
	
	async vectorServerGetFilenameQuestionList(modelID, filename) {

		var vectorBaseURLAPI = this.vectorBaseURLAPI;
		var url = vectorBaseURLAPI + "/getfilenamequestionlist?modelid=" + modelID + "&filename=" + encodeURIComponent(filename);
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		return jsonObj;
	}
	
	async serverDownloadTextFile(modelID, filename) {
		
		var baseURLAPI = this.baseURLAPI;
		var url = baseURLAPI + "/model/" + modelID + "/txt/" + filename + ".txt";
		
		var res = await fetch(url);
		var fileText = await res.text();

		return fileText;
	}

	async serverInsertDocumentRecord(modelID, filename) {

		var baseURLAPI = this.baseURLAPI;
		var url = baseURLAPI + "/createdocument?modelid=" + modelID;
		url = url + "&filename=" + encodeURIComponent(filename);
		
		const res = await fetch(url);
		var responseText = await res.text();
		var documentID = parseInt(responseText);
		
		return documentID;
	}

	async serverSetDocumentTextFile(modelID, documentFilename, txtFileText) {
		
		var baseURLAPI = this.baseURLAPI;
		var url = baseURLAPI + "/setdocumenttextfile?modelid=" + modelID + "&filename=" + encodeURIComponent(documentFilename);
		
		var formData = new FormData();
		formData.append("txtfiletext", txtFileText);

		const res = await fetch(url, {
			method: 'POST',
			body: formData,
		});		

		await res.text();
	}
	
	async vectorServerInsertQuestion(modelID, filename, questionText) {

		var vectorBaseURLAPI = this.vectorBaseURLAPI;
		var url = vectorBaseURLAPI + "/insertquestion?modelid=" + modelID;
		url = url + "&filename=" + encodeURIComponent(filename);
		url = url + "&question=" + encodeURIComponent(questionText);
		
		const res = await fetch(url);
		const jsonObj = await res.json();
	}
	
	async serverCopyOneDoc(modelID1, modelID2, filename) {
		
		var documentID = await this.serverInsertDocumentRecord(modelID2, filename);

		var fileText = await this.serverDownloadTextFile(modelID1, filename);
		await this.serverSetDocumentTextFile(modelID2, filename, fileText);

		var jsonObj = await this.vectorServerGetFilenameQuestionList(modelID1, filename);
		if (jsonObj === null) {
			return;
		}

		var i;
		var itemObj;
		var questionText;

		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			questionText = itemObj.questionText;
			await this.vectorServerInsertQuestion(modelID2, filename, questionText);
		}
	}
	
	async serverCopyAllDocs(modelID1, modelID2) {

		var progressMessageArray = this.progressMessageArray;
		var message;

		var i;
		var filename;
		
		var docArray = this.docArray;

		for(i=0; i<docArray.length; i++) {

			filename = docArray[i];

			await this.serverCopyOneDoc(modelID1, modelID2, filename);

			message = "Document copied: '" + filename + "'";
			progressMessageArray.push(message);		
			this.progressMessageArray = progressMessageArray;
			this.refresh();
		}

		progressMessageArray.push("-");
		this.progressMessageArray = progressMessageArray;
		this.refresh();
	}
	
	async serverGetPageList(modelID) {
		
		var baseURLAPI = this.baseURLAPI;
		var url = baseURLAPI + "/getpagelist?modelid=" + modelID;
		
		const res = await fetch(url);
		const pageListJsonObj = await res.json();
		
		this.pageListJsonObj = pageListJsonObj;
	}
	
	async serverCreatePageFolder(modelID, folder) {

		var baseURLAPI = this.baseURLAPI;
		var url = baseURLAPI + "/createpagefolder?modelid=" + modelID;
		url = url + "&folder=" + encodeURIComponent(folder);
		
		const res = await fetch(url);
		await res.text();
	}
	
	async serverCopyPageSubfolder(modelID1, modelID2, folder, subfolder) {

		var baseURLAPI = this.baseURLAPI;
		var url = baseURLAPI + "/copypagesubfolder?modelid1=" + modelID1;
		url = url + "&modelid2=" + modelID2;
		url = url + "&folder=" + encodeURIComponent(folder);
		url = url + "&subfolder=" + encodeURIComponent(subfolder);
		
		const res = await fetch(url);
		await res.text();
	}
		
	async serverCopyOnePageFolder(modelID1, modelID2, itemObj1) {

		var progressMessageArray = this.progressMessageArray;
		var message;

		var folderName = itemObj1.name;
		var pageArray = itemObj1.folders;

		await this.serverCreatePageFolder(modelID2, folderName);

		var i;
		var itemObj2;
		var subfolderName;

		for(i=0; i<pageArray.length; i++) {

			itemObj2 = pageArray[i];
			subfolderName = itemObj2.name;
			await this.serverCopyPageSubfolder(modelID1, modelID2, folderName, subfolderName);

			message = "Page copied: '" + folderName + "/" + subfolderName + "'";
			progressMessageArray.push(message);		
			this.progressMessageArray = progressMessageArray;
			this.refresh();
		}
	}

	async serverCopyAllPages(modelID1, modelID2) {

		var i;
		var itemObj;
		var pageListJsonObj = this.pageListJsonObj;
		
		for(i=0; i<pageListJsonObj.length; i++) {
			itemObj = pageListJsonObj[i];
			await this.serverCopyOnePageFolder(modelID1, modelID2, itemObj);
		}

		var progressMessageArray = this.progressMessageArray;
		progressMessageArray.push("-");
		this.progressMessageArray = progressMessageArray;
		this.refresh();
	}
	
	async serverCopyModelCSSJS(modelID1, modelID2) {

		var baseURLAPI = this.baseURLAPI;
		var url = baseURLAPI + "/copymodelcssjs?modelid1=" + modelID1;
		url = url + "&modelid2=" + modelID2;
		
		const res = await fetch(url);
		await res.text();

		var message = "";
		var progressMessageArray = this.progressMessageArray;

		message = "js, css and img copied";
		progressMessageArray.push(message);		
		this.progressMessageArray = progressMessageArray;
		this.refresh();
	}

}
