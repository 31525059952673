import React from 'react';
import './App.css';
import './Navbar.css';
import { AiOutlineLogout, AiOutlineFileImage } from "react-icons/ai"
import { BsCardList, BsChatRightDots, BsQuestionCircle, BsDatabaseCheck } from "react-icons/bs"
import { FaInfo } from "react-icons/fa"
import { RiQuestionAnswerLine } from "react-icons/ri"
import { MdOutlineChangeHistory, MdOutlineSchool } from "react-icons/md"

import AppLang from './AppLang.json';

import { useNavigate } from "react-router-dom";

class Navbar extends React.Component {

	constructor(props) {

		super(props);

		this.handleClick = this.handleClick.bind(this);		
	}
	
	componentDidMount() {
	}

	componentWillUnmount() {
	};
	
	handleClick(page) {

		const navigate = this.props.navigate;

		if (page === "/logout") {
			this.props.logoutClearVariables();
			navigate("/login");
		} else {
			navigate(page);
		}

		this.props.closeNavbar();
	}
	
	render() {
		
		var appUserType = this.props.appUserType;
		var showDocsYN = false;
		var showPagesYN = false;
		var showQuestionLogYN = false;
		var showChangeLogYN = false;
		var showModelsYN = false;
		var showSampleQuestionsYN = false;
		var showUserLessonLogYN = false;
		var showQuestionLogAllYN = false;
		if (appUserType >= 100) {
			showDocsYN = true;
			showPagesYN = true;
			showQuestionLogYN = true;
			showChangeLogYN = true;
			showModelsYN = true;
			showSampleQuestionsYN = true;
			showQuestionLogAllYN = true;
		}

		var modelUserType = this.props.modelUserType;
		if (modelUserType > 0) {
			showDocsYN = true;
			showPagesYN = true;
			showQuestionLogYN = true;
			showSampleQuestionsYN = true;
		}
		
		var modelLearnYN = this.props.modelLearnYN;
		if (modelLearnYN) {
			showUserLessonLogYN = true;
		}

		var navbarOpenYN = this.props.navbarOpenYN;
		if (!navbarOpenYN) {
			return (<div></div>);
		}
		
		return (
			<div id="navbar" className="Navbar">

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/chat")}
				>
					<div className="NavbarLinkColumn1">
						<BsChatRightDots style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "chat")}</div>				  					  
				</div>

				{showSampleQuestionsYN && (<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/samplequestions")}
				>
					<div className="NavbarLinkColumn1">
						<BsQuestionCircle style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "sampleQuestions")}</div>				  					  
				</div>)}

				{showDocsYN && (<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/docs")}
				>
					<div className="NavbarLinkColumn1">
						<BsCardList style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "docs")}</div>
				</div>)}

				{showPagesYN && (<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/pages")}
				>
					<div className="NavbarLinkColumn1">
						<AiOutlineFileImage style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "pages")}</div>
				</div>)}

				{showQuestionLogYN && (<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/questionlog")}
				>
					<div className="NavbarLinkColumn1">
						<RiQuestionAnswerLine style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "questionLog")}</div>
				</div>)}

				{showChangeLogYN && (<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/changelog")}
				>
					<div className="NavbarLinkColumn1">
						<MdOutlineChangeHistory style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "changeLog")}</div>
				</div>)}
				
				{showUserLessonLogYN && (<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/userlessonlog")}
				>
					<div className="NavbarLinkColumn1">
						<MdOutlineSchool style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "userLessonLog")}</div>
				</div>)}

				{showModelsYN && (<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/models")}
				>
					<div className="NavbarLinkColumn1">
						<BsDatabaseCheck style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "models")}</div>
				</div>)}

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/selectmodel")}
				>
					<div className="NavbarLinkColumn1">
						<BsDatabaseCheck style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "selectModel")}</div>
				</div>

				{showQuestionLogAllYN && (<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/questionlogall")}
				>
					<div className="NavbarLinkColumn1">
						<RiQuestionAnswerLine style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "questionLogAll")}</div>
				</div>)}

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/about")}
				>
					<div className="NavbarLinkColumn1">
						<FaInfo style={{ color: "white", width: "25px", height: "20px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(AppLang, "about")}</div>				  					  
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/logout")}
				>
					<div className="NavbarLinkColumn1">
						<AiOutlineLogout style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">Logout</div>
				</div>

			</div>
		);
	}
}

export default function NavbarFunction(props) {
	const navigate = useNavigate();
	return <Navbar {...props} navigate={navigate} />;
}

