import React  from 'react';
import './App.css';
import './Page.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { AiOutlineEdit, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai"

import PageListToolbar from './PageListToolbar.js';

import { useNavigate } from "react-router-dom";

class PageList extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			pageListJsonObj: null,
		};
		
		this.serverGetPageList = this.serverGetPageList.bind(this);
		this.serverDeletePageFolder = this.serverDeletePageFolder.bind(this);
		this.handleEditFolder = this.handleEditFolder.bind(this);
		this.handleAddPage = this.handleAddPage.bind(this);		
		this.getPageCountInFolder = this.getPageCountInFolder.bind(this);
		this.handleDeleteFolder = this.handleDeleteFolder.bind(this);
		this.handleSelectPage = this.handleSelectPage.bind(this);
		this.renderOneSubfolder = this.renderOneSubfolder.bind(this);
		this.renderAllSubfolders = this.renderAllSubfolders.bind(this);
		this.renderOneFolder = this.renderOneFolder.bind(this);
		this.renderAllFolders = this.renderAllFolders.bind(this);
	}
	
	componentDidMount() {
		this.serverGetPageList();
	}
	
	async serverGetPageList() {
		
		var modelID = this.props.modelID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getpagelist?modelid=" + modelID;
		
		const res = await fetch(url);
		const pageListJsonObj = await res.json();
		
		this.setState({
			pageListJsonObj: pageListJsonObj,
		});
	}
	
	async serverDeletePageFolder(modelID, pageFolder) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/deletepagefolder?modelid=" + modelID;
		url = url + "&folder=" + encodeURIComponent(pageFolder);

		const res = await fetch(url);
		await res.text();
		
		this.props.showToast("Folder deleted");
		this.serverGetPageList();

		var title;
		var changeJsonObj;
		var changeJSON;

		title = "Delete folder: " + pageFolder;
		changeJsonObj = {};
		changeJsonObj.section = "pages";
		changeJsonObj.operation = "deletefolder";
		changeJsonObj.pageFolder = pageFolder;
		var changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);
	}
	
	handleEditFolder(pageFolder) {
		this.props.setSelectedPageFolder(pageFolder);
		const navigate = this.props.navigate;
  		navigate("/pages/renamefolder");
	}

	handleAddPage(pageFolder) {
		this.props.setSelectedPageFolder(pageFolder);
		const navigate = this.props.navigate;
  		navigate("/pages/newsubfolder");
	}
	
	// niksa
	getPageCountInFolder(pageFolder) {

		var pageListJsonObj = this.state.pageListJsonObj;
		var length = pageListJsonObj.length;
		
		var i;
		var itemObj;
		var folderName;
		var folderArray;
		
		for(i=0; i<length; i++) {
			itemObj = pageListJsonObj[i];
			folderName = itemObj.name;
			folderArray = itemObj.folders;
			if (folderName === pageFolder) {
				return folderArray.length;
			}
		}

		return -1;
	}
	
	handleDeleteFolder(pageFolder) {
		
		var count = this.getPageCountInFolder(pageFolder);
		if (count !== 0) {
			this.props.showToast("Cannot delete folder with pages. Please delete pages first.");
			return;
		}
		
		var modelID = this.props.modelID;

		confirmAlert({
			title: 'Delete folder',
			message: 'Are you sure you want to delete this folder?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => this.serverDeletePageFolder(modelID, pageFolder)
				},
				{
					label: 'No',
				}
			]
		});
	}
	
	handleSelectPage(pageFolder, pageSubfolder) {
		this.props.setSelectedPageFolder(pageFolder);
		this.props.setSelectedPageSubfolder(pageSubfolder);
		const navigate = this.props.navigate;
  		navigate("/page");
	}

	renderOneSubfolder(index, folderName, itemObj) {

		var name = itemObj.name;
		var key = folderName + "-" + index;

		return (
			<div key={key} className="PageListSubfolder" onClick={() => this.handleSelectPage(folderName, name)}>
				{name}
			</div>
		);
	}
	
	renderAllSubfolders(folderName, folderArray) {

		var length = folderArray.length;
		if (length === 0) {
			return;
		}
		
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<length; i++) {
			itemObj = folderArray[i];
			jsxCode = this.renderOneSubfolder(i, folderName, itemObj);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}

	renderOneFolder(index, itemObj) {

		var folderName = itemObj.name;
		var folderArray = itemObj.folders;

		return (
			<div key={index}>
				<div className="PageListFolderContainer">
					<div className="PageListFolder">
						{folderName}
					</div>
					<div
					  className="PageListIcon"
					  onClick={() => this.handleEditFolder(folderName)}
					>
						<AiOutlineEdit style={{ color: "blue", width: "15px", height: "15px" }} />
					</div>
					<div
					  className="PageListIcon"
					  onClick={() => this.handleDeleteFolder(folderName)}
					>
						<AiOutlineDelete style={{ color: "blue", width: "15px", height: "15px" }} />
					</div>
					<div
					  className="PageListIcon"
					  onClick={() => this.handleAddPage(folderName)}
					>
						<AiOutlinePlus style={{ color: "blue", width: "15px", height: "15px" }} />
					</div>
				</div>
				{this.renderAllSubfolders(folderName, folderArray)}
			</div>
		);
	}

	renderAllFolders() {

		var pageListJsonObj = this.state.pageListJsonObj;
		if (pageListJsonObj === null) {
			return;
		}
		
		var length = pageListJsonObj.length;
		if (length === 0) {
			return (
				<div className="PageInfoText">
					No pages found.
				</div>
			);
		}
		
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<length; i++) {
			itemObj = pageListJsonObj[i];
			jsxCode = this.renderOneFolder(i, itemObj);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}
	
	render() {
		return (
			<div>
				<PageListToolbar 
					modelName={this.props.modelName}
					chatAppUserType={this.props.chatAppUserType}
				/>
				<div style={{height: "10px"}}></div>
				<div className="PageListTopContainer">
					{this.renderAllFolders()}
				</div>
			</div>
		);
	}
}

export default function PageListFunction(props) {
	const navigate = useNavigate();
	return <PageList {...props} navigate={navigate} />;
}


