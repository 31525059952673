import React  from 'react';
import './App.css';
import './Model.css';

import { useNavigate } from "react-router-dom";

class ModelNew extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			modelName: "",
			helloMessage: "",
			systemMessage: "",
			llmModel: "llama2-7b",
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onModelNameChanged = this.onModelNameChanged.bind(this);
		this.onHelloMessageChanged = this.onHelloMessageChanged.bind(this);
		this.onSystemMessageChanged = this.onSystemMessageChanged.bind(this);
		this.serverInsertModelRecord = this.serverInsertModelRecord.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	onModelNameChanged(value) {
		this.setState({modelName: value});
	}
	
	onHelloMessageChanged(value) {
		this.setState({helloMessage: value});
	}
	
	onSystemMessageChanged(value) {
		this.setState({systemMessage: value});
	}
	
	async serverInsertModelRecord(modelName, helloMessage, systemMessage, llmModel) {
		
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/insertmodelrecord?modelname=" + encodeURIComponent(modelName);
		url = url + "&hellomessage=" + encodeURIComponent(helloMessage);
		url = url + "&systemmessage=" + encodeURIComponent(systemMessage);
		url = url + "&llmmodel=" + encodeURIComponent(llmModel);
		
		const res = await fetch(url);
		await res.text();

		this.props.showToast("New model created");
		const navigate = this.props.navigate;
  		navigate("/models");
	}
		
	onClickSaveButton() {
		
		var modelName = this.state.modelName;
		modelName = modelName.trim();
		if (modelName === "") {
			this.props.showToast("Model name cannot be empty");
			return;
		}

		var helloMessage = this.state.helloMessage;
		var systemMessage = this.state.systemMessage;
		var llmModel = this.state.llmModel;
		this.serverInsertModelRecord(modelName, helloMessage, systemMessage, llmModel);

		var title;
		var changeJsonObj;
		var changeJSON;

		title = "New model: " + modelName;
		changeJsonObj = {};
		changeJsonObj.section = "model";
		changeJsonObj.operation = "new";
		changeJsonObj.modelName = modelName;
		var changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/models");
	}
	
	render() {		

		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">New model</div>
				<div className="ViewTopText">Enter model data and press Create Model button</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">Model info</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelNewFieldset">
							<legend className="ModelNewLegend">Model name</legend> 
							<input
								className="ModelNewInput" 
								id="modelName" 
								type="text" 
								autoFocus={true}
								onChange={(event) => this.onModelNameChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>

					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelNewFieldset">
							<legend className="ModelNewLegend">Hello message</legend> 
							<textarea
								className="ModelNewInput" 
								id="helloMessage" 
								type="text" 
								rows={5}
								onChange={(event) => this.onHelloMessageChanged(event.target.value)}
							/>
						</fieldset>
					</div>

					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelNewFieldset">
							<legend className="ModelNewLegend">System message</legend> 
							<input
								className="ModelNewInput" 
								id="systemMessage" 
								type="text" 
								onChange={(event) => this.onSystemMessageChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>

					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>Cancel</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>Create Model</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function ModelNewFunction(props) {
	const navigate = useNavigate();
	return <ModelNew {...props} navigate={navigate} />;
}

