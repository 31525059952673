import React  from 'react';
import './App.css';
import './QuestionSample.css';
import { AiOutlineEdit } from "react-icons/ai"

import QuestionSampleListToolbar from './QuestionSampleListToolbar.js';

import { useNavigate } from "react-router-dom";

class QuestionSampleList extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			questionSampleCategoryListJsonObj: null,
			questionSampleListJsonObj: null,
		};

		this.serverGetQuestionSampleCategoryList = this.serverGetQuestionSampleCategoryList.bind(this);
		this.serverGetQuestionSampleList = this.serverGetQuestionSampleList.bind(this);
		this.handleSelectCategory = this.handleSelectCategory.bind(this);				
		this.handleSelectQuestion = this.handleSelectQuestion.bind(this);				
		this.handleEditQuestion = this.handleEditQuestion.bind(this);				
		this.renderOneQuestion = this.renderOneQuestion.bind(this);				
		this.renderAllQuestions = this.renderAllQuestions.bind(this);				
		this.renderOneCategory = this.renderOneCategory.bind(this);				
		this.renderAllCategories = this.renderAllCategories.bind(this);				
	}
	
	componentDidMount() {
		this.serverGetQuestionSampleCategoryList();
	}

	async serverGetQuestionSampleCategoryList() {

		var modelID = this.props.modelID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getquestionsamplecategorylist?modelid=" + modelID;
		
		const res = await fetch(url);
		const questionSampleCategoryListJsonObj = await res.json();
		
		var questionSampleCategoryID = this.props.questionSampleCategoryID;
		if (questionSampleCategoryID === 0) {
			if (questionSampleCategoryListJsonObj.length > 0) {
				var itemObj = questionSampleCategoryListJsonObj[0];
				questionSampleCategoryID = itemObj.questionSampleCategoryID;
			}
			this.props.setSelectedQuestionSampleCategoryID(questionSampleCategoryID);
		}
				
		this.setState({
			questionSampleCategoryListJsonObj: questionSampleCategoryListJsonObj,
		});

		if (questionSampleCategoryID > 0) {
			this.serverGetQuestionSampleList(questionSampleCategoryID);
		}
	}
		
	async serverGetQuestionSampleList(questionSampleCategoryID) {

		var modelID = this.props.modelID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getquestionsamplelist?questionsamplecategoryid=" + questionSampleCategoryID;
		
		const res = await fetch(url);
		const questionSampleListJsonObj = await res.json();
		
		this.setState({
			questionSampleListJsonObj: questionSampleListJsonObj,
		});
	}
	
	handleSelectCategory(questionSampleCategoryID) {
		this.props.setSelectedQuestionSampleCategoryID(questionSampleCategoryID);
		if (questionSampleCategoryID > 0) {
			this.serverGetQuestionSampleList(questionSampleCategoryID);
		}
	}

	handleSelectQuestion(questionSampleID, questionText) {
		return;
		this.props.setSelectedQuestionSampleID(questionSampleID);
		this.props.setSelectedQuestionText(questionText);
		const navigate = this.props.navigate;
  		navigate("/chat");
	}

	handleEditQuestion(questionSampleID) {
		this.props.setSelectedQuestionSampleID(questionSampleID);
		const navigate = this.props.navigate;
  		navigate("/samplequestions/edit");
	}

	renderOneQuestion(itemObj) {

		var questionSampleID = itemObj.questionSampleID;
		var questionText = itemObj.questionText;
		
		var chatAppUserType = this.props.chatAppUserType;
		var showEditYN = false;
		if (chatAppUserType >= 100) {
			showEditYN = true;
		}

		var modelUserType = this.props.modelUserType;
		if (modelUserType >= 100) {
			showEditYN = true;
		}

		return (
			<div key={questionSampleID} className="QuestionSampleListQuestionContainer"> 
				<div className="QuestionSampleListQuestionText" onClick={() => this.handleSelectQuestion(questionSampleID, questionText)}>
					{questionText}
				</div>
				{showEditYN && (
					<div 
						className="QuestionSampleListEditIcon"
						onClick={() => this.handleEditQuestion(questionSampleID)}
					>
						<AiOutlineEdit style={{ color: "black", width: "20px", height: "20px" }} />
					</div>
				)}
			</div>
		);
	}

	renderAllQuestions() {

		var questionSampleListJsonObj = this.state.questionSampleListJsonObj;
		if (questionSampleListJsonObj === null) {
			return;
		}

		var length = questionSampleListJsonObj.length;
		if (length === 0) {
			return (
				<div className="QuestionSampleListQuestionText">
					No sample questions found.
				</div>
			);
		}
		
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<length; i++) {
			itemObj = questionSampleListJsonObj[i];
			jsxCode = this.renderOneQuestion(itemObj);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}

	renderOneCategory(itemObj) {

		var selectedQuestionSampleCategoryID = this.props.questionSampleCategoryID;
		var questionSampleCategoryID = itemObj.questionSampleCategoryID;
		var categoryTitle = itemObj.categoryTitle;
		var key = "category-" + questionSampleCategoryID;
		
		var className = "QuestionSampleTitleSpan";
		if (selectedQuestionSampleCategoryID === questionSampleCategoryID) {
			var className = "QuestionSampleTitleSpanSelected";
		}
		
		return (
			<span key={key} className={className} onClick={() => this.handleSelectCategory(questionSampleCategoryID)}>
				{categoryTitle}
			</span>
		);
	}

	renderAllCategories() {

		var questionSampleCategoryListJsonObj = this.state.questionSampleCategoryListJsonObj;
		if (questionSampleCategoryListJsonObj === null) {
			return;
		}

		var length = questionSampleCategoryListJsonObj.length;
		if (length === 0) {
			return;
		}
		
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<length; i++) {
			itemObj = questionSampleCategoryListJsonObj[i];
			jsxCode = this.renderOneCategory(itemObj);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}

	render() {
		return (
			<div>
				<QuestionSampleListToolbar 
					modelName={this.props.modelName}
					chatAppUserType={this.props.chatAppUserType}
					modelUserType={this.props.modelUserType}
				/>
				<div className="QuestionSampleListTopContainer">
					<div style={{height: "20px"}}></div>
					{this.renderAllCategories()}
					<div style={{height: "20px"}}></div>
					{this.renderAllQuestions()}
				</div>
			</div>
		);
	}
}

export default function QuestionSampleListFunction(props) {
	const navigate = useNavigate();
	return <QuestionSampleList {...props} navigate={navigate} />;
}

