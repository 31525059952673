import * as React from 'react';
import './App.css';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import moment from 'moment';
import {storeEmailPassword} from './LoginLocalStorage.js';
//import AppLang from './AppLang.json';
import {ModelCopyHelpers} from './ModelCopyHelpers.js';

import Topbar from './common/Topbar.js';
import Login from './Login.js';
import Chat from './Chat.js';
import QuestionSampleList from './QuestionSampleList.js';
import QuestionSampleNew from './QuestionSampleNew.js';
import QuestionSampleEdit from './QuestionSampleEdit.js';
import QuestionSampleReorder from './QuestionSampleReorder.js';
import QuestionSampleCategoryNew from './QuestionSampleCategoryNew.js';
import QuestionSampleCategoryEdit from './QuestionSampleCategoryEdit.js';
import QuestionSampleCategoryReorder from './QuestionSampleCategoryReorder.js';
import QuestionLogList from './QuestionLogList.js';
import QuestionLogView from './QuestionLogView.js';
import QuestionLogAllList from './QuestionLogAllList.js';
import DocumentList from './DocumentList.js';
import DocumentView from './DocumentView.js';
import DocumentNew from './DocumentNew.js';
import DocumentEditText from './DocumentEditText.js';
import DocumentEditQuestions from './DocumentEditQuestions.js';
import PageList from './PageList.js';
import PageNewFolder from './PageNewFolder.js';
import PageRenameFolder from './PageRenameFolder.js';
import PageNewSubfolder from './PageNewSubfolder.js';
import PageMain from './PageMain.js';
import PageEditImage from './PageEditImage.js';
import PageEditText from './PageEditText.js';
import PageEditRichText from './PageEditRichText.js';
import PageEditHtml from './PageEditHtml.js';
import PageEditLink from './PageEditLink.js';
import PageEditSingleChoice from './PageEditSingleChoice.js';
import PageEditPronounce from './PageEditPronounce.js';
import ChangeLogList from './ChangeLogList.js';
import UserLessonLogList from './UserLessonLogList.js';
import UserLessonLogView from './UserLessonLogView.js';
import ModelList from './ModelList.js';
import ModelNew from './ModelNew.js';
import ModelNewFromWebSite from './ModelNewFromWebSite.js';
import ModelNewFromWebSiteJobList from './ModelNewFromWebSiteJobList.js';
import ModelNewFromWebSiteJobView from './ModelNewFromWebSiteJobView.js';
import ModelCopy from './ModelCopy.js';
import ModelEdit from './ModelEdit.js';
import SelectModel from './SelectModel.js';
import About from './About.js';

class App extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			baseURLAPI: "https://aiapi.plurato.com",
			managementBaseURLAPI: "https://managementapi.plurato.com",
			storageBaseURLAPI: "",
			vectorBaseURLAPI: "",
			mlServerIPAddress: "",
			mlBaseURLAPI: "",
			ping: -1,
			modelID: 0,
			modelName: "",
			modelLearnYN: false,
			modelUserType: 0,
			helloMessage: "",
			dbtouch: 0,
			sessionID: "",
			userID: 0,
			email: "",
			firstName: "",
			lastName: "",
			fullName: "",
			phone: "",
			language: "en",
			chatAppUserType: 0,
			documentFilename: "",
			pageFolder: "",
			pageSubfolder: "",
			pageFilenameExt: "",
			questionArray: [],
			answerArray: [],
			filesArray: [],
			videoArray: [],
			questionText: "",
			questionSampleCategoryID: 0,
			questionSampleID: 0,
			questionLogID: 0,
			questionLogListIndex: 0,
			changeLogListIndex: 0,
			studentID: 0,
			studentFullName: "",
			firstLoadYN: true,
			redirectCheckedYN: false,
			navbarOpenYN: false,
			modelCopyHelpersObj: null,
			modelFromWebSiteJobListIndex: 0,
			modelFromWebSiteID: 0,
		}
		
		this.handleMouseClick = this.handleMouseClick.bind(this);
		this.getFilenameAndExtFromFilenameExt = this.getFilenameAndExtFromFilenameExt.bind(this);
		this.serverGetBaseURLList = this.serverGetBaseURLList.bind(this);
		this.serverGetUserDataFromSessionID = this.serverGetUserDataFromSessionID.bind(this);
		this.serverGetModelDataFromUrlShortcut = this.serverGetModelDataFromUrlShortcut.bind(this);
		this.checkModelRedirect = this.checkModelRedirect.bind(this);
		this.getSessionIDFromURLString = this.getSessionIDFromURLString.bind(this);
		this.serverUpdateUserLanguage = this.serverUpdateUserLanguage.bind(this);
		this.showToast = this.showToast.bind(this);
		this.logoutClearVariables = this.logoutClearVariables.bind(this);
		this.serverLogout = this.serverLogout.bind(this);
		this.setCompanyName = this.setCompanyName.bind(this);
		this.serverGetUserData = this.serverGetUserData.bind(this);
		this.serverDBTouchGetUserData = this.serverDBTouchGetUserData.bind(this);
		this.serverGetUserDBTouchData = this.serverGetUserDBTouchData.bind(this);
		this.serverGetMLServerIPAddress = this.serverGetMLServerIPAddress.bind(this);
		this.mlServerPing = this.mlServerPing.bind(this);
		this.serverGetModelData = this.serverGetModelData.bind(this);
		this.serverGetUserSettingsData = this.serverGetUserSettingsData.bind(this);
		this.serverGetUserModelData = this.serverGetUserModelData.bind(this);
		this.serverSetUserModelID = this.serverSetUserModelID.bind(this);
		this.serverInsertChangeLogRecord = this.serverInsertChangeLogRecord.bind(this);
		this.setSessionID = this.setSessionID.bind(this);
		this.setUserID = this.setUserID.bind(this);
		this.setLanguage = this.setLanguage.bind(this);
		this.setQuestionArray = this.setQuestionArray.bind(this);
		this.setAnswerArray = this.setAnswerArray.bind(this);
		this.setFilesArray = this.setFilesArray.bind(this)
		this.setVideoArray = this.setVideoArray.bind(this)
		this.setSelectedDocument = this.setSelectedDocument.bind(this);
		this.setSelectedPageFolder = this.setSelectedPageFolder.bind(this);
		this.setSelectedPageSubfolder = this.setSelectedPageSubfolder.bind(this);
		this.setSelectedPageFilenameExt = this.setSelectedPageFilenameExt.bind(this);
		this.setSelectedQuestionText = this.setSelectedQuestionText.bind(this);
		this.setSelectedStudent = this.setSelectedStudent.bind(this);
		this.setSelectedModel = this.setSelectedModel.bind(this);
		this.setSelectedQuestionSampleID = this.setSelectedQuestionSampleID.bind(this);
		this.setSelectedQuestionSampleCategoryID = this.setSelectedQuestionSampleCategoryID.bind(this);
		this.setSelectedQuestionLogID = this.setSelectedQuestionLogID.bind(this);
		this.setSelectedModelFromWebSiteID = this.setSelectedModelFromWebSiteID.bind(this);
		this.setQuestionLogListIndex = this.setQuestionLogListIndex.bind(this);
		this.setModelFromWebSiteJobListIndex = this.setModelFromWebSiteJobListIndex.bind(this);
		this.setChangeLogListIndex = this.setChangeLogListIndex.bind(this);
		this.setNavbarOpenYN = this.setNavbarOpenYN.bind(this);
		this.getLang = this.getLang.bind(this);
		this.separateTextIntoArrayOfLines = this.separateTextIntoArrayOfLines.bind(this);
		this.convertTextNewlineToBr = this.convertTextNewlineToBr.bind(this);
		this.getDateStringFromUnixTimeSec = this.getDateStringFromUnixTimeSec.bind(this);
		this.getTimeStringFromUnixTimeSec = this.getTimeStringFromUnixTimeSec.bind(this);
		this.getDateTimeStringFromUnixTimeSec = this.getDateTimeStringFromUnixTimeSec.bind(this);
		this.getTimeStringFromSeconds = this.getTimeStringFromSeconds.bind(this);
		this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
	}
	
	componentDidMount() {

		document.title = "AI Chatbot"

		this.serverGetBaseURLList();
		//this.serverGetMLServerIPAddress();

		var windowLocation = window.location;
		var windowLocationString = windowLocation.toString();
		this.checkModelRedirect(windowLocationString);
		var sessionID = this.getSessionIDFromURLString(windowLocationString);
		
		if (sessionID === "") {
			this.setState({firstLoadYN: false});
			return;
		}
		
		this.setState({
			sessionID: sessionID,
		});
		this.serverGetUserDataFromSessionID(sessionID);
	}

	componentWillUnmount() {
	};
	
	handleMouseClick(e) {

		var target = e.target;
		var id = target.id;
		
		if (id === "fimenu") {
			return;
		}
		
		if (id === "") {
			var parentNode = target.parentNode;
			if (parentNode !== null) {
				id = parentNode.id;
				if (id === "fimenu") {
					return;
				}
				if (id === "") {
					parentNode = parentNode.parentNode;
					if (parentNode !== null) {
						id = parentNode.id;
						if (id === "fimenu") {
							return;
						}
					}
				}
			}
		}

		this.setNavbarOpenYN(false);		
	}

	getFilenameAndExtFromFilenameExt(filenameExt) {
		var length = filenameExt.length;
		var ext = filenameExt.split('.').pop();
		var filename = filenameExt.substring(0, length - ext.length - 1);
		ext = ext.toLowerCase();
		return [filename, ext];
	}

	async serverGetBaseURLList() {
		
		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/getbaseurllist";
		
		const res = await fetch(url);
		const jsonObj = await res.json();
		var storageBaseURLAPI = jsonObj.storageBaseURLAPI;
		var vectorBaseURLAPI = jsonObj.vectorBaseURLAPI;

		this.setState({
			storageBaseURLAPI: storageBaseURLAPI,
			vectorBaseURLAPI: vectorBaseURLAPI,
		});

		var modelCopyHelpersObj = new ModelCopyHelpers();
		modelCopyHelpersObj.baseURLAPI = this.state.baseURLAPI;
		modelCopyHelpersObj.vectorBaseURLAPI = this.state.vectorBaseURLAPI;
		this.setState({
			modelCopyHelpersObj: modelCopyHelpersObj
		});
	}
	
	async serverGetUserDataFromSessionID(sessionID) {
		
		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getuserdatafromsessionid?sid=" + sessionID;

		try {
			const res = await fetch(url);
			const jsonObj = await res.json();

			var userID = jsonObj.userID;
			var email = jsonObj.email;
			var firstName = jsonObj.firstName;
			var lastName = jsonObj.lastName;
			var phone = jsonObj.phone;
			var language = jsonObj.language;
			var chatAppUserType = jsonObj.chatAppUserType;
			var companyID = jsonObj.companyID;
			var createdTimestamp = jsonObj.createdTimestamp;

			var fullName = firstName + " " + lastName;
			fullName = fullName.trim();
			if (fullName === "") {
				fullName = email;
			}

			this.setState({
				userID: userID,
				email: email,
				firstName: firstName,
				lastName: lastName,
				fullName: fullName,
				phone: phone,
				language: language,
				chatAppUserType: chatAppUserType,
				companyID: companyID,
				createdTimestamp: createdTimestamp,
				firstLoadYN: false,
			});
		
			this.setCompanyName(companyID);
			this.serverGetUserSettingsData(userID);			
		} catch (e) {
			this.setState({firstLoadYN: false});
		}
	}
	
	async serverGetModelDataFromUrlShortcut(urlShortcut) {
		
		if (urlShortcut === "") {
			return 0;
		}
		
		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/getmodeldatafromurlshortcut?urlshortcut=" + encodeURIComponent(urlShortcut);
		var modelID = 0;

		try {
			const res = await fetch(url);
			const jsonObj = await res.json();
			modelID = jsonObj.modelID;
		} catch (e) {
			modelID = 0;
		}
		
		return modelID;
	}
	
	async checkModelRedirect(urlString) {
		
		var i;
		var foundYN = false;
		var length = urlString.length;
		var index = -1;
		var ch;
		
		// find last /
		for(i=length-1; (i>=0) && (!foundYN); i--) {
			ch = urlString.charAt(i);
			if (ch === "/") {
				foundYN = true;
				index = i+1;
			}
		}
		
		if (!foundYN) {
			this.setState({redirectCheckedYN: true});
			return;
		}
		
		var urlShortcut = urlString.substring(index, length);
		urlShortcut = urlShortcut.toLowerCase();

		var modelID = await this.serverGetModelDataFromUrlShortcut(urlShortcut);
		if (modelID === 0) {
			this.setState({redirectCheckedYN: true});
			return;
		}

		var url = "https://aiapi.plurato.com/chat/index.html?model=" + modelID;
		window.location.href = url;

		/*var i;
		var foundYN = false;
		var length = urlString.length;
		var index = -1;
		var ch;
		
		// find last /
		for(i=length-1; (i>=0) && (!foundYN); i--) {
			ch = urlString.charAt(i);
			if (ch === "/") {
				foundYN = true;
				index = i+1;
			}
		}
		
		if (!foundYN) {
			this.setState({redirectCheckedYN: true});
			return;
		}
		
		var modelName = urlString.substring(index, length);
		modelName = modelName.toLowerCase();
		
		var modelID = 0;
		if (modelName === "tesla") {
			modelID = 1;
		}
		if (modelName === "cornaro") {
			modelID = 2;
		}
		if (modelName === "plurato") {
			modelID = 3;
		}
		if (modelName === "manas") {
			modelID = 4;
		}
		if (modelName === "split") {
			modelID = 8;
		}
		if (modelName === "valamar") {
			modelID = 13;
		}
		if (modelName === "akcija") {
			modelID = 14;
		}
		if (modelName === "explain") {
			modelID = 15;
		}
		
		if (modelID > 0) {
			var url = "https://aiapi.plurato.com/chat/index.html?model=" + modelID;
			window.location.href = url;
		} else {
			this.setState({redirectCheckedYN: true});
		}*/
	}

	getSessionIDFromURLString(urlString) {

		var i;
		var index;
		var length;
		var foundYN;
		var sessionID = "";
		
		length = urlString.length;
		foundYN = false;
		index = 0;
		
		// find sid=
		for(i=0; (i<length) && (!foundYN); i++) {
			if (urlString.substr(i, 4) === "sid=") {
				foundYN = true;
				index = i+4;
			}
		}
		
		if (!foundYN) {
			return "";
		}
		
		sessionID = urlString.substr(index, length-index);
		return sessionID;
	}
	
	async serverUpdateUserLanguage(userID, language) {

		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var dbtouch = this.state.dbtouch;
		var email = this.state.email; 
		var url;

		if (dbtouch) {
			url = managementBaseURLAPI + "/updateuserdbtouchlanguage?email=" + encodeURIComponent(email) + "&language=" + language;
		} else {
			url = managementBaseURLAPI + "/updateuserlanguage?userid=" + userID + "&language=" + language;
		}
		
		const res = await fetch(url);
		await res.text();
	}

	showToast(messageText) {
		toast(messageText, {
			position: "top-center",
			autoClose: 2500,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
	}
	
	logoutClearVariables() {

		var sessionID = this.state.sessionID;
		this.serverLogout(sessionID);

		this.setState({
			sessionID: "",
			userID: 0,
			email: "",
			firstName: "",
			lastName: "",
			fullName: "",
			phone: "",
			chatAppUserType: 0,
			companyID: 0,
			createdTimestamp: "",
		});
		
		storeEmailPassword("", "");
	}

	async serverLogout(sessionID) {
		
		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/logout?sid=" + sessionID;

		const res = await fetch(url);
		await res.text();
	}

	async serverGetUserData(userID) {

		if (userID <= 0) {
			return;
		}

		var sessionID = this.state.sessionID;
		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getuserdata?sid=" + sessionID + "&userid=" + userID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var email = jsonObj.email;
		var firstName = jsonObj.firstName;
		var lastName = jsonObj.lastName;
		var phone = jsonObj.phone;
		var language = jsonObj.language;
		var chatAppUserType = jsonObj.chatAppUserType;
		var companyID = jsonObj.companyID;
		var createdTimestamp = jsonObj.createdTimestamp;

		var fullName = firstName + " " + lastName;
		fullName = fullName.trim();
		if (fullName === "") {
			fullName = email;
		}

		this.setState({
			email: email,
			firstName: firstName,
			lastName: lastName,
			fullName: fullName,
			phone: phone,
			language: language,
			chatAppUserType: chatAppUserType,
			companyID: companyID,
			createdTimestamp: createdTimestamp,
		});
		
		this.setCompanyName(companyID);
		this.serverGetUserSettingsData(userID);
	}
	
	async serverDBTouchGetUserData(userID) {

		if (userID <= 0) {
			return;
		}

		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/dbtouchGetUserData?userid=" + userID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		var isActive = jsonObj.isActive;
		if (!isActive) {
			this.logoutClearVariables();
			return;
		}

		var email = jsonObj.username;
		var firstName = jsonObj.firstName;
		var lastName = jsonObj.lastName;
		var companyID = jsonObj.companyId;
		
		var fullName = firstName + " " + lastName;
		fullName = fullName.trim();
		if (fullName === "") {
			fullName = email;
		}

		this.setState({
			dbtouch: 1,
			email: email,
			firstName: firstName,
			lastName: lastName,
			fullName: fullName,
			companyID: companyID,
		});
		
		this.serverGetUserDBTouchData(email);
		//this.serverDBTouchSetCompanyName(companyID);
		this.serverGetUserSettingsData(userID);
	}
	
	async serverGetUserDBTouchData(email) {

		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getuserdbtouchdata?email=" + encodeURIComponent(email);

		const res = await fetch(url);
		var responseText = await res.text();
		if (responseText === "") {
			return;
		}
		
		var jsonObj = JSON.parse(responseText);

		var language = jsonObj.language;
		var chatAppUserType = jsonObj.chatAppUserType;
		
		if (chatAppUserType < 1) {
			storeEmailPassword("", "");
			this.showToast("You do not have permission to use this app.");
			setTimeout(function () {
				window.location.href = "/login";
			}, 1000);
			return;
		}
		
		this.setState({
			language: language,
			chatAppUserType: chatAppUserType,
		});
}

	async setCompanyName(companyID) {

		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getcompanydata?companyid=" + companyID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		var companyName = jsonObj.companyName;
		this.setState({companyName: companyName});
	}
	
	async serverGetMLServerIPAddress() {

		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/getpcipaddress";

		const res = await fetch(url);
		const mlServerIPAddress = await res.text();
		
		var mlBaseURLAPI = "http://" + mlServerIPAddress + ":8000";

		// temp
		//var vectorBaseURLAPI = "http://" + mlServerIPAddress + ":8001";

		this.setState({
			mlServerIPAddress: mlServerIPAddress,
			mlBaseURLAPI: mlBaseURLAPI,
		});
		
		
		this.mlServerPing(mlBaseURLAPI);
	}

	async mlServerPing(mlBaseURLAPI) {

		var url = mlBaseURLAPI + "/ping";

		var responseText = "";
		var ping = -1;
		
		try {
			const res = await fetch(url);
			responseText = await res.text();
		} catch (e) {
			responseText = "";
		}

		if (responseText === "PING") {
			ping = 1;
		} else {
			ping = 0;
		}
		
		this.setState({
			ping: ping,
		});
	}

	async serverGetModelData(modelID) {
		
		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/getmodeldata?modelid=" + modelID;
		
		const res = await fetch(url);
		var jsonObj = await res.json();
		var modelName = jsonObj.modelName;
		var helloMessage = jsonObj.helloMessage;
		var learn = jsonObj.learn;
		
		var modelLearnYN = false;
		if (learn === 1) {
			modelLearnYN = true;
		}
		
		this.setState({
			modelName: modelName,
			modelLearnYN: modelLearnYN,
			helloMessage: helloMessage,
		});

		this.serverGetUserModelData(this.state.email, modelID);
	}
	
	async serverGetUserSettingsData(userID) {

		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/getusersettingsdata?userid=" + userID;
		
		const res = await fetch(url);
		var responseText = await res.text();
		
		var modelID = 0;
		if (responseText !== "") {
			var jsonObj = JSON.parse(responseText);
			modelID = jsonObj.modelID;
		}
		
		if (modelID > 0) {
			this.serverGetModelData(modelID);
		}

		this.setState({
			modelID: modelID,
		});
	}
	
	async serverGetUserModelData(email, modelID) {

		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/getusermodeldata?email=" + encodeURIComponent(email) + "&modelid=" + modelID;
		
		const res = await fetch(url);
		var responseText = await res.text();
		
		var modelUserType = 0;
		if (responseText !== "") {
			var jsonObj = JSON.parse(responseText);
			modelUserType = jsonObj.userType;
		}
		
		this.setState({
			modelUserType: modelUserType,
		});
	}
	
	async serverSetUserModelID(userID, fullName, companyID, modelID) {

		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/setusermodelid?userid=" + userID;
		url = url + "&userfullname=" + encodeURIComponent(fullName);
		url = url + "&companyid=" + companyID;
		url = url + "&modelid=" + modelID;
		
		const res = await fetch(url);
		await res.text();
	}
	
	async serverInsertChangeLogRecord(title, changeJSON) {

		var modelID = this.state.modelID;
		var userID = this.state.userID;
		var fullName = this.state.fullName;
		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/insertchangelogrecord?modelid=" + modelID;
		url = url + "&title=" + encodeURIComponent(title);
		url = url + "&changejson=" + encodeURIComponent(changeJSON);
		url = url + "&userid=" + userID;
		url = url + "&fullname=" + encodeURIComponent(fullName);
		
		const res = await fetch(url);
		await res.text();
	}
		
	setSessionID(sessionID) {
		this.setState({sessionID: sessionID});
	}
	
	setUserID(userID) {
		this.setState({userID: userID});
	}
	
	setLanguage(language) {
		var userID = this.state.userID;
		this.serverUpdateUserLanguage(userID, language);
		this.setState({language: language});
	}
	
	setQuestionArray(questionArray) {
		this.setState({questionArray: questionArray});
	}
	
	setAnswerArray(answerArray) {
		this.setState({answerArray: answerArray});
	}
	
	setFilesArray(filesArray) {
		this.setState({filesArray: filesArray});
	}
	
	setVideoArray(videoArray) {
		this.setState({videoArray: videoArray});
	}
	
	setSelectedDocument(filename) {
		this.setState({documentFilename: filename});
	}

	setSelectedPageFolder(pageFolder) {
		this.setState({pageFolder: pageFolder});
	}

	setSelectedPageSubfolder(pageSubfolder) {
		this.setState({pageSubfolder: pageSubfolder});
	}
	
	setSelectedPageFilenameExt(pageFilenameExt) {
		this.setState({pageFilenameExt: pageFilenameExt});
	}

	setSelectedQuestionText(questionText) {
		this.setState({questionText: questionText});
	}
	
	setSelectedStudent(studentID, studentFullName) {
		this.setState({
			studentID: studentID,
			studentFullName: studentFullName,
		});
	}	
	
	setSelectedModel(modelID) {
		this.setState({
			modelID: modelID,
			questionArray: [],
			answerArray: [],
			filesArray: [],
			questionSampleCategoryID: 0,
			questionLogListIndex: 0,
			changeLogListIndex: 0,
		});
		this.serverGetModelData(modelID);
		this.serverSetUserModelID(this.state.userID, this.state.fullName, this.state.companyID, modelID);
	}
	
	setSelectedQuestionSampleID(questionSampleID) {
		this.setState({
			questionSampleID: questionSampleID,
		});
	}
	
	setSelectedQuestionSampleCategoryID(questionSampleCategoryID) {
		this.setState({
			questionSampleCategoryID: questionSampleCategoryID,
		});
	}
		
	setSelectedQuestionLogID(questionLogID) {
		this.setState({
			questionLogID: questionLogID,
		});
	}
	
	setSelectedModelFromWebSiteID(modelFromWebSiteID) {
		this.setState({
			modelFromWebSiteID: modelFromWebSiteID,
		});
	}
	
	setQuestionLogListIndex(questionLogListIndex) {
		this.setState({
			questionLogListIndex: questionLogListIndex,
		});
	}
	
	setModelFromWebSiteJobListIndex(modelFromWebSiteJobListIndex) {
		this.setState({
			modelFromWebSiteJobListIndex: modelFromWebSiteJobListIndex,
		});
	}
	
	setChangeLogListIndex(changeLogListIndex) {
		this.setState({
			changeLogListIndex: changeLogListIndex,
		});
	}
	
	setNavbarOpenYN(navbarOpenYN) {
		this.setState({
			navbarOpenYN: navbarOpenYN,
		});
	}
	
	getLang(lang, id) {
		var language = this.state.language;
		var id2 = id + "." + language;
		var i;
		var id3;
		var length = lang.length;
		var itemObj;
		var keys;
		var returnString;
		for(i=0; i<length; i++) {
			itemObj = lang[i];
			keys = Object.keys(itemObj);
			id3 = keys[0];
			if (id2 === id3) {
				returnString = itemObj[id2];
				return returnString;
			}
		}
		return "";
	}
	
	separateTextIntoArrayOfLines(inputText) {

		var lineArray = [];

		if (inputText === null) {
			inputText = "";
		}

		if (inputText === "") {
			return lineArray;
		}

		var i;
		var ch;
		var len = inputText.length;
		var oneLineText = "";
		
		for(i=0; i<len; i++) {
			ch = inputText[i];
			if (ch === "\n") {
				lineArray.push(oneLineText);
				oneLineText = "";
			} else {
				if (ch !== "\r") {
					oneLineText = oneLineText + ch;
				}
			}
		}
		
		lineArray.push(oneLineText);

		return lineArray;
	}

	convertTextNewlineToBr(inputText, variable, className) {
		
		var lineArray = this.separateTextIntoArrayOfLines(inputText);
		var jsxArray = [];
		
		var i;
		var len = lineArray.length;
		var oneLineText;
		var key;
		
		for(i=0; i<len; i++) {
			key = variable + "-" + i;
			oneLineText = lineArray[i];
			if (oneLineText === "") {
				jsxArray.push(<div key={key} style={{height: "10px"}}></div>);
			} else {
				jsxArray.push(<div key={key} className={className}>{oneLineText}</div>);
			}
		}
		
		return (<div>{jsxArray}</div>);
	}

	getDateStringFromUnixTimeSec(unixTimeSec) {

		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			return "";
		}

		var dateObj = new Date(unixTimeSec * 1000);

		var dateString = moment(dateObj).format('DD.MM.YYYY');
		return dateString;
	}

	getTimeStringFromUnixTimeSec(unixTimeSec) {

		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			return "";
		}

		var dateObj = new Date(unixTimeSec * 1000);

		var timeString = moment(dateObj).format('HH:mm');
		return timeString;
	}

	getDateTimeStringFromUnixTimeSec(unixTimeSec) {

		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			return "";
		}

		var currentDateObj = new Date();
		var dateObj = new Date(unixTimeSec * 1000);
		
		var year1 = currentDateObj.getFullYear();
		var year2 = dateObj.getFullYear();
		
		var dateTimeString;
		if (year1 === year2) {
			dateTimeString = moment(dateObj).format('DD/MM HH:mm');
		} else {
			dateTimeString = moment(dateObj).format('DD.MM.YYYY HH:mm');
		}

		return dateTimeString;
	}

	getTimeStringFromSeconds(seconds) {
		
		if (seconds === null) {
			return "";
		}

		var secondsLeft = 0;
		var hours = 0;
		var minutes = 0;
		var timeString = "";
		
		secondsLeft = seconds;
		
		hours = Math.floor(secondsLeft / 3600);
		secondsLeft = secondsLeft - hours * 3600;
		
		minutes = Math.floor(secondsLeft / 60);
		secondsLeft = secondsLeft - minutes * 60;
		
		if (hours > 0) {
			timeString = hours + "h ";
		}
		
		if (minutes > 0) {
			timeString = timeString + minutes + "m ";
		}
		
		if ((hours === 0) && (minutes === 0)) {
			timeString = timeString + secondsLeft + "s";
		}

		return timeString;
	}
	
	checkIfLoggedIn() {
				
		var pathname = window.location.pathname;
		
		if (pathname === "/") {
			return;
		}

		var userID = this.state.userID;
		if (userID !== 0) {
			return;
		}
		
		var url = "/";
		window.location.href = url;
	}
	
	render() {

		var redirectCheckedYN = this.state.redirectCheckedYN;
		if (!redirectCheckedYN) {
			return null;
		}		

		this.checkIfLoggedIn();
		
		var firstLoadYN = this.state.firstLoadYN;
		if (firstLoadYN) {
			return null;
		}
		
		var loggedInYN;
		var userID = this.state.userID;
		
		if (userID === 0) {
			loggedInYN = false;
		} else {
			loggedInYN = true;
		}
		
		var modelName = this.state.modelName;
		if (modelName === "") {
			modelName = "CHATGPT"
		}
		
		var chatAppUserType = this.state.chatAppUserType;
		if ((loggedInYN) && (chatAppUserType < 1)) {
			return (
				<div>You do not have permission to use this app.</div>
			);
		}
		
		return (
		<Router>
			<div className="App" onClick={this.handleMouseClick}>
				<ToastContainer />
				<Topbar
					dbtouch={this.state.dbtouch}
					userID={this.state.userID}
					email={this.state.email}
					firstName={this.state.firstName}
					lastName={this.state.lastName}
					sessionID={this.state.sessionID}
					appUserType={this.state.chatAppUserType}
					modelUserType={this.state.modelUserType}
					modelLearnYN={this.state.modelLearnYN}
					navbarOpenYN={this.state.navbarOpenYN}
					iconName="CHAT"
					appName={modelName}
					bkColor1="#4e2675"
					bkColor2="#f0f0f0"
					logoutClearVariables={this.logoutClearVariables}
					setLanguage={this.setLanguage}
					getLang={this.getLang}
					setNavbarOpenYN={this.setNavbarOpenYN}
				/>
        		<Routes>
          			<Route 
						path="/"
						element={
							<div>
							{!loggedInYN && (
								<Login
									managementBaseURLAPI={this.state.managementBaseURLAPI}
									setSessionID={this.setSessionID}
									setUserID={this.setUserID}
									serverGetUserData={this.serverGetUserData}
									serverDBTouchGetUserData={this.serverDBTouchGetUserData}
									getLang={this.getLang}
								/>
							)}
							{loggedInYN && (
								<Chat
									baseURLAPI={this.state.baseURLAPI}
									modelID={this.state.modelID}
									modelName={this.state.modelName}
									helloMessage={this.state.helloMessage}
									questionSampleID={this.state.questionSampleID}
									questionText={this.state.questionText}
									userID={this.state.userID}
									fullName={this.state.fullName}
									questionArray={this.state.questionArray}
									answerArray={this.state.answerArray}
									filesArray={this.state.filesArray}
									videoArray={this.state.videoArray}
									setQuestionArray={this.setQuestionArray}
									setAnswerArray={this.setAnswerArray}
									setFilesArray={this.setFilesArray}
									setVideoArray={this.setVideoArray}
									showToast={this.showToast}
									getLang={this.getLang}
									convertTextNewlineToBr={this.convertTextNewlineToBr}
									setSelectedQuestionText={this.setSelectedQuestionText}
									setSelectedQuestionSampleID={this.setSelectedQuestionSampleID}
								/>
							)}
							</div>
						}
					/>
          			<Route 
						path="/login"
						element={
							<Login
								managementBaseURLAPI={this.state.managementBaseURLAPI}
								setSessionID={this.setSessionID}
								setUserID={this.setUserID}
								serverGetUserData={this.serverGetUserData}
								serverDBTouchGetUserData={this.serverDBTouchGetUserData}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/chat"
						element={
							<Chat
								baseURLAPI={this.state.baseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								helloMessage={this.state.helloMessage}
								questionSampleID={this.state.questionSampleID}
								questionText={this.state.questionText}
								userID={this.state.userID}
								fullName={this.state.fullName}
								questionArray={this.state.questionArray}
								answerArray={this.state.answerArray}
								filesArray={this.state.filesArray}
								videoArray={this.state.videoArray}
								setQuestionArray={this.setQuestionArray}
								setAnswerArray={this.setAnswerArray}
								setFilesArray={this.setFilesArray}
								setVideoArray={this.setVideoArray}
								showToast={this.showToast}
								getLang={this.getLang}
								convertTextNewlineToBr={this.convertTextNewlineToBr}
								setSelectedQuestionText={this.setSelectedQuestionText}
								setSelectedQuestionSampleID={this.setSelectedQuestionSampleID}
							/>
						}
					/>
         			<Route 
						path="/samplequestions"
						element={
							<QuestionSampleList
								baseURLAPI={this.state.baseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								userID={this.state.userID}
								fullName={this.state.fullName}
								chatAppUserType={this.state.chatAppUserType}
								modelUserType={this.state.modelUserType}
								questionSampleCategoryID={this.state.questionSampleCategoryID}
								showToast={this.showToast}
								getLang={this.getLang}
								setSelectedQuestionText={this.setSelectedQuestionText}
								setSelectedQuestionSampleID={this.setSelectedQuestionSampleID}
								setSelectedQuestionSampleCategoryID={this.setSelectedQuestionSampleCategoryID}
							/>
						}
					/>
         			<Route 
						path="/samplequestions/reorder"
						element={
							<QuestionSampleReorder
								baseURLAPI={this.state.baseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								userID={this.state.userID}
								fullName={this.state.fullName}
								chatAppUserType={this.state.chatAppUserType}
								modelUserType={this.state.modelUserType}
								questionSampleCategoryID={this.state.questionSampleCategoryID}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/samplequestioncategories/new"
						element={
							<QuestionSampleCategoryNew
								baseURLAPI={this.state.baseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								userID={this.state.userID}
								fullName={this.state.fullName}
								chatAppUserType={this.state.chatAppUserType}
								modelUserType={this.state.modelUserType}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/samplequestioncategories/edit"
						element={
							<QuestionSampleCategoryEdit
								baseURLAPI={this.state.baseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								userID={this.state.userID}
								fullName={this.state.fullName}
								chatAppUserType={this.state.chatAppUserType}
								modelUserType={this.state.modelUserType}
								questionSampleCategoryID={this.state.questionSampleCategoryID}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/samplequestioncategories/reorder"
						element={
							<QuestionSampleCategoryReorder
								baseURLAPI={this.state.baseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								userID={this.state.userID}
								fullName={this.state.fullName}
								chatAppUserType={this.state.chatAppUserType}
								modelUserType={this.state.modelUserType}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
					/>					
         			<Route 
						path="/samplequestions/new"
						element={
							<QuestionSampleNew
								baseURLAPI={this.state.baseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								userID={this.state.userID}
								fullName={this.state.fullName}
								questionSampleCategoryID={this.state.questionSampleCategoryID}
								showToast={this.showToast}
								getLang={this.getLang}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>					
         			<Route 
						path="/samplequestions/edit"
						element={
							<QuestionSampleEdit
								baseURLAPI={this.state.baseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								questionSampleID={this.state.questionSampleID}
								userID={this.state.userID}
								fullName={this.state.fullName}
								showToast={this.showToast}
								getLang={this.getLang}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route
						path="/questionlog"
						element={
							<QuestionLogList
								baseURLAPI={this.state.baseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								userID={this.state.userID}
								chatAppUserType={this.state.chatAppUserType}
								questionLogListIndex={this.state.questionLogListIndex}
								showToast={this.showToast}
								getLang={this.getLang}
								setSelectedQuestionLogID={this.setSelectedQuestionLogID}
								setQuestionLogListIndex={this.setQuestionLogListIndex}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
							/>
						}
					/>
         			<Route 
						path="/questionlog/view"
						element={
							<QuestionLogView
								baseURLAPI={this.state.baseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								userID={this.state.userID}
								questionLogID={this.state.questionLogID}
								chatAppUserType={this.state.chatAppUserType}
								showToast={this.showToast}
								getLang={this.getLang}
								setSelectedQuestionLogID={this.setSelectedQuestionLogID}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
								convertTextNewlineToBr={this.convertTextNewlineToBr}
							/>
						}
					/>
         			<Route
						path="/questionlogall"
						element={
							<QuestionLogAllList
								baseURLAPI={this.state.baseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								userID={this.state.userID}
								chatAppUserType={this.state.chatAppUserType}
								questionLogListIndex={this.state.questionLogListIndex}
								showToast={this.showToast}
								getLang={this.getLang}
								setSelectedQuestionLogID={this.setSelectedQuestionLogID}
								setQuestionLogListIndex={this.setQuestionLogListIndex}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
							/>
						}
					/>					
         			<Route 
						path="/docs"
						element={
							<DocumentList
								userID={this.state.userID}
								fullName={this.state.fullName}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								baseURLAPI={this.state.baseURLAPI}
								chatAppUserType={this.state.chatAppUserType}
								modelUserType={this.state.modelUserType}
								setSelectedDocument={this.setSelectedDocument}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/docs/view"
						element={
							<DocumentView
								baseURLAPI={this.state.baseURLAPI}
								vectorBaseURLAPI={this.state.vectorBaseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								documentFilename={this.state.documentFilename}
								getLang={this.getLang}
								convertTextNewlineToBr={this.convertTextNewlineToBr}
								showToast={this.showToast}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route 
						path="/docs/new"
						element={
							<DocumentNew
								baseURLAPI={this.state.baseURLAPI}
								vectorBaseURLAPI={this.state.vectorBaseURLAPI}
								userID={this.state.userID}
								fullName={this.state.fullName}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								getLang={this.getLang}
								showToast={this.showToast}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route 
						path="/docs/edittext"
						element={
							<DocumentEditText
								baseURLAPI={this.state.baseURLAPI}
								userID={this.state.userID}
								fullName={this.state.fullName}
								companyID={this.state.companyID}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								modelLearnYN={this.state.modelLearnYN}
								documentFilename={this.state.documentFilename}
								getLang={this.getLang}
								showToast={this.showToast}
								separateTextIntoArrayOfLines={this.separateTextIntoArrayOfLines}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route 
						path="/docs/editquestions"
						element={
							<DocumentEditQuestions
								baseURLAPI={this.state.baseURLAPI}
								vectorBaseURLAPI={this.state.vectorBaseURLAPI}
								userID={this.state.userID}
								fullName={this.state.fullName}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								documentFilename={this.state.documentFilename}
								getLang={this.getLang}
								showToast={this.showToast}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>					
         			<Route 
						path="/pages"
						element={
							<PageList
								userID={this.state.userID}
								fullName={this.state.fullName}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								baseURLAPI={this.state.baseURLAPI}
								chatAppUserType={this.state.chatAppUserType}
								modelUserType={this.state.modelUserType}
								setSelectedPageFolder={this.setSelectedPageFolder}
								setSelectedPageSubfolder={this.setSelectedPageSubfolder}								
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/pages/newfolder"
						element={
							<PageNewFolder
								userID={this.state.userID}
								fullName={this.state.fullName}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								baseURLAPI={this.state.baseURLAPI}
								chatAppUserType={this.state.chatAppUserType}
								modelUserType={this.state.modelUserType}
								setSelectedPageFolder={this.setSelectedPageFolder}
								setSelectedPageSubfolder={this.setSelectedPageSubfolder}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/pages/renamefolder"
						element={
							<PageRenameFolder
								userID={this.state.userID}
								fullName={this.state.fullName}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								baseURLAPI={this.state.baseURLAPI}
								pageFolder={this.state.pageFolder}
								chatAppUserType={this.state.chatAppUserType}
								modelUserType={this.state.modelUserType}
								setSelectedPageFolder={this.setSelectedPageFolder}
								setSelectedPageSubfolder={this.setSelectedPageSubfolder}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/pages/newsubfolder"
						element={
							<PageNewSubfolder
								userID={this.state.userID}
								fullName={this.state.fullName}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								baseURLAPI={this.state.baseURLAPI}
								pageFolder={this.state.pageFolder}
								chatAppUserType={this.state.chatAppUserType}
								modelUserType={this.state.modelUserType}
								setSelectedPageFolder={this.setSelectedPageFolder}
								setSelectedPageSubfolder={this.setSelectedPageSubfolder}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/page"
						element={
							<PageMain
								baseURLAPI={this.state.baseURLAPI}
								vectorBaseURLAPI={this.state.vectorBaseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								modelLearnYN={this.state.modelLearnYN}
								pageFolder={this.state.pageFolder}
								pageSubfolder={this.state.pageSubfolder}
								getLang={this.getLang}
								convertTextNewlineToBr={this.convertTextNewlineToBr}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
								showToast={this.showToast}
								getFilenameAndExtFromFilenameExt={this.getFilenameAndExtFromFilenameExt}
								setSelectedPageFilenameExt={this.setSelectedPageFilenameExt}
							/>
						}
					/>
         			<Route 
						path="/page/newimage"
						element={
							<PageEditImage
								newYN={true}
								baseURLAPI={this.state.baseURLAPI}
								vectorBaseURLAPI={this.state.vectorBaseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								pageFolder={this.state.pageFolder}
								pageSubfolder={this.state.pageSubfolder}
								pageFilenameExt={this.state.pageFilenameExt}
								getLang={this.getLang}
								showToast={this.showToast}
								getFilenameAndExtFromFilenameExt={this.getFilenameAndExtFromFilenameExt}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route 
						path="/page/editimage"
						element={
							<PageEditImage
								baseURLAPI={this.state.baseURLAPI}
								vectorBaseURLAPI={this.state.vectorBaseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								pageFolder={this.state.pageFolder}
								pageSubfolder={this.state.pageSubfolder}
								pageFilenameExt={this.state.pageFilenameExt}
								getLang={this.getLang}
								showToast={this.showToast}
								getFilenameAndExtFromFilenameExt={this.getFilenameAndExtFromFilenameExt}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route 
						path="/page/newtext"
						element={
							<PageEditText
								newYN={true}
								baseURLAPI={this.state.baseURLAPI}
								vectorBaseURLAPI={this.state.vectorBaseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								pageFolder={this.state.pageFolder}
								pageSubfolder={this.state.pageSubfolder}
								pageFilenameExt={this.state.pageFilenameExt}
								getLang={this.getLang}
								convertTextNewlineToBr={this.convertTextNewlineToBr}
								showToast={this.showToast}
								getFilenameAndExtFromFilenameExt={this.getFilenameAndExtFromFilenameExt}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route 
						path="/page/edittext"
						element={
							<PageEditText
								baseURLAPI={this.state.baseURLAPI}
								vectorBaseURLAPI={this.state.vectorBaseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								pageFolder={this.state.pageFolder}
								pageSubfolder={this.state.pageSubfolder}
								pageFilenameExt={this.state.pageFilenameExt}
								getLang={this.getLang}
								convertTextNewlineToBr={this.convertTextNewlineToBr}
								showToast={this.showToast}
								getFilenameAndExtFromFilenameExt={this.getFilenameAndExtFromFilenameExt}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route 
						path="/page/newrichtext"
						element={
							<PageEditRichText
								newYN={true}
								baseURLAPI={this.state.baseURLAPI}
								vectorBaseURLAPI={this.state.vectorBaseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								pageFolder={this.state.pageFolder}
								pageSubfolder={this.state.pageSubfolder}
								pageFilenameExt={this.state.pageFilenameExt}
								getLang={this.getLang}
								showToast={this.showToast}
								getFilenameAndExtFromFilenameExt={this.getFilenameAndExtFromFilenameExt}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route 
						path="/page/editrichtext"
						element={
							<PageEditRichText
								baseURLAPI={this.state.baseURLAPI}
								vectorBaseURLAPI={this.state.vectorBaseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								pageFolder={this.state.pageFolder}
								pageSubfolder={this.state.pageSubfolder}
								pageFilenameExt={this.state.pageFilenameExt}
								getLang={this.getLang}
								showToast={this.showToast}
								getFilenameAndExtFromFilenameExt={this.getFilenameAndExtFromFilenameExt}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route 
						path="/page/newhtml"
						element={
							<PageEditHtml
								newYN={true}
								baseURLAPI={this.state.baseURLAPI}
								vectorBaseURLAPI={this.state.vectorBaseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								pageFolder={this.state.pageFolder}
								pageSubfolder={this.state.pageSubfolder}
								pageFilenameExt={this.state.pageFilenameExt}
								getLang={this.getLang}
								convertTextNewlineToBr={this.convertTextNewlineToBr}
								showToast={this.showToast}
								getFilenameAndExtFromFilenameExt={this.getFilenameAndExtFromFilenameExt}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route 
						path="/page/edithtml"
						element={
							<PageEditHtml
								baseURLAPI={this.state.baseURLAPI}
								vectorBaseURLAPI={this.state.vectorBaseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								pageFolder={this.state.pageFolder}
								pageSubfolder={this.state.pageSubfolder}
								pageFilenameExt={this.state.pageFilenameExt}
								getLang={this.getLang}
								convertTextNewlineToBr={this.convertTextNewlineToBr}
								showToast={this.showToast}
								getFilenameAndExtFromFilenameExt={this.getFilenameAndExtFromFilenameExt}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route 
						path="/page/newlink"
						element={
							<PageEditLink
								newYN={true}
								baseURLAPI={this.state.baseURLAPI}
								vectorBaseURLAPI={this.state.vectorBaseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								pageFolder={this.state.pageFolder}
								pageSubfolder={this.state.pageSubfolder}
								pageFilenameExt={this.state.pageFilenameExt}
								getLang={this.getLang}
								convertTextNewlineToBr={this.convertTextNewlineToBr}
								showToast={this.showToast}
								getFilenameAndExtFromFilenameExt={this.getFilenameAndExtFromFilenameExt}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route 
						path="/page/editlink"
						element={
							<PageEditLink
								baseURLAPI={this.state.baseURLAPI}
								vectorBaseURLAPI={this.state.vectorBaseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								pageFolder={this.state.pageFolder}
								pageSubfolder={this.state.pageSubfolder}
								pageFilenameExt={this.state.pageFilenameExt}
								getLang={this.getLang}
								convertTextNewlineToBr={this.convertTextNewlineToBr}
								showToast={this.showToast}
								getFilenameAndExtFromFilenameExt={this.getFilenameAndExtFromFilenameExt}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route 
						path="/page/newsinglechoice"
						element={
							<PageEditSingleChoice
								newYN={true}
								baseURLAPI={this.state.baseURLAPI}
								vectorBaseURLAPI={this.state.vectorBaseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								pageFolder={this.state.pageFolder}
								pageSubfolder={this.state.pageSubfolder}
								pageFilenameExt={this.state.pageFilenameExt}
								getLang={this.getLang}
								convertTextNewlineToBr={this.convertTextNewlineToBr}
								showToast={this.showToast}
								getFilenameAndExtFromFilenameExt={this.getFilenameAndExtFromFilenameExt}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route 
						path="/page/editsinglechoice"
						element={
							<PageEditSingleChoice
								baseURLAPI={this.state.baseURLAPI}
								vectorBaseURLAPI={this.state.vectorBaseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								pageFolder={this.state.pageFolder}
								pageSubfolder={this.state.pageSubfolder}
								pageFilenameExt={this.state.pageFilenameExt}
								getLang={this.getLang}
								convertTextNewlineToBr={this.convertTextNewlineToBr}
								showToast={this.showToast}
								getFilenameAndExtFromFilenameExt={this.getFilenameAndExtFromFilenameExt}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route 
						path="/page/newpronounce"
						element={
							<PageEditPronounce
								newYN={true}
								baseURLAPI={this.state.baseURLAPI}
								vectorBaseURLAPI={this.state.vectorBaseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								pageFolder={this.state.pageFolder}
								pageSubfolder={this.state.pageSubfolder}
								pageFilenameExt={this.state.pageFilenameExt}
								getLang={this.getLang}
								convertTextNewlineToBr={this.convertTextNewlineToBr}
								showToast={this.showToast}
								getFilenameAndExtFromFilenameExt={this.getFilenameAndExtFromFilenameExt}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route 
						path="/page/editpronounce"
						element={
							<PageEditPronounce
								baseURLAPI={this.state.baseURLAPI}
								vectorBaseURLAPI={this.state.vectorBaseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								pageFolder={this.state.pageFolder}
								pageSubfolder={this.state.pageSubfolder}
								pageFilenameExt={this.state.pageFilenameExt}
								getLang={this.getLang}
								convertTextNewlineToBr={this.convertTextNewlineToBr}
								showToast={this.showToast}
								getFilenameAndExtFromFilenameExt={this.getFilenameAndExtFromFilenameExt}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route
						path="/changelog"
						element={
							<ChangeLogList
								baseURLAPI={this.state.baseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								userID={this.state.userID}
								chatAppUserType={this.state.chatAppUserType}
								changeLogListIndex={this.state.changeLogListIndex}
								showToast={this.showToast}
								getLang={this.getLang}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
								setChangeLogListIndex={this.setChangeLogListIndex}
							/>
						}
					/>
         			<Route
						path="/userlessonlog"
						element={
							<UserLessonLogList
								baseURLAPI={this.state.baseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								userID={this.state.userID}
								companyID={this.state.companyID}
								chatAppUserType={this.state.chatAppUserType}
								setSelectedStudent={this.setSelectedStudent}
								showToast={this.showToast}
								getLang={this.getLang}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
							/>
						}
					/>
         			<Route
						path="/userlessonlog/view"
						element={
							<UserLessonLogView
								baseURLAPI={this.state.baseURLAPI}
								storageBaseURLAPI={this.state.storageBaseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								userID={this.state.userID}
								studentID={this.state.studentID}
								studentFullName={this.state.studentFullName}
								companyID={this.state.companyID}
								chatAppUserType={this.state.chatAppUserType}
								showToast={this.showToast}
								getLang={this.getLang}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
							/>
						}
					/>
         			<Route 
						path="/models"
						element={
							<ModelList
								baseURLAPI={this.state.baseURLAPI}
								setSelectedModel={this.setSelectedModel}
								getLang={this.getLang}
								showToast={this.showToast}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route 
						path="/models/new"
						element={
							<ModelNew
								baseURLAPI={this.state.baseURLAPI}
								setSelectedModel={this.setSelectedModel}
								getLang={this.getLang}
								showToast={this.showToast}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route 
						path="/models/newfromwebsite"
						element={
							<ModelNewFromWebSite
								baseURLAPI={this.state.baseURLAPI}
								vectorBaseURLAPI={this.state.vectorBaseURLAPI}
								userID={this.state.userID}
								fullName={this.state.fullName}
								email={this.state.email}
								setSelectedModel={this.setSelectedModel}
								getLang={this.getLang}
								showToast={this.showToast}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
								modelCopyHelpersObj={this.state.modelCopyHelpersObj}
							/>
						}
					/>
         			<Route 
						path="/models/newfromwebsitejoblist"
						element={
							<ModelNewFromWebSiteJobList
								baseURLAPI={this.state.baseURLAPI}
								modelFromWebSiteJobListIndex={this.state.modelFromWebSiteJobListIndex}
								setSelectedModel={this.setSelectedModel}
								getLang={this.getLang}
								showToast={this.showToast}
								setModelFromWebSiteJobListIndex={this.setModelFromWebSiteJobListIndex}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
								setSelectedModelFromWebSiteID={this.setSelectedModelFromWebSiteID}
							/>
						}
					/>
         			<Route 
						path="/models/newfromwebsitejobview"
						element={
							<ModelNewFromWebSiteJobView
								baseURLAPI={this.state.baseURLAPI}
								storageBaseURLAPI={this.state.storageBaseURLAPI}
								modelFromWebSiteID={this.state.modelFromWebSiteID}
								getLang={this.getLang}
								showToast={this.showToast}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
								getTimeStringFromSeconds={this.getTimeStringFromSeconds}
								separateTextIntoArrayOfLines={this.separateTextIntoArrayOfLines}
							/>
						}
					/>					
         			<Route 
						path="/models/copy"
						element={
							<ModelCopy
								baseURLAPI={this.state.baseURLAPI}
								vectorBaseURLAPI={this.state.vectorBaseURLAPI}
								modelID={this.state.modelID}
								modelName={this.state.modelName}
								setSelectedModel={this.setSelectedModel}
								getLang={this.getLang}
								showToast={this.showToast}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
								modelCopyHelpersObj={this.state.modelCopyHelpersObj}
							/>
						}
					/>
         			<Route 
						path="/models/edit"
						element={
							<ModelEdit
								baseURLAPI={this.state.baseURLAPI}
								modelID={this.state.modelID}
								getLang={this.getLang}
								showToast={this.showToast}
								serverInsertChangeLogRecord={this.serverInsertChangeLogRecord}
							/>
						}
					/>
         			<Route 
						path="/selectmodel"
						element={
							<SelectModel
								baseURLAPI={this.state.baseURLAPI}
								getLang={this.getLang}
								setSelectedModel={this.setSelectedModel}
							/>
						}
					/>
          			<Route 
						path="/about"
						element={
							<About
								baseURLAPI={this.state.baseURLAPI}
								getLang={this.getLang}
							/>
						}
          			/>
        		</Routes>
			</div>
    		</Router>
		);
	}
}

export default App;

/*
*/


