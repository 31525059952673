import React  from 'react';
import './App.css';
import './Chat.css';
//import ChatLang from './ChatLang.json';
import FileViewPopup from './FileViewPopup.js';
import {isMobile} from 'react-device-detect';
import Iframe from 'react-iframe'

class Chat extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			questionText: "",
			questionTextDisabledYN: false,
			showFileViewPopupYN: false,
			filename: "",
			fileQuestionText: "",
			fileSimilarityScore: 0,
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.serverGetQuestionSampleData = this.serverGetQuestionSampleData.bind(this);
		this.readChunk = this.readChunk.bind(this);
		this.appendChunks = this.appendChunks.bind(this);
		this.processChunkedResponse = this.processChunkedResponse.bind(this);
		this.serverSubmitQuestion = this.serverSubmitQuestion.bind(this);
		this.onSubmitQuestion = this.onSubmitQuestion.bind(this);
		this.onQuestionTextChanged = this.onQuestionTextChanged.bind(this);
		this.setShowFileViewPopupYN = this.setShowFileViewPopupYN.bind(this);
		this.handleSelectFile = this.handleSelectFile.bind(this);
		this.renderHelloMessage = this.renderHelloMessage.bind(this);
		this.renderOneFile = this.renderOneFile.bind(this);
		this.renderAllFiles = this.renderAllFiles.bind(this);
		this.renderVideo = this.renderVideo.bind(this);
		this.renderOneQuestionAndAnswer = this.renderOneQuestionAndAnswer.bind(this);
		this.renderAllQuestionsAndAnswers = this.renderAllQuestionsAndAnswers.bind(this);
		this.renderInput = this.renderInput.bind(this);
		this.renderIFrame = this.renderIFrame.bind(this);
	}
	
	componentDidMount() {
		var questionSampleID = this.props.questionSampleID;
		var questionText = this.props.questionText;
		this.props.setSelectedQuestionSampleID(0);
		this.props.setSelectedQuestionText("");
		if (questionSampleID !== 0) {
			this.serverGetQuestionSampleData(questionSampleID, questionText);
		}
	}
	
	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onSubmitQuestion(this.state.questionText);
		}
	}
	
	async serverGetQuestionSampleData(questionSampleID, questionText) {

		var baseURLAPI = this.props.baseURLAPI;
		var modelID = this.props.modelID;
		var url = baseURLAPI + "/getquestionsampledata?modelid=" + modelID + "&questionsampleid=" + questionSampleID;

		const res = await fetch(url);
		const jsonObj = await res.json();
		
		var type = jsonObj.type;
		
		if (type === "none") {
			this.onSubmitQuestion(questionText);
			return;
		}

		var answerText = "";
		var questionArray = this.props.questionArray;
		var answerArray = this.props.answerArray;
		var filesArray = this.props.filesArray;
		var videoArray = this.props.videoArray;

		if (type === "text") {

			answerText = jsonObj.text;
			this.setState({answerText: answerText});

			questionArray.push(questionText);
			answerArray.push(answerText);
			filesArray.push(null);
			videoArray.push("");

			this.props.setQuestionArray(questionArray);
			this.props.setAnswerArray(answerArray);
			this.props.setFilesArray(filesArray);
			this.props.setVideoArray(videoArray);
			this.refQAEnd.scrollIntoView({ behavior: "smooth" });

			//this.serverInsertQuestionLogRecord(questionText, answerText);
			return;
		}

		if (type === "audio") {

			answerText = jsonObj.text;
			//var audioURL = jsonObj.audioURL;
			this.setState({answerText: answerText});
			//var url = aiBaseURLAPI + audioURL;

			questionArray.push(questionText);
			answerArray.push(answerText);
			filesArray.push(null);
			videoArray.push("");
			this.refQAEnd.scrollIntoView({ behavior: "smooth" });

			this.props.setQuestionArray(questionArray);
			this.props.setAnswerArray(answerArray);
			this.props.setFilesArray(filesArray);
			this.props.setVideoArray(videoArray);

			//this.serverInsertQuestionLogRecord(questionText, answerText);
			return;
		}

		if (type === "video") {
			
			var videoURL = jsonObj.videoURL;

			questionArray.push(questionText);
			answerArray.push("");
			filesArray.push(null);
			videoArray.push(videoURL);

			this.props.setQuestionArray(questionArray);
			this.props.setAnswerArray(answerArray);
			this.props.setFilesArray(filesArray);
			this.props.setVideoArray(videoArray);
			this.refQAEnd.scrollIntoView({ behavior: "smooth" });

			return;
		}
	}

	readChunk(reader) {
		return reader.read().then(result =>{
			this.appendChunks(result, reader);
		});
	}

	appendChunks(result, reader) {

		var answerArray = this.props.answerArray;
		var length = answerArray.length;

		var decoder = new TextDecoder();
		var chunk = decoder.decode(result.value || new Uint8Array, {stream: !result.done});

		if (chunk.substring(0, 6) === "[DONE]") {
			var jsonString = chunk.substring(6, chunk.length);
			var jsonObj = JSON.parse(jsonString);
			var filesArray = this.props.filesArray;
			filesArray[length-1] = jsonObj;
			this.props.setFilesArray(filesArray);
			this.inputRef.focus();
			this.setState({
				questionTextDisabledYN: false,
			});			
			return;
		}

		var answerText = answerArray[length-1];
		
		answerText = answerText + chunk;		
		answerArray[length-1] = answerText;
				
		this.props.setAnswerArray(answerArray);

		this.refQAEnd.scrollIntoView({ behavior: "smooth" });

		if (chunk.includes("[ERROR_END]")) {
			this.inputRef.focus();
			this.setState({
				questionTextDisabledYN: false,
			});			
			return;
		}

		if (result.done) {
			return;
		} else {
			return this.readChunk(reader);
		}
	}

	processChunkedResponse(response) {

		var reader = response.body.getReader();
		this.readChunk(reader);
	}
	
	async serverSubmitQuestion(questionText) {

		questionText = questionText.trim();

		var modelID = this.props.modelID;
		var userID = this.props.userID;
		var fullName = this.props.fullName;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/submitquestion?key=dingac708&modelid=" + modelID;
		url = url + "&questiontext=" + encodeURIComponent(questionText);
		url = url + "&channel=" + encodeURIComponent("chat.plurato.com");
		url = url + "&userid=" + userID;
		url = url + "&fullname=" + fullName;
		console.log(url);
		
		fetch(url, {
		  method: 'GET',
		  keepalive: true,
		})
		.then(response => {
			this.processChunkedResponse(response);
		})

		//this.inputRef.focus();
		//this.refQAEnd.scrollIntoView({ behavior: "smooth" });
	}

	onSubmitQuestion(questionText) {

		questionText = questionText.trim();
		if (questionText === "") {
			return;
		}
		
		var questionArray = this.props.questionArray;
		var answerArray = this.props.answerArray;
		var filesArray = this.props.filesArray;
		var videoArray = this.props.videoArray;
		
		questionArray.push(questionText);
		answerArray.push("");
		filesArray.push(null);
		videoArray.push("");
		
		this.props.setQuestionArray(questionArray);
		this.props.setAnswerArray(answerArray);
		this.props.setFilesArray(filesArray);
		this.props.setVideoArray(videoArray);
		
		this.setState({
			questionText: "",
			questionTextDisabledYN: true,
		});
		
		this.refQAEnd.scrollIntoView({ behavior: "smooth" });
		this.serverSubmitQuestion(questionText);
	}

	onQuestionTextChanged(value) {
		this.setState({
			questionText: value,
		});
	}

	setShowFileViewPopupYN(showFileViewPopupYN) {
		this.setState({
			showFileViewPopupYN: showFileViewPopupYN,
		});
	}
	
	handleSelectFile(filename, fileQuestionText, fileSimilarityScore) {

		this.setState({
			filename: filename,
			fileQuestionText: fileQuestionText,
			fileSimilarityScore: fileSimilarityScore,
			showFileViewPopupYN: true,
		});
	}

	renderHelloMessage() {

		var helloMessage = this.props.helloMessage;
		if (helloMessage === "") {
			helloMessage = "Hi, I am AI model ChatGPT. How can I help you?";
		}

		return (
			<div key="-1">
				<div style={{height: "5px"}}></div>
				<div className="ChatAnswerText">
					{helloMessage}
				</div>
				<div style={{height: "20px"}}></div>
			</div>
		);
	}

	renderOneFile(index1, index2, itemObj) {
		
		var filename = itemObj.filename;
		var fileQuestionText = itemObj.questionText;
		var fileSimilarityScore = itemObj.similarityScore;
		var key = index1 + "-" + index2;
		
		var filename2 = filename.toUpperCase();

		return (
			<div key={key} className="ChatFilesContainer">
				<div className="ChatFilename" onClick={() => this.handleSelectFile(filename, fileQuestionText, fileSimilarityScore)}>
					{filename2}
				</div>
				<div className="ChatFilesSpace"></div>
			</div>
		);
	}

	renderAllFiles(index1, filesJsonObj) {

		if (filesJsonObj === null) {
			return;
		}
		
		var jsonObj = filesJsonObj.files;
		
		var length = 0;
		
		try {
			length = jsonObj.length;
		} catch {
			length = 0;
		}

		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<length; i++) {
			itemObj = jsonObj[i];
			jsxCode = this.renderOneFile(index1, i, itemObj);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}

	renderVideo(videoURL) {

		console.log("videoURL = " + videoURL);
		
		return (
			<iframe
				src={videoURL}
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
				title="video"
			/>
		);
	}

	renderOneQuestionAndAnswer(index) {

		var questionArray = this.props.questionArray;
		var answerArray = this.props.answerArray;
		var filesArray = this.props.filesArray;
		var videoArray = this.props.videoArray;
		
		var questionText = questionArray[index];
		var anwerText = answerArray[index];
		var filesJsonObj = filesArray[index];
		var videoURL = videoArray[index];
		
		return (
			<div key={index}>
				<div className="ChatQuestionText">
					{questionText}
				</div>
				<div style={{height: "5px"}}></div>
				{(videoURL === "") && (<div className="ChatAnswerText">
					{this.props.convertTextNewlineToBr(anwerText, "anwerText", "")}
				</div>)}
				{(videoURL !== "") && (<div className="ChatAnswerText">
					<div style={{height: "10px"}}></div>
					{this.renderVideo(videoURL)}
				</div>)}
				<div style={{height: "10px"}}></div>
				<div className="ChatFilesContainer">
					{this.renderAllFiles(index, filesJsonObj)}
				</div>
				<div style={{height: "20px"}}></div>
			</div>
		);
	}
	
	renderAllQuestionsAndAnswers() {
		
		var questionArray = this.props.questionArray;
		var length = questionArray.length;
		var i;
		var jsxCode;
		var jsxArray = [];
		
		var className = "ChatQAContainerDesktop";
		if (isMobile) {
			className = "ChatQAContainerMobile";
		}
		
		jsxCode = this.renderHelloMessage();
		jsxArray.push(jsxCode);
		
		for(i=0; i<length; i++) {
			jsxCode = this.renderOneQuestionAndAnswer(i);
			jsxArray.push(jsxCode);
		}
		
		return (
			<div className={className}>
				{jsxArray}
				<div style={{ float:"left", clear: "both" }}
					 ref={(el) => { this.refQAEnd = el; }}>
				</div>
			</div>
		);
	}
	
	renderInput() {
		
		//if (isMobile) {
			
		var placeholder = "Ask a question...";
		if (this.state.questionTextDisabledYN) {
			placeholder = "Thinking...";
		}

		return (
			<input
				ref={(input) => { this.inputRef = input; }} 
				className="ChatQuestionInput" 
				id="questionText"
				type="text" 
				//autoFocus={true}
				placeholder={placeholder}
				value={this.state.questionText}
				disabled={this.state.questionTextDisabledYN}
				onChange={(event) => this.onQuestionTextChanged(event.target.value)}
				onKeyDown={(event) => this.onKeyDown(event.keyCode)}
			/>
		);

	}
	
	renderIFrame() {
		
		var modelID = this.props.modelID;
		var url = "https://aiapi.plurato.com/chat/index.html?model=" + modelID + "&files=1&header=0";
		
		// onLoad={()=>{console.log("IFRAME ON LOAD")}}
		return (
			<div className="ChatTopContainer">
				<Iframe url={url} width="99%" height="100%" frameBorder="0"></Iframe>
			</div>
		);
		
		/*return (
			<div className="ChatTopContainer">
				<iframe src={url} height="100vh" width="500"/>         
			</div>
		);*/
	}
	
	render() {
		
		var userID = this.props.userID;
		if (typeof(userID) === "undefined") {
			return;
		}
		if (userID === 0) {
			return;
		}
		
		var modelID = this.props.modelID;
		if (modelID === 0) {
			return;
		}

		return this.renderIFrame();
		
		/*return (
			<div className="ChatTopContainer">
				<FileViewPopup
					renderPopupYN={this.state.showFileViewPopupYN}
					baseURLAPI={this.props.baseURLAPI}
					userID={this.props.userID}
					modelID={this.props.modelID}
					filename={this.state.filename}
					questionText={this.state.fileQuestionText}
					similarityScore={this.state.fileSimilarityScore}
					showToast={this.props.showToast}
					setShowFileViewPopupYN={this.setShowFileViewPopupYN}
					getLang={this.props.getLang}
				/>
				<div style={{height: "10px"}}></div>
				{this.renderAllQuestionsAndAnswers()}
				<div style={{height: "30px"}}></div>
				<div>
					{this.renderInput()}
				</div>
				<div style={{height: "10px"}}></div>
			</div>
		);*/
	}
}

export default Chat;


