import React  from 'react';
import './App.css';
import './Model.css';

import { useNavigate } from "react-router-dom";

class ModelNewFromWebSiteJobList extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			modelFromWebSiteListJsonObj: null,
			logCount: 0,
			count: 10,
		};

		this.serverGetModelFromWebSiteList = this.serverGetModelFromWebSiteList.bind(this);
		this.handlePrev = this.handlePrev.bind(this);
		this.handleNext = this.handleNext.bind(this);
		this.handleSelectQuestion = this.handleSelectQuestion.bind(this);
		this.renderOneQuestion = this.renderOneQuestion.bind(this);
		this.renderAllQuestions = this.renderAllQuestions.bind(this);
		this.renderPrevNext = this.renderPrevNext.bind(this);
	}
	
	componentDidMount() {
		var modelFromWebSiteJobListIndex = this.props.modelFromWebSiteJobListIndex;
		this.serverGetModelFromWebSiteList(modelFromWebSiteJobListIndex);
	}
	
	async serverGetModelFromWebSiteList(index) {

		this.setState({
			modelFromWebSiteListJsonObj: null,
		});
		
		var modelID = this.props.modelID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getmodelfromwebsitelist?status=";
		url = url + "&index=" + index + "&count=" + this.state.count;

		const res = await fetch(url);
		const modelFromWebSiteListJsonObj = await res.json();
		var logCount = modelFromWebSiteListJsonObj.length;
		
		this.setState({
			modelFromWebSiteListJsonObj: modelFromWebSiteListJsonObj,
			logCount: logCount,
		});
	}
	
	handlePrev() {
		var modelFromWebSiteJobListIndex = this.props.modelFromWebSiteJobListIndex - 10;
		this.props.setModelFromWebSiteJobListIndex(modelFromWebSiteJobListIndex);
		this.serverGetModelFromWebSiteList(modelFromWebSiteJobListIndex);
	}

	handleNext() {
		var modelFromWebSiteJobListIndex = this.props.modelFromWebSiteJobListIndex + 10;
		this.props.setModelFromWebSiteJobListIndex(modelFromWebSiteJobListIndex);
		this.serverGetModelFromWebSiteList(modelFromWebSiteJobListIndex);
	}
	
	handleSelectQuestion(modelFromWebSiteID) {
		this.props.setSelectedModelFromWebSiteID(modelFromWebSiteID);
		const navigate = this.props.navigate;
  		navigate("/models/newfromwebsitejobview");
	}

	renderOneQuestion(itemObj) {
		
		var modelFromWebSiteID = itemObj.modelFromWebSiteID;
		var jobSubmittedUnixTimeSec = itemObj.jobSubmittedUnixTimeSec;
		var webSite = itemObj.webSite;
		var modelName = itemObj.modelName;
		var status = itemObj.status;
		var fullName = itemObj.fullName;
		
		var submittedString = this.props.getDateTimeStringFromUnixTimeSec(jobSubmittedUnixTimeSec);
		
		return (
			<tr key={modelFromWebSiteID} className="ModelFromWebSiteListItemContainer" onClick={() => this.handleSelectQuestion(modelFromWebSiteID)}> 
				<td className="ModelFromWebSiteListSubmittedItem">{submittedString}</td>
				<td className="ModelFromWebSiteListWebsiteItem">{webSite}</td>
				<td className="ModelFromWebSiteListModelNameItem">{modelName}</td>
				<td className="ModelFromWebSiteListStatusItem">{status}</td>
				<td className="ModelFromWebSiteListUserItem">{fullName}</td>
			</tr>
		);
	}

	renderAllQuestions() {

		var modelFromWebSiteListJsonObj = this.state.modelFromWebSiteListJsonObj;
		if (modelFromWebSiteListJsonObj === null) {
			return;
		}

		var length = modelFromWebSiteListJsonObj.length;
		if (length === 0) {
			return (
				<div className="ModelFromWebSiteListWebsite">
					No questions found.
				</div>
			);
		}
		
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<length; i++) {
			itemObj = modelFromWebSiteListJsonObj[i];
			jsxCode = this.renderOneQuestion(itemObj);
			jsxArray.push(jsxCode);
		}
		
		return (
			<table className="ListContainer2">
				<tbody>
					<tr>
						<td className="ModelFromWebSiteListSubmittedHeader">Submitted</td>
						<td className="ModelFromWebSiteListWebsiteHeader">Website</td>
						<td className="ModelFromWebSiteListModelNameHeader">Model name</td>
						<td className="ModelFromWebSiteListStatusHeader">Status</td>
						<td className="ModelFromWebSiteListUserHeader">User</td>
					</tr>
					{jsxArray}
				</tbody>
			</table>
		);
	}

	renderPrevNext() {
		
		var modelFromWebSiteListJsonObj = this.state.modelFromWebSiteListJsonObj;
		if (modelFromWebSiteListJsonObj === null) {
			return;
		}

		var prevYN = false;
		var nextYN = false;
		
		var modelFromWebSiteJobListIndex = this.props.modelFromWebSiteJobListIndex;
		
		if (modelFromWebSiteJobListIndex > 0) {
			prevYN = true;
		}
		
		if (modelFromWebSiteListJsonObj.length === this.state.count) {
			nextYN = true;
		}
		
		return (
			<div className="ListContainer3">
				{prevYN && (<div 
					className="ListPrevNextLink"
					onClick={() => this.handlePrev()}
				>
					&lt;&lt; Previous
				</div>)}
				{!prevYN && (<div 
					className="ListPrevNextLinkDisabled"
				>
					&lt;&lt; Previous
				</div>)}
				<div className="ListPrevNextLinkDisabled">|</div>
				{nextYN && (<div 
					className="ListPrevNextLink"
					onClick={() => this.handleNext()}
				>
					Next &gt;&gt;
				</div>)}
				{!nextYN && (<div 
					className="ListPrevNextLinkDisabled"
				>
					Next &gt;&gt;
				</div>)}
			</div>
		);
	}
	
	render() {

		var modelFromWebSiteListJsonObj = this.state.modelFromWebSiteListJsonObj;
		if (modelFromWebSiteListJsonObj === null) {
			return;
		}

		return (
			<div>
				<div className="ModelFromWebSiteListTopContainer">
					<div style={{height: "20px"}}></div>
					{this.renderAllQuestions()}
					<div style={{height: "40px"}}></div>
					{this.renderPrevNext()}
				</div>
			</div>
		);
	}
}

export default function ModelNewFromWebSiteJobListFunction(props) {
	const navigate = useNavigate();
	return <ModelNewFromWebSiteJobList {...props} navigate={navigate} />;
}

