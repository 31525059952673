import React  from 'react';
import './App.css';
import './QuestionSample.css';
import QuestionSampleLang from './QuestionSampleLang.json';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ReorderCard } from './ReorderCard.js'

import { useNavigate } from "react-router-dom";

const style = {
  width: 1000,
}

class QuestionSampleCategoryReorder extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			questionSampleCategoryListJsonObj: null,
			cards: null,
		};

		this.serverGetQuestionSampleCategoryList = this.serverGetQuestionSampleCategoryList.bind(this);
		this.serverSetQuestionSampleCategoryOrder = this.serverSetQuestionSampleCategoryOrder.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
		this.moveCard = this.moveCard.bind(this);
		this.saveCards = this.saveCards.bind(this);
		this.renderCard = this.renderCard.bind(this);
	}
	
	componentDidMount() {
		var modelID = this.props.modelID;
		this.serverGetQuestionSampleCategoryList(modelID);
	}

	async serverGetQuestionSampleCategoryList(modelID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getquestionsamplecategorylist?modelid=" + modelID;
		
		const res = await fetch(url);
		const questionSampleCategoryListJsonObj = await res.json();
		
		this.setState({
			questionSampleCategoryListJsonObj: questionSampleCategoryListJsonObj,
		});

		var i;
		var cards = [];
		var itemObj1;
		var itemObj2;
		var questionSampleCategoryID;
		var categoryTitle;

		for(i=0; i<questionSampleCategoryListJsonObj.length; i++) {
			itemObj1 = questionSampleCategoryListJsonObj[i];
			questionSampleCategoryID = itemObj1.questionSampleCategoryID;
			categoryTitle = itemObj1.categoryTitle;
			itemObj2 = {};
			itemObj2.id = questionSampleCategoryID;
			itemObj2.text = categoryTitle;
			cards.push(itemObj2);
		}
		
		this.setState({cards: cards});
	}
	
	async serverSetQuestionSampleCategoryOrder() {

		var i;
		var itemObj;
		var id;
		var cards = this.state.cards;
		var length = cards.length;

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/setquestionsamplecategoryorder?count=" + length;
		
		for(i=0; i<length; i++) {
			itemObj = cards[i];
			id = itemObj.id;
			url = url + "&id" + i + "=" + id;
		}

		const res = await fetch(url);
		await res.text();

		this.props.showToast("Question category order saved");
		const navigate = this.props.navigate;
  		navigate("/samplequestions");
	}
	
	onClickSaveButton() {
		this.serverSetQuestionSampleCategoryOrder();
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/samplequestions");
	}
	
    moveCard(dragIndex, hoverIndex) {

		var cards = this.state.cards;
		var itemObj1 = cards[dragIndex];
		var itemObj;
		var i;
		var cards2 = [];
		if (dragIndex > hoverIndex) {
			for(i=0; i<cards.length; i++) {
				itemObj = cards[i];
				if (i === hoverIndex) {
					cards2.push(itemObj1);
				}
				if (i !== dragIndex) {
					cards2.push(itemObj);
				}
			}
		} else {
			for(i=0; i<cards.length; i++) {
				itemObj = cards[i];
				if (i !== dragIndex) {
					cards2.push(itemObj);
				}
				if (i === hoverIndex) {
					cards2.push(itemObj1);
				}
			}
		}
		
		this.setState({cards: cards2});
    }

    saveCards() {
	}

    renderCard(card, index) {
		return (
			<ReorderCard
				key={card.id}
				index={index}
				id={card.id}
				text={card.text}
				moveCard={this.moveCard}
				saveCards={this.saveCards}
			/>
		);
    }

	render() {

		var cards = this.state.cards;
		if (cards === null) {
			return null;
		}
		
		return (
			<div>
				<div style={{height: "50px"}}></div>

				<div className="QuestionSampleReorderTopContainer">
					<DndProvider backend={HTML5Backend}>
						<div style={style}>{cards.map((card, i) => this.renderCard(card, i))}</div>
					</DndProvider>
				</div>

				<div style={{height: "40px"}}></div>
				<div className="QuestionSampleReorderTopContainer">
					<div className="ViewContainerButtons">
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(QuestionSampleLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(QuestionSampleLang, "save")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>
			</div>
		);
	}
}

export default function QuestionSampleCategoryReorderFunction(props) {
	const navigate = useNavigate();
	return <QuestionSampleCategoryReorder {...props} navigate={navigate} />;
}

