import React  from 'react';
import './App.css';
import './Page.css';
import Iframe from 'react-iframe'

class PageView extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
		};

		this.renderIFrame = this.renderIFrame.bind(this);
	}
	
	componentDidMount() {
	}
	
	renderIFrame() {
		
		var modelID = this.props.modelID;
		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;

		var url = "https://aiapi.plurato.com/chat/viewpage.html?model=" + modelID;
		url = url + "&folder=" + encodeURIComponent(pageFolder);
		url = url + "&subfolder=" + encodeURIComponent(pageSubfolder);
		
		return (
			<div className="PageViewTopContainer">
				<Iframe url={url} width="99%" height="100%" frameBorder="0"></Iframe>
			</div>
		);
	}
	
	render() {
		return this.renderIFrame();
	}
}

export default PageView;

