import React  from 'react';
import './App.css';
import AppLang from './AppLang.json';

class About extends React.Component {

	constructor(props) {

		super(props);

		this.renderOneLine = this.renderOneLine.bind(this);
	}
	
	renderOneLine(dateString, infoText) {
		
		return (
			<div>
				<div className="AboutDateString">{dateString}</div>
				<div className="AboutInfoText">{infoText}</div>
				<div style={{height: "15px"}}></div>
			</div>
		);
	}

	render() {
		return (
			<div>
				<div style={{height: "20px"}}></div>
				<div className="AboutTitle">{this.props.getLang(AppLang, "about")}</div>
				<div style={{height: "20px"}}></div>
				{this.renderOneLine("Android mob app", "https://storageapi.plurato.com/apk/chat.apk")}				
				{this.renderOneLine("14.7.2023.", "Copy model - caution when using this feature! Deleting model takes a lot of time.")}
				{this.renderOneLine("23.6.2023.", "Autogenerated 'lip' videos")}
				{this.renderOneLine("5.6.2023.", "Edit pages")}
				{this.renderOneLine("23.5.2023.", "weaviate.io vector db")}
				{this.renderOneLine("12.5.2023.", "Change log")}
			</div>
		);
	}
}

export default About;

