import React  from 'react';
import './App.css';
import './ChangeLog.css';
import ReactTooltip from 'react-tooltip';

import ChangeLogListToolbar from './ChangeLogListToolbar.js';

import { useNavigate } from "react-router-dom";

class ChangeLogList extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			changeLogListJsonObj: null,
			logCount: 0,
			count: 10,
		};

		this.serverGetChangeLogList = this.serverGetChangeLogList.bind(this);
		this.handlePrev = this.handlePrev.bind(this);
		this.handleNext = this.handleNext.bind(this);
		this.handleSelectLog = this.handleSelectLog.bind(this);
		this.renderOneLog = this.renderOneLog.bind(this);
		this.renderAllLogs = this.renderAllLogs.bind(this);
		this.renderPrevNext = this.renderPrevNext.bind(this);
	}
	
	componentDidMount() {
		var changeLogListIndex = this.props.changeLogListIndex;
		this.serverGetChangeLogList(changeLogListIndex);
	}
	
	async serverGetChangeLogList(index) {

		this.setState({
			changeLogListJsonObj: null,
		});
		
		var modelID = this.props.modelID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getchangeloglist?modelid=" + modelID;
		url = url + "&sortby=createdUnixTimeSec&sortasc=0";
		url = url + "&index=" + index + "&count=" + this.state.count;

		const res = await fetch(url);
		const changeLogListJsonObj = await res.json();
		var logCount = changeLogListJsonObj.length;
		
		this.setState({
			changeLogListJsonObj: changeLogListJsonObj,
			logCount: logCount,
		});
	}
	
	handlePrev() {
		var changeLogListIndex = this.props.changeLogListIndex - 10;
		this.props.setChangeLogListIndex(changeLogListIndex);
		this.serverGetChangeLogList(changeLogListIndex);
	}

	handleNext() {
		var changeLogListIndex = this.props.changeLogListIndex + 10;
		this.props.setChangeLogListIndex(changeLogListIndex);
		this.serverGetChangeLogList(changeLogListIndex);
	}
	
	handleSelectLog(changeLogID) {
		//this.props.setSelectedChangeLogID(changeLogID);
		//const navigate = this.props.navigate;
  		//navigate("/questionlogs/view");
	}

	renderOneLog(itemObj) {
		
		var changeLogID = itemObj.changeLogID;
		var createdUnixTimeSec = itemObj.createdUnixTimeSec;
		var fullName = itemObj.fullName;
		var title = itemObj.title;
		var changeJSON = itemObj.changeJSON;
		
		var createdString = this.props.getDateTimeStringFromUnixTimeSec(createdUnixTimeSec);
		
		var dataTip = changeJSON;
		
		return (
			<tr data-tip={dataTip} key={changeLogID} className="ChangeLogListQuestionItemContainer" onClick={() => this.handleSelectLog(changeLogID)}> 
				<td className="ChangeLogListCreatedItem">{createdString}</td>
				<td className="ChangeLogListTitleItem">{title}</td>
				<td className="ChangeLogListFullNameItem">{fullName}</td>
			</tr>
		);
	}

	renderAllLogs() {

		var changeLogListJsonObj = this.state.changeLogListJsonObj;
		if (changeLogListJsonObj === null) {
			return;
		}

		var length = changeLogListJsonObj.length;
		if (length === 0) {
			return (
				<div className="ChangeLogListQuestionText">
					No questions found.
				</div>
			);
		}
		
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<length; i++) {
			itemObj = changeLogListJsonObj[i];
			jsxCode = this.renderOneLog(itemObj);
			jsxArray.push(jsxCode);
		}
		
		return (
			<table className="ListContainer2">
				<tbody>
					<tr>
						<td className="ChangeLogListCreatedHeader">Date/time</td>
						<td className="ChangeLogListTitleHeader">Title</td>
						<td className="ChangeLogListFullNameHeader">User</td>
					</tr>
					{jsxArray}
				</tbody>
			</table>
		);
	}

	renderPrevNext() {
		
		var changeLogListJsonObj = this.state.changeLogListJsonObj;
		if (changeLogListJsonObj === null) {
			return;
		}

		var prevYN = false;
		var nextYN = false;
		
		var changeLogListIndex = this.props.changeLogListIndex;
		
		if (changeLogListIndex > 0) {
			prevYN = true;
		}
		
		if (changeLogListJsonObj.length === this.state.count) {
			nextYN = true;
		}
		
		return (
			<div className="ListContainer3">
				{prevYN && (<div 
					className="ListPrevNextLink"
					onClick={() => this.handlePrev()}
				>
					&lt;&lt; Previous
				</div>)}
				{!prevYN && (<div 
					className="ListPrevNextLinkDisabled"
				>
					&lt;&lt; Previous
				</div>)}
				<div className="ListPrevNextLinkDisabled">|</div>
				{nextYN && (<div 
					className="ListPrevNextLink"
					onClick={() => this.handleNext()}
				>
					Next &gt;&gt;
				</div>)}
				{!nextYN && (<div 
					className="ListPrevNextLinkDisabled"
				>
					Next &gt;&gt;
				</div>)}
			</div>
		);
	}
	
	render() {

		var changeLogListJsonObj = this.state.changeLogListJsonObj;
		if (changeLogListJsonObj === null) {
			return;
		}

		return (
			<div>
				<ReactTooltip
					type="info"
					delayShow={200}
					className="ChangeLogListTooltip"
				/>
				<ChangeLogListToolbar 
					modelName={this.props.modelName}
					chatAppUserType={this.props.chatAppUserType}
				/>
				<div className="ChangeLogListTopContainer">
					<div style={{height: "20px"}}></div>
					{this.renderAllLogs()}
					<div style={{height: "40px"}}></div>
					{this.renderPrevNext()}
				</div>
			</div>
		);
	}
}

export default function ChangeLogListFunction(props) {
	const navigate = useNavigate();
	return <ChangeLogList {...props} navigate={navigate} />;
}

