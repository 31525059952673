import React  from 'react';
import './App.css';
import './Page.css';
import Dropzone from 'react-dropzone';

import { useNavigate } from "react-router-dom";

class PageEditImage extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			imageURL: "",
			files: [],
			filenameMax: -1,
		};

		this.onDrop = this.onDrop.bind(this);
		this.finishedFileUpload = this.finishedFileUpload.bind(this);
		this.serverGetPageFilenameMax = this.serverGetPageFilenameMax.bind(this);
		this.uploadOneFile = this.uploadOneFile.bind(this);
		this.onClickSaveButtonNewImage = this.onClickSaveButtonNewImage.bind(this);
		this.onClickSaveButtonEditImage = this.onClickSaveButtonEditImage.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
		this.renderOneUploadedImage = this.renderOneUploadedImage.bind(this);
		this.renderAllUploadedImages = this.renderAllUploadedImages.bind(this);
	}

	componentDidMount() {

		var baseURLAPI = this.props.baseURLAPI;
		var modelID = this.props.modelID;
		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;
		var pageFilenameExt = this.props.pageFilenameExt;

		var newYN = this.props.newYN;
		if (newYN) {
			this.serverGetPageFilenameMax(modelID, pageFolder, pageSubfolder);
			return;
		}

		var pageURL = baseURLAPI + "/model/" + modelID + "/pages/" + pageFolder + "/" + pageSubfolder;
		var imageURL = pageURL + "/" + pageFilenameExt;
		this.setState({imageURL: imageURL});
	}

	onDrop(newFiles) {

		var newYN = this.props.newYN;

		if (newFiles.length === 0) {
			return;
		}

		var itemObj;
		var filenameExt;
		var filename;
		var ext;

		if (newYN) {

			var i;
			var files = this.state.files;

			for(i=0; i<newFiles.length; i++) {

				itemObj = newFiles[i];
				filenameExt = itemObj.path;
				
				[filename, ext] = this.props.getFilenameAndExtFromFilenameExt(filenameExt);
				
				if ((ext === "jpg") || (ext === "jpeg") || (ext === "png")) {
					files.push(itemObj);
				}
			}

			this.setState({files: files})
			return;
		}

		if (!newYN) {

			itemObj = newFiles[0];
			filenameExt = itemObj.path;
			
			[filename, ext] = this.props.getFilenameAndExtFromFilenameExt(filenameExt);
			
			if ((ext === "jpg") || (ext === "jpeg") || (ext === "png")) {
				var files2 = [];
				files2.push(itemObj);
				this.setState({files: files2})
			} else {
				var files2 = [];
				files2.push(itemObj);
				this.setState({files: files2})
			}
			
			return;
		}
	}
	
	finishedFileUpload() {
		this.props.showToast("Image section saved");
		const navigate = this.props.navigate;
  		navigate("/page");
	}
	
	async serverGetPageFilenameMax(modelID, pageFolder, pageSubfolder) {

		var baseURLAPI = this.props.baseURLAPI;
		var folder = pageFolder + "/" + pageSubfolder;
		var url = baseURLAPI + "/getpagefilenamemax?modelid=" + modelID;
		url = url + "&folder=" + encodeURIComponent(folder);

		var filenameMax = -1;

		var res = await fetch(url);
		var responseText = "";

		try {
			responseText = await res.text();
			filenameMax = parseInt(responseText);
		} catch {
			filenameMax = -1;
		}

		this.setState({
			filenameMax: filenameMax,
			dataLoadedYN: true,
		});
		
	}	
	
	async uploadOneFile(modelID, pageFolder, pageSubfolder, filename, ext, file, redirectYN) {
		
		var baseURLAPI = this.props.baseURLAPI;
		var sizeBytes = file.size;

		var folder = pageFolder + "/" + pageSubfolder;
		var url = baseURLAPI + "/setpageimagefile?modelid=" + modelID;
		url = url + "&folder=" + encodeURIComponent(folder);
		url = url + "&filename=" + encodeURIComponent(filename);
		url = url + "&ext=" + encodeURIComponent(ext);
		url = url + "&sizebytes=" + sizeBytes;
		
		const formData = new FormData()
		formData.append('file', file)

		fetch(url, {
			method: 'POST',
			body: formData
		}).then(() => {if (redirectYN) {this.finishedFileUpload()}})
	}

	async onClickSaveButtonNewImage() {

		var modelID = this.props.modelID;
		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;
		var pageFilenameExt = this.props.pageFilenameExt;
		
		var files = this.state.files;
		var i;
		var itemObj;
		var newFilenameExt;

		var filename1;
		var ext1;
		var filename2;
		var ext2;
		var redirectYN;
		var filenameMax = this.state.filenameMax;
		
		for(i=0; i<files.length; i++) {

			itemObj = files[i];
			newFilenameExt = itemObj.name;

			[filename2, ext2] = this.props.getFilenameAndExtFromFilenameExt(newFilenameExt);

			filenameMax = filenameMax + 1;
			filename1 = filenameMax;

			redirectYN = false;
			if (i === files.length - 1) {
				redirectYN = true;
			}

			await this.uploadOneFile(modelID, pageFolder, pageSubfolder, filename1, ext2, itemObj, redirectYN);
		}

		var title;
		var changeJsonObj;
		var changeJSON;

		title = "New page image section: " + pageFolder + "/" + pageSubfolder;
		changeJsonObj = {};
		changeJsonObj.section = "pages";
		changeJsonObj.operation = "newimagesection";
		changeJsonObj.pageFolder = pageFolder;
		changeJsonObj.pageSubfolder = pageSubfolder;
		var changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);
	}
	
	async onClickSaveButtonEditImage() {

		var modelID = this.props.modelID;
		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;
		var pageFilenameExt = this.props.pageFilenameExt;
		
		var files = this.state.files;
		var itemObj = files[0];
		var newFilenameExt = itemObj.name;

		var filename1;
		var ext1;
		var filename2;
		var ext2;

		[filename2, ext2] = this.props.getFilenameAndExtFromFilenameExt(newFilenameExt);

		var pageFilenameExt;
		[filename1, ext1] = this.props.getFilenameAndExtFromFilenameExt(pageFilenameExt);

		var redirectYN = true;
		await this.uploadOneFile(modelID, pageFolder, pageSubfolder, filename1, ext2, itemObj, redirectYN);

		var title;
		var changeJsonObj;
		var changeJSON;

		title = "Edit page image section: " + pageFolder + "/" + pageSubfolder;
		changeJsonObj = {};
		changeJsonObj.section = "pages";
		changeJsonObj.operation = "editimagesection";
		changeJsonObj.pageFolder = pageFolder;
		changeJsonObj.pageSubfolder = pageSubfolder;
		var changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);
	}
	
	onClickSaveButton() {

		var newYN = this.props.newYN;
		if (newYN) {
			this.onClickSaveButtonNewImage();
		} else {
			this.onClickSaveButtonEditImage();
		}
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/page");
	}

	renderOneUploadedImage(index, itemObj) {

		var filename = itemObj.path;

		return (
			<div key={index}>
				<div style={{height: "20px"}}></div>
				<div>Uploaded image: {filename}</div>
			</div>
		);
	}
	
	renderAllUploadedImages() {
		
		var files = this.state.files;
		
		if (files.length === 0) {
			return;
		}

		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];

		for(i=0; i<files.length; i++) {
			itemObj = files[i];
			jsxCode = this.renderOneUploadedImage(i, itemObj);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;		
	}

	render() {
		
		var newYN = this.props.newYN;
		var imageURL = this.state.imageURL;

		if ((imageURL === "") && (!newYN)) {
			return;
		}
		
		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;

		var files = this.state.files;
		var saveButtonYN = false;
		if (files.length > 0) {
			saveButtonYN = true;
		}

		var uploadString = "Drag 'n' drop one jpg or png file here, or click to select from folders";
		if (newYN) {
			uploadString = "Drag 'n' drop one or multiple jpg or png files here";
		}

		return (
			<div className="ViewContainer1">
				<div style={{height: "10px"}}></div>
				<div className="PageEditHtmlTitle">{pageFolder}_{pageSubfolder}</div>
				{!newYN && (<div style={{height: "20px"}}></div>)}
				{!newYN && (<img
					className="PageEditHtmlImage"
					src={imageURL}
					border="0"
				/>)}
				<div style={{height: "30px"}}></div>
				<Dropzone 
					onDrop={this.onDrop}
  				>
				{({getRootProps, getInputProps}) => (
				  <section className="PageEditHtmlDragContainer1">
					<div {...getRootProps({className: ''})}>
					  <input {...getInputProps()} />
					  <div className="PageEditHtmlDragContainer2">{uploadString}</div>
					</div>
					<div style={{height: "15px"}}></div>
				  </section>
				)}
				</Dropzone>
				{this.renderAllUploadedImages()}
				<div style={{height: "40px"}}></div>
				<div className="PageEditHtmlDragContainer1">
					<div className="ViewContainerButtons">
						<div style={{width: "40px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>Cancel</button>
						{saveButtonYN && (<div style={{width: "20px"}}></div>)}
						{saveButtonYN && (<button className="ViewSaveButton" onClick={this.onClickSaveButton}>Save</button>)}
					</div>
				</div>
				<div style={{height: "20px"}}></div>
			</div>
		);
	}
}

export default function PageEditImageFunction(props) {
	const navigate = useNavigate();
	return <PageEditImage {...props} navigate={navigate} />;
}
