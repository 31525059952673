import React  from 'react';
import './App.css';
import './Page.css';

import { useNavigate } from "react-router-dom";

class PageEditLink extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			fileText: "",
			linkText: "",
			linkURL: "",
			filenameMax: -1,
			dataLoadedYN: false,
		};

		this.onKeyDown = this.onKeyDown.bind(this);		
		this.extractLinkTextAndURL = this.extractLinkTextAndURL.bind(this);
		this.downloadTextFile = this.downloadTextFile.bind(this);
		this.serverGetPageFilenameMax = this.serverGetPageFilenameMax.bind(this);
		this.serverSetPageTextFile = this.serverSetPageTextFile.bind(this);
		this.onLinkTextChanged = this.onLinkTextChanged.bind(this);
		this.onLinkURLChanged = this.onLinkURLChanged.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}

	componentDidMount() {

		var baseURLAPI = this.props.baseURLAPI;
		var modelID = this.props.modelID;
		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;

		var newYN = this.props.newYN;
		if (newYN) {
			this.serverGetPageFilenameMax(modelID, pageFolder, pageSubfolder);
			return;
		}

		var pageFilenameExt = this.props.pageFilenameExt;
		var pageURL = baseURLAPI + "/model/" + modelID + "/pages/" + pageFolder + "/" + pageSubfolder;
		var fileURL = pageURL + "/" + pageFilenameExt;
		this.downloadTextFile(fileURL);
	}

	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	extractLinkTextAndURL(fileText) {

		var i;
		var ch;
		var lineCount = 0;
		var linkText = "";
		var linkURL = "";

		for(i=0; i<(fileText.length) && (lineCount<2); i++) {
			ch = fileText.charAt(i);
			if (ch === "\n") {
				lineCount = lineCount + 1;
			} else {
				if (lineCount === 0) {
					linkText = linkText + ch;
				}
				if (lineCount === 1) {
					linkURL = linkURL + ch;
				}
			}
		}

		return [linkText, linkURL];
	}

	async downloadTextFile(fileURL) {

		var res = await fetch(fileURL);
		var responseText = "";

		try {
			responseText = await res.text();
		} catch {
			responseText = "";
		}

		if (res.status !== 200) {
			responseText = "";
		}
		
		var linkText;
		var linkURL;
		[linkText, linkURL] = this.extractLinkTextAndURL(responseText);

		this.setState({
			fileText: responseText,
			linkText: linkText,
			linkURL: linkURL,
			dataLoadedYN: true,
		});
	}
	
	async serverGetPageFilenameMax(modelID, pageFolder, pageSubfolder) {

		var baseURLAPI = this.props.baseURLAPI;
		var folder = pageFolder + "/" + pageSubfolder;
		var url = baseURLAPI + "/getpagefilenamemax?modelid=" + modelID;
		url = url + "&folder=" + encodeURIComponent(folder);

		var filenameMax = -1;

		var res = await fetch(url);
		var responseText = "";

		try {
			responseText = await res.text();
			filenameMax = parseInt(responseText);
		} catch {
			filenameMax = -1;
		}

		this.setState({
			filenameMax: filenameMax,
			dataLoadedYN: true,
		});
		
	}
	
	async serverSetPageTextFile(modelID, pageFolder, pageSubfolder, pageFilenameExt, fileText) {
		
		var baseURLAPI = this.props.baseURLAPI;
		var folder = pageFolder + "/" + pageSubfolder;
		var url = baseURLAPI + "/setpagetextfile?modelid=" + modelID;
		url = url + "&folder=" + encodeURIComponent(folder);
		url = url + "&filename=" + encodeURIComponent(pageFilenameExt);
		
		var formData = new FormData();
		formData.append("filetext", fileText);

		const res = await fetch(url, {
			method: 'POST',
			body: formData,
		});		

		await res.text();

		this.props.showToast("Link section saved");
		const navigate = this.props.navigate;
  		navigate("/page");
	}

	onLinkTextChanged(value) {
		this.setState({linkText: value});
	}

	onLinkURLChanged(value) {
		this.setState({linkURL: value});
	}
	
	onClickSaveButton() {
		
		var modelID = this.props.modelID;
		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;

		var newYN = this.props.newYN;
		var pageFilenameExt;
		if (newYN) {
			var filenameMax = this.state.filenameMax;
			filenameMax = filenameMax + 1;
			pageFilenameExt = filenameMax + ".link";
		} else {
			pageFilenameExt = this.props.pageFilenameExt;
		}

		var linkText = this.state.linkText;
		var linkURL = this.state.linkURL;
		var fileText = linkText + "\n" + linkURL + "\n";

		this.serverSetPageTextFile(modelID, pageFolder, pageSubfolder, pageFilenameExt, fileText);

		var title;
		var changeJsonObj;
		var changeJSON;

		if (newYN) {
			title = "New page link section: " + pageFolder + "/" + pageSubfolder;
			changeJsonObj = {};
			changeJsonObj.section = "pages";
			changeJsonObj.operation = "newlinksection";
			changeJsonObj.pageFolder = pageFolder;
			changeJsonObj.pageSubfolder = pageSubfolder;
			changeJsonObj.fileText = fileText;
			var changeJSON = JSON.stringify(changeJsonObj);
			this.props.serverInsertChangeLogRecord(title, changeJSON);
		} else {
			title = "Edit page link section: " + pageFolder + "/" + pageSubfolder;
			changeJsonObj = {};
			changeJsonObj.section = "pages";
			changeJsonObj.operation = "editlinksection";
			changeJsonObj.pageFolder = pageFolder;
			changeJsonObj.pageSubfolder = pageSubfolder;
			changeJsonObj.fileText = fileText;
			var changeJSON = JSON.stringify(changeJsonObj);
			this.props.serverInsertChangeLogRecord(title, changeJSON);
		}
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/page");
	}

	render() {
		
		var dataLoadedYN = this.state.dataLoadedYN;
		if (!dataLoadedYN) {
			return null;
		}

		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;

		return (
			<div className="PageEditLinkContainer">
				<div style={{height: "10px"}}></div>
				<div className="PageEditLinkTitle">{pageFolder}_{pageSubfolder}</div>
				<div style={{height: "20px"}}></div>
				<fieldset className="PageEditLinkFieldset">
					<legend className="PageEditLinkLegend">Link text</legend> 
					<input
						className="PageEditLinkInput" 
						id="linkText" 
						type="text" 
						autoFocus={true}
						value={this.state.linkText}
						onChange={(event) => this.onLinkTextChanged(event.target.value)}
						onKeyDown={(event) => this.onKeyDown(event.keyCode)}
					/>
				</fieldset>
				<div style={{height: "20px"}}></div>
				<fieldset className="PageEditLinkFieldset">
					<legend className="PageEditLinkLegend">Link URL</legend> 
					<input
						className="PageEditLinkInput" 
						id="linkURL" 
						type="text" 
						value={this.state.linkURL}
						onChange={(event) => this.onLinkURLChanged(event.target.value)}
						onKeyDown={(event) => this.onKeyDown(event.keyCode)}
					/>
				</fieldset>
				<div style={{height: "20px"}}></div>
				<div className="ViewContainerButtons">
					<div style={{width: "40px"}}></div>
					<button className="ViewCancelButton" onClick={this.onClickCancelButton}>Cancel</button>
					<div style={{width: "20px"}}></div>
					<button className="ViewSaveButton" onClick={this.onClickSaveButton}>Save</button>
				</div>
				<div style={{height: "20px"}}></div>
			</div>
		);
	}
}

export default function PageEditLinkFunction(props) {
	const navigate = useNavigate();
	return <PageEditLink {...props} navigate={navigate} />;
}
