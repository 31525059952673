import React  from 'react';
import './App.css';
import './Page.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import PageMainToolbar from './PageMainToolbar.js';
import PageView from './PageView.js';
import PageEdit from './PageEdit.js';
import PageReorder from './PageReorder.js';
import PageRename from './PageRename.js';

import { useNavigate } from "react-router-dom";

class PageMain extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			selectedTab: "edit",
			sectionListJsonObj: null,
			sectionArray: [],
			fileTextArray: [],
			dataLoadedYN: false,
		};
		
		this.isStringNumberYN = this.isStringNumberYN.bind(this);
		this.sortSectionArray = this.sortSectionArray.bind(this);
		this.downloadAllTextFiles = this.downloadAllTextFiles.bind(this);
		this.serverGetFolderList = this.serverGetFolderList.bind(this);
		this.serverGetFolderListWithFolderParam = this.serverGetFolderListWithFolderParam.bind(this);
		this.serverDeletePageSubfolder = this.serverDeletePageSubfolder.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.setSelectedTab = this.setSelectedTab.bind(this);
		this.renderTab = this.renderTab.bind(this);
	}
	
	componentDidMount() {
		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;
		var folder = "pages/" + pageFolder + "/" + pageSubfolder;
		this.serverGetFolderListWithFolderParam(folder);
	}
	
	isStringNumberYN(string1) {
		return !isNaN(string1);
	}

	async downloadAllTextFiles(sectionArray, pageURL) {

		var length = sectionArray.length;

		var fileTextArray = [];
		var i;
		var filenameExt;
		var filename;
		var ext;
		var fileURL;

		for(i=0; i<length; i++) {
			fileTextArray.push("");
		}

		for(i=0; i<length; i++) {

			filenameExt = sectionArray[i];

			[filename, ext] = this.props.getFilenameAndExtFromFilenameExt(filenameExt);

			if ((ext === "txt") || (ext === "link") || (ext === "singlejsonsinglejson") || (ext === "pronjson")) {

				var r = Math.floor(Math.random() * 1000000);
				fileURL = pageURL + "/" + filenameExt + "?r=" + r;

				var res = await fetch(fileURL);
				var responseText = "";

				try {
					responseText = await res.text();
				} catch {
					responseText = "";
				}

				if (res.status !== 200) {
					responseText = "";
				}
				
				fileTextArray[i] = responseText;
			}
		}
		
		this.setState({
			fileTextArray: fileTextArray,
			dataLoadedYN: true,
		});
	}
	
	async serverGetFolderList() {
		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;
		var folder = "pages/" + pageFolder + "/" + pageSubfolder;
		this.serverGetFolderListWithFolderParam(folder);
	}

	sortSectionArray(sectionArray) {

		var i;
		var j;
		var sectionArrayTaken = [];
		var sectionArray2 = [];
		var filenameExt;
		var filename;
		var ext;
		var number;
		var minValue;
		var minIndex;
		var minFilenameExt;

		for(i=0; i<sectionArray.length; i++) {
			sectionArrayTaken.push(false);
		}
		
		for(i=0; i<sectionArray.length; i++) {

			minValue = 100000;
			minIndex = -1;
			minFilenameExt = "";

			for(j=0; j<sectionArray.length; j++) {
				if (!sectionArrayTaken[j]) {
					filenameExt = sectionArray[j];
					[filename, ext] = this.props.getFilenameAndExtFromFilenameExt(filenameExt);
					number = parseInt(filename);
					if (number < minValue) {
						minValue = number;
						minIndex = j;
						minFilenameExt = filenameExt;
					}
				}
			}
			
			if (minIndex >= 0) {
				sectionArray2.push(minFilenameExt);
				sectionArrayTaken[minIndex] = true;
			}
		}
		
		return sectionArray2;
	}
		
	async serverGetFolderListWithFolderParam(folder) {
		
		var modelID = this.props.modelID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getfolderlist?modelid=" + modelID + "&folder=" + encodeURIComponent(folder);
		
		const res = await fetch(url);
		const sectionListJsonObj = await res.json();
		
		var i;
		var itemObj;
		var filenameExt;
		var filename;
		var ext;
		var sectionArray = [];
		
		for(i=0; i<sectionListJsonObj.length; i++) {
			itemObj = sectionListJsonObj[i];
			filenameExt = itemObj.filename;
			[filename, ext] = this.props.getFilenameAndExtFromFilenameExt(filenameExt);
			if (this.isStringNumberYN(filename)) {
				if ((ext === "png") || (ext === "jpg") || (ext === "txt") || (ext === "draftraw") || (ext === "html") || (ext === "link") || (ext === "singlejson") || (ext === "pronjson")) {
					sectionArray.push(filenameExt);
				}
			}
		}
		
		sectionArray = this.sortSectionArray(sectionArray);
		
		this.setState({
			sectionListJsonObj: sectionListJsonObj,
			sectionArray: sectionArray,
		});

		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;
		var pageURL = baseURLAPI + "/model/" + modelID + "/pages/" + pageFolder + "/" + pageSubfolder;
		this.downloadAllTextFiles(sectionArray, pageURL);
	}
	
	async serverDeletePageSubfolder(modelID, pageFolder, pageSubfolder) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/deletepagesubfolder?modelid=" + modelID;
		url = url + "&folder=" + encodeURIComponent(pageFolder);
		url = url + "&subfolder=" + encodeURIComponent(pageSubfolder);

		const res = await fetch(url);
		await res.text();
		
		this.props.showToast("Page deleted");
		const navigate = this.props.navigate;
  		navigate("/pages");

		var title;
		var changeJsonObj;
		var changeJSON;

		title = "Delete page: " + pageFolder + "/" + pageSubfolder;
		changeJsonObj = {};
		changeJsonObj.section = "pages";
		changeJsonObj.operation = "deletepage";
		changeJsonObj.pageFolder = pageFolder;
		changeJsonObj.pageSubfolder = pageSubfolder;
		var changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);
	}
	
	handleDelete() {
		
		var modelID = this.props.modelID;
		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;
		var sectionArray = this.state.sectionArray;
		
		if (sectionArray.length > 0) {
			this.props.showToast("Cannot delete page with sections. Please delete all sections first.");
			return;
		}

		confirmAlert({
			title: 'Delete page',
			message: 'Are you sure you want to delete this page?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => this.serverDeletePageSubfolder(modelID, pageFolder, pageSubfolder)
				},
				{
					label: 'No',
				}
			]
		});		
	}
	
	setSelectedTab(tab) {
		if (tab === "delete") {
			this.handleDelete();
			return;
		}
		this.setState({
			selectedTab: tab,
		});
	}
	
	renderTab() {		

		var dataLoadedYN = this.state.dataLoadedYN;
		if (!dataLoadedYN) {
			return;
		}

		var selectedTab = this.state.selectedTab;
		
		if (selectedTab === "view") {
			return (
				<PageView
					baseURLAPI={this.props.baseURLAPI}
					modelID={this.props.modelID}
					pageFolder={this.props.pageFolder}
					pageSubfolder={this.props.pageSubfolder}
					sectionArray={this.state.sectionArray}
					getLang={this.props.getLang}
				/>
			);
		}
		
		if (selectedTab === "edit") {
			return (
				<PageEdit
					baseURLAPI={this.props.baseURLAPI}
					modelID={this.props.modelID}
					modelLearnYN={this.props.modelLearnYN}
					pageFolder={this.props.pageFolder}
					pageSubfolder={this.props.pageSubfolder}
					sectionArray={this.state.sectionArray}
					fileTextArray={this.state.fileTextArray}
					serverGetFolderList={this.serverGetFolderList}
					getFilenameAndExtFromFilenameExt={this.props.getFilenameAndExtFromFilenameExt}
					convertTextNewlineToBr={this.props.convertTextNewlineToBr}
					setSelectedPageFilenameExt={this.props.setSelectedPageFilenameExt}
					serverInsertChangeLogRecord={this.props.serverInsertChangeLogRecord}
					showToast={this.props.showToast}
					getLang={this.props.getLang}
				/>
			);
		}

		if (selectedTab === "reorder") {
			return (
				<PageReorder
					baseURLAPI={this.props.baseURLAPI}
					modelID={this.props.modelID}
					pageFolder={this.props.pageFolder}
					pageSubfolder={this.props.pageSubfolder}
					sectionArray={this.state.sectionArray}
					fileTextArray={this.state.fileTextArray}
					serverGetFolderList={this.serverGetFolderList}
					setSelectedTab={this.setSelectedTab}
					serverInsertChangeLogRecord={this.props.serverInsertChangeLogRecord}
					getFilenameAndExtFromFilenameExt={this.props.getFilenameAndExtFromFilenameExt}
					convertTextNewlineToBr={this.props.convertTextNewlineToBr}
					showToast={this.props.showToast}
					getLang={this.props.getLang}
				/>
			);
		}
		if (selectedTab === "rename") {
			return (
				<PageRename
					baseURLAPI={this.props.baseURLAPI}
					modelID={this.props.modelID}
					pageFolder={this.props.pageFolder}
					pageSubfolder={this.props.pageSubfolder}
					serverGetFolderList={this.serverGetFolderList}
					setSelectedTab={this.setSelectedTab}
					sectionArray={this.state.sectionArray}
					serverInsertChangeLogRecord={this.props.serverInsertChangeLogRecord}
					showToast={this.props.showToast}
					getLang={this.props.getLang}
				/>
			);
		}
	}

	render() {
		return (
			<div>
				<PageMainToolbar 
					modelName={this.props.modelName}
					pageFolder={this.props.pageFolder}
					pageSubfolder={this.props.pageSubfolder}
					chatAppUserType={this.props.chatAppUserType}
					selectedTab={this.state.selectedTab}
					setSelectedTab={this.setSelectedTab}
				/>
				<div style={{height: "1px"}}></div>
				{this.renderTab()}
			</div>
		);
	}
}

export default function PageMainFunction(props) {
	const navigate = useNavigate();
	return <PageMain {...props} navigate={navigate} />;
}


