import React  from 'react';
import './App.css';
import './QuestionSample.css';
import QuestionSampleLang from './QuestionSampleLang.json';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { useNavigate } from "react-router-dom";

class QuestionSampleCategoryEdit extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			categoryTitle: "",
			categoryTitleOriginal: "",
			questionCount: 0,
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.serverGetQuestionSampleCategoryRecord = this.serverGetQuestionSampleCategoryRecord.bind(this);
		this.serverGetQuestionSampleList = this.serverGetQuestionSampleList.bind(this);
		this.serverSetQuestionSampleCategoryTitle = this.serverSetQuestionSampleCategoryTitle.bind(this);
		this.serverDeleteQuestionSampleCategoryRecord = this.serverDeleteQuestionSampleCategoryRecord.bind(this);
		this.onCategoryTitleChanged = this.onCategoryTitleChanged.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
		this.onClickDeleteButton = this.onClickDeleteButton.bind(this);
	}
	
	componentDidMount() {
		var questionSampleCategoryID = this.props.questionSampleCategoryID;
		this.serverGetQuestionSampleCategoryRecord(questionSampleCategoryID);
		this.serverGetQuestionSampleList(questionSampleCategoryID);
	}

	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	async serverGetQuestionSampleCategoryList() {

		var modelID = this.props.modelID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getquestionsamplecategorylist?modelid=" + modelID;
		
		const res = await fetch(url);
		const questionSampleCategoryListJsonObj = await res.json();
		
		this.setState({
			questionSampleCategoryListJsonObj: questionSampleCategoryListJsonObj,
		});
	}
		
	onCategoryTitleChanged(value) {
		this.setState({categoryTitle: value});
	}
	
	async serverGetQuestionSampleCategoryRecord(questionSampleCategoryID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getquestionsamplecategoryrecord?questionsamplecategoryid=" + questionSampleCategoryID;
		
		const res = await fetch(url);
		var jsonObj = await res.json();
		var categoryTitle = jsonObj.categoryTitle;
		
		this.setState({
			categoryTitle: categoryTitle,
			categoryTitleOriginal: categoryTitle,
		});
	}
	
	async serverGetQuestionSampleList(questionSampleCategoryID) {

		var modelID = this.props.modelID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getquestionsamplelist?questionsamplecategoryid=" + questionSampleCategoryID;
		
		const res = await fetch(url);
		var questionSampleListJsonObj = await res.json();
		var questionCount = questionSampleListJsonObj.length;
		
		this.setState({
			questionSampleListJsonObj: questionSampleListJsonObj,
			questionCount: questionCount,
		});
	}

	async serverSetQuestionSampleCategoryTitle(questionSampleCategoryID, categoryTitle) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/setquestionsamplecategorytitle?questionsamplecategoryid=" + questionSampleCategoryID;
		url = url + "&categorytitle=" + encodeURIComponent(categoryTitle);
		
		const res = await fetch(url);
		await res.text();

		this.props.showToast(this.props.getLang(QuestionSampleLang, "questionSampleSaved"));
		const navigate = this.props.navigate;
  		navigate("/samplequestions");
	}
	
	async serverDeleteQuestionSampleCategoryRecord(questionSampleCategoryID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/deletequestionsamplecategoryrecord?questionsamplecategoryid=" + questionSampleCategoryID;
		
		const res = await fetch(url);
		await res.text();

		/*var questionTextOriginal = this.state.questionTextOriginal;
		var title = "Delete sample question: " + questionTextOriginal;
		var changeJsonObj = {};
		changeJsonObj.section = "questionsample";
		changeJsonObj.operation = "delete";
		changeJsonObj.value = questionTextOriginal;
		var changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);*/

		this.props.showToast(this.props.getLang(QuestionSampleLang, "questionDeleted"));

		const navigate = this.props.navigate;
  		navigate("/samplequestions");
	}
		
	onClickSaveButton() {
		
		var categoryTitle = this.state.categoryTitle;
		if (categoryTitle === "") {
			this.props.showToast("Category title cannot be empty");
			return;
		}

		var questionSampleCategoryID = this.props.questionSampleCategoryID;
		this.serverSetQuestionSampleCategoryTitle(questionSampleCategoryID, categoryTitle);

		/*var questionTextOriginal = this.state.questionTextOriginal;
		var title = "Edit sample question: " + questionText;
		var changeJsonObj = {};
		changeJsonObj.section = "questionsample";
		changeJsonObj.operation = "edit";
		changeJsonObj.oldValue = questionTextOriginal;
		changeJsonObj.newValue = questionText;
		var changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);*/
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/samplequestions");
	}
	
	onClickDeleteButton() {

		var questionCount = this.state.questionCount;
		if (questionCount > 0) {
			this.props.showToast("Cannot delete question category with questions. Please delete all questions from this category first.");
			return;
		}

		var questionSampleCategoryID = this.props.questionSampleCategoryID;
		confirmAlert({
			title: 'Delete question',
			message: 'Are you sure you want to delete this question category?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => this.serverDeleteQuestionSampleCategoryRecord(questionSampleCategoryID)
				},
				{
					label: 'No',
				}
			]
		});
	}
	
	render() {		

		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(QuestionSampleLang, "editQuestionSampleCategory")}</div>
				<div className="ViewTopText">{this.props.getLang(QuestionSampleLang, "editQuestionSampleCategoryInfoText")}</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(QuestionSampleLang, "questionCategoryInfo")}</div>
					<div style={{height: "40px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="QuestionSampleEditFieldset">
							<legend className="QuestionSampleEditQuestionSampleLegend">{this.props.getLang(QuestionSampleLang, "categoryTitle")}</legend> 
							<input
								className="QuestionSampleEditQuestionSampleInput" 
								id="categoryTitle" 
								type="text" 
								value={this.state.categoryTitle}
								autoFocus={true}
								onChange={(event) => this.onCategoryTitleChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>

					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "300px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(QuestionSampleLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(QuestionSampleLang, "save")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>
				
				<div style={{height: "50px"}}></div>
				<button className="ViewSaveButton" onClick={this.onClickDeleteButton}>{this.props.getLang(QuestionSampleLang, "deleteQuestionCategory")}</button>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function QuestionSampleCategoryEditFunction(props) {
	const navigate = useNavigate();
	return <QuestionSampleCategoryEdit {...props} navigate={navigate} />;
}

