import React  from 'react';
import './App.css';
import './Model.css';

import { useNavigate } from "react-router-dom";

class SelectModel extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			modelListJsonObj: null,
		};

		this.serverGetModelList = this.serverGetModelList.bind(this);
		this.handleSelectModel = this.handleSelectModel.bind(this);				
		this.renderOneModel = this.renderOneModel.bind(this);				
		this.renderAllModels = this.renderAllModels.bind(this);				
	}
	
	componentDidMount() {
		this.serverGetModelList();
	}
	
	async serverGetModelList() {

		var index = 0;
		var count = 100;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getmodellist?sortby=modelName&sortasc=1";
		url = url + "&index=" + index + "&count=" + count;
		
		const res = await fetch(url);
		const modelListJsonObj = await res.json();
		
		this.setState({
			modelListJsonObj: modelListJsonObj,
		});
	}
	
	handleSelectModel(modelID) {
		this.props.setSelectedModel(modelID);
		const navigate = this.props.navigate;
  		navigate("/chat");
	}

	renderOneModel(itemObj) {
		var modelID = itemObj.modelID;
		var modelName = itemObj.modelName;
		return (
			<div key={modelID} className="SelectModelQuestionText" onClick={() => this.handleSelectModel(modelID)}>
				{modelName}
			</div>
		);
	}

	renderAllModels() {

		var modelListJsonObj = this.state.modelListJsonObj;
		if (modelListJsonObj === null) {
			return;
		}

		var length = modelListJsonObj.length;
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<length; i++) {
			itemObj = modelListJsonObj[i];
			jsxCode = this.renderOneModel(itemObj);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}

	render() {
		return (
			<div className="SelectModelTopContainer">
				<div style={{height: "20px"}}></div>
				{this.renderAllModels()}
			</div>
		);
	}
}

export default function SelectModelFunction(props) {
	const navigate = useNavigate();
	return <SelectModel {...props} navigate={navigate} />;
}

