import React  from 'react';
import './App.css';
import './QuestionSample.css';
import QuestionSampleLang from './QuestionSampleLang.json';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ReorderCard } from './ReorderCard.js'

import { useNavigate } from "react-router-dom";

const style = {
  width: 1000,
}

class QuestionSampleReorder extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			questionSampleListJsonObj: null,
			cards: null,
		};

		this.serverGetQuestionSampleList = this.serverGetQuestionSampleList.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
		this.moveCard = this.moveCard.bind(this);
		this.saveCards = this.saveCards.bind(this);
		this.renderCard = this.renderCard.bind(this);
	}
	
	componentDidMount() {
		var questionSampleCategoryID = this.props.questionSampleCategoryID;
		this.serverGetQuestionSampleList(questionSampleCategoryID);
	}

	async serverGetQuestionSampleList(questionSampleCategoryID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getquestionsamplelist?questionsamplecategoryid=" + questionSampleCategoryID;
		
		const res = await fetch(url);
		const questionSampleListJsonObj = await res.json();
		
		this.setState({
			questionSampleListJsonObj: questionSampleListJsonObj,
		});

		var i;
		var cards = [];
		var itemObj1;
		var itemObj2;
		var questionSampleID;
		var questionText;

		for(i=0; i<questionSampleListJsonObj.length; i++) {
			itemObj1 = questionSampleListJsonObj[i];
			questionSampleID = itemObj1.questionSampleID;
			questionText = itemObj1.questionText;
			itemObj2 = {};
			itemObj2.id = questionSampleID;
			itemObj2.text = questionText;
			cards.push(itemObj2);
		}
		
		this.setState({cards: cards});
	}
	
	async serverSetQuestionSampleOrder() {

		var i;
		var itemObj;
		var id;
		var cards = this.state.cards;
		var length = cards.length;

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/setquestionsampleorder?count=" + length;
		
		for(i=0; i<length; i++) {
			itemObj = cards[i];
			id = itemObj.id;
			url = url + "&id" + i + "=" + id;
		}

		const res = await fetch(url);
		await res.text();

		this.props.showToast("Sample question order saved");
		const navigate = this.props.navigate;
  		navigate("/samplequestions");
	}
	
	onClickSaveButton() {
		this.serverSetQuestionSampleOrder();
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/samplequestions");
	}
	
    moveCard(dragIndex, hoverIndex) {

		var cards = this.state.cards;
		var itemObj1 = cards[dragIndex];
		var itemObj;
		var i;
		var cards2 = [];
		if (dragIndex > hoverIndex) {
			for(i=0; i<cards.length; i++) {
				itemObj = cards[i];
				if (i === hoverIndex) {
					cards2.push(itemObj1);
				}
				if (i !== dragIndex) {
					cards2.push(itemObj);
				}
			}
		} else {
			for(i=0; i<cards.length; i++) {
				itemObj = cards[i];
				if (i !== dragIndex) {
					cards2.push(itemObj);
				}
				if (i === hoverIndex) {
					cards2.push(itemObj1);
				}
			}
		}
		
		this.setState({cards: cards2});
    }

    saveCards() {
	}

    renderCard(card, index) {
		return (
			<ReorderCard
				key={card.id}
				index={index}
				id={card.id}
				text={card.text}
				moveCard={this.moveCard}
				saveCards={this.saveCards}
			/>
		);
    }

	render() {

		var cards = this.state.cards;
		if (cards === null) {
			return null;
		}
		
		return (
			<div>
				<div style={{height: "50px"}}></div>

				<div className="QuestionSampleReorderTopContainer">
					<DndProvider backend={HTML5Backend}>
						<div style={style}>{cards.map((card, i) => this.renderCard(card, i))}</div>
					</DndProvider>
				</div>

				<div style={{height: "40px"}}></div>
				<div className="QuestionSampleReorderTopContainer">
					<div className="ViewContainerButtons">
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(QuestionSampleLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(QuestionSampleLang, "save")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>
			</div>
		);
	}
}

export default function QuestionSampleReorderFunction(props) {
	const navigate = useNavigate();
	return <QuestionSampleReorder {...props} navigate={navigate} />;
}

