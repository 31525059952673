import React  from 'react';
import './App.css';
import './Page.css';

import ReactDOM from 'react-dom';
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';
import { ContentState, Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertFromHTML } from 'draft-convert';
import draftToHtmlPuri from "draftjs-to-html";
import { stateFromHTML } from 'draft-js-import-html';

import { useNavigate } from "react-router-dom";

class PageEditRichText extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			htmlFilenameExt: "",
			fileRaw: "",
			fileHtml: "",
			editorState: EditorState.createEmpty(),
			filenameMax: -1,
			dataLoadedYN: false,
		};

		this.downloadRawFile = this.downloadRawFile.bind(this);
		this.downloadHtmlFile = this.downloadHtmlFile.bind(this);
		this.serverGetPageFilenameMax = this.serverGetPageFilenameMax.bind(this);
		this.serverSetPageTextFile = this.serverSetPageTextFile.bind(this);
		this.setEditorState = this.setEditorState.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}

	componentDidMount() {

		var baseURLAPI = this.props.baseURLAPI;
		var modelID = this.props.modelID;
		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;
		var pageFilenameExt = this.props.pageFilenameExt;
		var pageURL = baseURLAPI + "/model/" + modelID + "/pages/" + pageFolder + "/" + pageSubfolder;

		var filename;
		var ext;
		[filename, ext] = this.props.getFilenameAndExtFromFilenameExt(pageFilenameExt);
		var htmlFilenameExt = filename + ".drafthtml";
		
		this.setState({htmlFilenameExt: htmlFilenameExt});

		var newYN = this.props.newYN;
		if (newYN) {
			this.serverGetPageFilenameMax(modelID, pageFolder, pageSubfolder);
			return;
		}

		var rawURL = pageURL + "/" + pageFilenameExt;
		var htmlURL = pageURL + "/" + htmlFilenameExt;
		this.downloadRawFile(rawURL, htmlURL);
	}
	
	async downloadRawFile(rawURL, htmlURL) {

		var res = await fetch(rawURL);
		var jsonObj = null;

		try {
			jsonObj = await res.json();
		} catch {
			jsonObj = null;
		}
		
		if (res.status !== 200) {
			jsonObj = null;
		}

		if (jsonObj === null) {
			this.downloadHtmlFile(htmlURL);
			return;
		}
		
		var editorState = EditorState.createWithContent(convertFromRaw(jsonObj));
		
		this.setState({
			fileRaw: jsonObj,
			editorState: editorState,
			dataLoadedYN: true,
		});
	}	
	
	async downloadHtmlFile(fileURL) {

		var res = await fetch(fileURL);
		var responseText = "";

		try {
			responseText = await res.text();
		} catch {
			responseText = "";
		}

		if (res.status !== 200) {
			responseText = "";
		}
				
		var editorState = EditorState.createWithContent(convertFromHTML(responseText));
		
		this.setState({
			fileHtml: responseText,
			editorState: editorState,
			dataLoadedYN: true,
		});
	}
	
	async serverGetPageFilenameMax(modelID, pageFolder, pageSubfolder) {

		var baseURLAPI = this.props.baseURLAPI;
		var folder = pageFolder + "/" + pageSubfolder;
		var url = baseURLAPI + "/getpagefilenamemax?modelid=" + modelID;
		url = url + "&folder=" + encodeURIComponent(folder);

		var filenameMax = -1;

		var res = await fetch(url);
		var responseText = "";

		try {
			responseText = await res.text();
			filenameMax = parseInt(responseText);
		} catch {
			filenameMax = -1;
		}

		this.setState({
			filenameMax: filenameMax,
			dataLoadedYN: true,
		});
		
	}
	
	async serverSetPageTextFile(modelID, pageFolder, pageSubfolder, pageFilenameExt, fileText, redirectYN) {
		
		var baseURLAPI = this.props.baseURLAPI;
		var folder = pageFolder + "/" + pageSubfolder;
		var url = baseURLAPI + "/setpagetextfile?modelid=" + modelID;
		url = url + "&folder=" + encodeURIComponent(folder);
		url = url + "&filename=" + encodeURIComponent(pageFilenameExt);
		
		var formData = new FormData();
		formData.append("filetext", fileText);

		const res = await fetch(url, {
			method: 'POST',
			body: formData,
		});		

		await res.text();

		if (redirectYN) {
			this.props.showToast("Rick text section saved");
			const navigate = this.props.navigate;
			navigate("/page");
		}
	}

	setEditorState(editorState) {
		this.setState({editorState: editorState});
	}

	onClickSaveButton() {

		var editorState = this.state.editorState;
		
		var contentState = editorState.getCurrentContent(); 
		var raw = convertToRaw(contentState);
		var html = draftToHtmlPuri(raw);
		var rawJsonString = JSON.stringify(raw);

		var modelID = this.props.modelID;
		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;
		
		var newYN = this.props.newYN;
		var pageFilenameExt;
		var htmlFilenameExt;
		if (newYN) {
			var filenameMax = this.state.filenameMax;
			filenameMax = filenameMax + 1;
			pageFilenameExt = filenameMax + ".draftraw";
			htmlFilenameExt = filenameMax + ".drafthtml";
		} else {
			pageFilenameExt = this.props.pageFilenameExt;
			htmlFilenameExt = this.state.htmlFilenameExt;
		}

		this.serverSetPageTextFile(modelID, pageFolder, pageSubfolder, htmlFilenameExt, html, false);
		this.serverSetPageTextFile(modelID, pageFolder, pageSubfolder, pageFilenameExt, rawJsonString, true);

		var title;
		var changeJsonObj;
		var changeJSON;

		if (newYN) {
			title = "New page rich text section: " + pageFolder + "/" + pageSubfolder;
			changeJsonObj = {};
			changeJsonObj.section = "pages";
			changeJsonObj.operation = "newrichtextsection";
			changeJsonObj.pageFolder = pageFolder;
			changeJsonObj.pageSubfolder = pageSubfolder;
			var changeJSON = JSON.stringify(changeJsonObj);
			this.props.serverInsertChangeLogRecord(title, changeJSON);
		} else {
			title = "Edit page rich text section: " + pageFolder + "/" + pageSubfolder;
			changeJsonObj = {};
			changeJsonObj.section = "pages";
			changeJsonObj.operation = "editrichtextsection";
			changeJsonObj.pageFolder = pageFolder;
			changeJsonObj.pageSubfolder = pageSubfolder;
			var changeJSON = JSON.stringify(changeJsonObj);
			this.props.serverInsertChangeLogRecord(title, changeJSON);
		}
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/page");
	}

	render() {
		
		var dataLoadedYN = this.state.dataLoadedYN;
		if (!dataLoadedYN) {
			return null;
		}

		var pageFolder = this.props.pageFolder;
		var pageSubfolder = this.props.pageSubfolder;

		return (
			<div className="ViewContainer1">
				<div style={{height: "10px"}}></div>
				<div className="PageEditTextTitle">{pageFolder}_{pageSubfolder}</div>
				<div style={{height: "20px"}}></div>
				<Editor 
					editorStyle={{ marginLeft: '10px', marginRight: '10px', height: '420px' }}
					id="fileHtml" 
					editorState={this.state.editorState} 
					onEditorStateChange={this.setEditorState}
					autoFocus={true}
				/>
				<div style={{height: "20px"}}></div>
				<div className="ViewContainerButtons">
					<div style={{width: "40px"}}></div>
					<button className="ViewCancelButton" onClick={this.onClickCancelButton}>Cancel</button>
					<div style={{width: "20px"}}></div>
					<button className="ViewSaveButton" onClick={this.onClickSaveButton}>Save</button>
				</div>
				<div style={{height: "20px"}}></div>
			</div>
		);
	}
}

export default function PageEditRichTextFunction(props) {
	const navigate = useNavigate();
	return <PageEditRichText {...props} navigate={navigate} />;
}
