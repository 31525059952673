import React  from 'react';
import './App.css';
import './Document.css';
import DocumentLang from './DocumentLang.json';

import { useNavigate } from "react-router-dom";

class DocumentEditText extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			txtFileText: "",
			txtFileTextOriginal: "",
		};

		this.serverDownloadFile = this.serverDownloadFile.bind(this);
		this.serverSetDocumentTextFile = this.serverSetDocumentTextFile.bind(this);
		this.servergetLessonList = this.servergetLessonList.bind(this);
		this.serverInsertLessonRecord = this.serverInsertLessonRecord.bind(this);
		this.serverSetLessonData = this.serverSetLessonData.bind(this);
		this.onFileTextChanged = this.onFileTextChanged.bind(this);
		this.findLessonInJson = this.findLessonInJson.bind(this);
		this.separateOneLessonLine = this.separateOneLessonLine.bind(this);
		this.updateLessonTableInDB = this.updateLessonTableInDB.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	componentDidMount() {
		var documentFilename = this.props.documentFilename;
		this.serverDownloadFile(documentFilename);
	}

	onFileTextChanged(value) {
		this.setState({txtFileText: value});
	}
	
	async serverDownloadFile(filename) {

		var modelID = this.props.modelID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/model/" + modelID + "/txt/" + filename + ".txt";

		var res = await fetch(url);
		var txtFileText = await res.text();

		this.setState({
			txtFileText: txtFileText,
			txtFileTextOriginal: txtFileText,
		});
	}
	
	async serverSetDocumentTextFile(modelID, documentFilename, txtFileText) {
		
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/setdocumenttextfile?modelid=" + modelID + "&filename=" + encodeURIComponent(documentFilename);
		
		var formData = new FormData();
		formData.append("txtfiletext", txtFileText);

		const res = await fetch(url, {
			method: 'POST',
			body: formData,
		});		

		await res.text();

		this.props.showToast(this.props.getLang(DocumentLang, "textSaved"));
		const navigate = this.props.navigate;
  		navigate("/docs/view");
	}
	
	async servergetLessonList(companyID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getlessonlist?companyid=" + companyID;

		var res = await fetch(url);
		var jsonObj = await res.json();
		return jsonObj;
	}

	async serverInsertLessonRecord(lessonFolder, lessonSubfolder, companyID, description, questionCount, createdUserID, createdFullName) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/insertlessonrecord?lessonfolder=" + lessonFolder;
		url = url + "&lessonsubfolder=" + encodeURIComponent(lessonSubfolder);
		url = url + "&companyid=" + companyID;
		url = url + "&description=" + encodeURIComponent(description);
		url = url + "&questioncount=" + questionCount;
		url = url + "&createduserid=" + createdUserID;
		url = url + "&createdfullname=" + encodeURIComponent(createdFullName);

		var res = await fetch(url);
		await res.text();
	}

	async serverSetLessonData(lessonID, lessonFolder, lessonSubfolder, description, questionCount, hidden) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/setlessondata?lessonid=" + lessonID;
		url = url + "&lessonfolder=" + encodeURIComponent(lessonFolder);
		url = url + "&lessonsubfolder=" + encodeURIComponent(lessonSubfolder);
		url = url + "&description=" + encodeURIComponent(description);
		url = url + "&questioncount=" + questionCount;
		url = url + "&hidden=" + hidden;

		var res = await fetch(url);
		await res.text();
	}

	separateOneLessonLine(line) {
		
		var i;
		var ch;
		var text1 = "";
		var text2 = "";
		var text1FoundYN = false;
		var folder;
		var subfolder;
		var description;
		
		for(i=0; i<line.length; i++) {
			ch = line.charAt(i);
			
			if ((!text1FoundYN) && (ch === ",")) {
				text1FoundYN = true;
			} else {
				if (text1FoundYN) {
					text2 = text2 + ch;
				} else {
					text1 = text1 + ch;
				}
			}
		}
		
		text1 = text1.trim();
		text2 = text2.trim();
		
		var index = text1.indexOf("_");
		if (index === -1) {
			return ["", "", ""];
		}

		folder = text1.substring(0, index);
		subfolder = text1.substring(index+1, text1.length);

		if (text2.length > 0) {
			if (text2[0] === "\"") {
				text2 = text2.substring(1, text2.length);
			}
		}
		if (text2.length > 0) {
			if (text2[text2.length-1] === "\"") {
				text2 = text2.substring(0, text2.length-1);
			}
		}
		
		description = text2;

		return [folder, subfolder, description];
	}

	findLessonInJson(lessonJsonObj, folder1, subfolder1) {

		folder1 = folder1.toLowerCase();
		subfolder1 = subfolder1.toLowerCase();

		var i;
		var itemObj;
		var folder2;
		var subfolder2;
		
		for(i=0; i<lessonJsonObj.length; i++) {
			
			itemObj = lessonJsonObj[i];
			folder2 = itemObj.lessonFolder;
			subfolder2 = itemObj.lessonSubfolder;

			folder2 = folder2.toLowerCase();
			subfolder2 = subfolder2.toLowerCase();
			
			if ((folder1 === folder2) && (subfolder1 === subfolder2)) {
				return i;
			}
		}
		
		return -1;
	}

	async updateLessonTableInDB(modelID, txtFileText) {
		
		var userID = this.props.userID;
		var fullName = this.props.fullName;
		var companyID = this.props.companyID;
		
		var lessonJsonObj = await this.servergetLessonList(companyID);

		var i;
		var oneLine;
		var lineArray = this.props.separateTextIntoArrayOfLines(txtFileText);
		var folder;
		var subfolder;
		var description;
		var questionCount;
		var fileFolderArray = [];
		var fileSubfolderArray = [];
		var fileDescriptionArray = [];
		var itemIndex;
		var itemObj;
		var lessonID;
		var hidden;
		
		for(i=0; i<lineArray.length; i++) {

			oneLine = lineArray[i];
			oneLine = oneLine.trim();

			if (oneLine !== "") {

				[folder, subfolder, description] = this.separateOneLessonLine(oneLine);
				fileFolderArray.push(folder);
				fileSubfolderArray.push(subfolder);
				fileDescriptionArray.push(description);

				// to do
				questionCount = 0;

				itemIndex = this.findLessonInJson(lessonJsonObj, folder, subfolder);

				if (itemIndex === -1) {
					await this.serverInsertLessonRecord(folder, subfolder, companyID, description, questionCount, userID, fullName);
				} else {
					itemObj = lessonJsonObj[itemIndex];
					lessonID = itemObj.lessonID;
					hidden = 0;
					await this.serverSetLessonData(lessonID, folder, subfolder, description, questionCount, hidden);
				}
			}
		}
	}
	
	async onClickSaveButton() {
		
		var modelID = this.props.modelID;
		var documentFilename = this.props.documentFilename;
		var txtFileText = this.state.txtFileText;

		var modelLearnYN = this.props.modelLearnYN;
		
		if (modelLearnYN) {
			if (documentFilename === "lessons") {
				await this.updateLessonTableInDB(modelID, txtFileText);
			}
		}

		this.serverSetDocumentTextFile(modelID, documentFilename, txtFileText);
		
		var txtFileTextOriginal = this.state.txtFileTextOriginal;
		var title = "Edit document text: " + documentFilename;
		var changeJsonObj = {};
		changeJsonObj.section = "docs";
		changeJsonObj.operation = "edittext";
		changeJsonObj.filename = documentFilename;
		changeJsonObj.newValue = txtFileText.substring(0, 2000);
		changeJsonObj.oldValue = txtFileTextOriginal.substring(0, 2000);
		var changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/docs/view");
	}

	render() {
		return (
			<div className="ViewContainer1">

				<div style={{height: "30px"}}></div>
				<textarea
					className="DocumentEditTextInput" 
					id="txtFileText" 
					type="text" 
					rows={30}
					value={this.state.txtFileText}
					autoFocus={true}
					onChange={(event) => this.onFileTextChanged(event.target.value)}
				/>
				<div style={{height: "20px"}}></div>
				<div className="ViewContainerButtons">
					<div style={{width: "40px"}}></div>
					<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(DocumentLang, "cancel")}</button>
					<div style={{width: "20px"}}></div>
					<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(DocumentLang, "save")}</button>
				</div>
				<div style={{height: "20px"}}></div>
			</div>
		);
	}
}

export default function DocumentEditTextFunction(props) {
	const navigate = useNavigate();
	return <DocumentEditText {...props} navigate={navigate} />;
}

