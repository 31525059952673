import React  from 'react';
import './App.css';
import './Model.css';
import ModelListToolbar from './ModelListToolbar.js';
import { AiOutlineCopy } from "react-icons/ai"
import ReactTooltip from 'react-tooltip';

import { useNavigate } from "react-router-dom";

class ModelList extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			modelListJsonObj: null,
		};

		this.serverGetModelList = this.serverGetModelList.bind(this);
		this.handleSelectModel = this.handleSelectModel.bind(this);
		this.handleCopyModel = this.handleCopyModel.bind(this);
		this.renderOneModel = this.renderOneModel.bind(this);
		this.renderAllModels = this.renderAllModels.bind(this);
	}
	
	componentDidMount() {
		this.serverGetModelList();
	}
	
	componentDidUpdate(prevProps) {

		var modelListJsonObj = this.state.modelListJsonObj;

		if (modelListJsonObj !== null) {
			ReactTooltip.rebuild();
		}
	}

	async serverGetModelList() {

		var index = 0;
		var count = 100;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getmodellist?sortby=modelName&sortasc=1";
		url = url + "&index=" + index + "&count=" + count;
		
		const res = await fetch(url);
		const modelListJsonObj = await res.json();
		
		this.setState({
			modelListJsonObj: modelListJsonObj,
		});
	}
	
	handleSelectModel(modelID) {
		this.props.setSelectedModel(modelID);
		const navigate = this.props.navigate;
  		navigate("/models/edit");
	}
	
	handleCopyModel(modelID) {
		this.props.setSelectedModel(modelID);
		const navigate = this.props.navigate;
  		navigate("/models/copy");
	}

	renderOneModel(itemObj) {
		var modelID = itemObj.modelID;
		var modelName = itemObj.modelName;
		return (
			<div key={modelID} className="ModelListOneModelContainer">
				<div className="ModelListModelName" onClick={() => this.handleSelectModel(modelID)}>
					{modelName}
				</div>
				<div
					className="ModelListCopyIcon"
					onClick={() => this.handleCopyModel(modelID)}
					data-tip="Copy model"
				>
					<AiOutlineCopy style={{ color: "blue", width: "15px", height: "15px" }} />
				</div>
			</div>
		);
	}

	renderAllModels() {

		var modelListJsonObj = this.state.modelListJsonObj;
		if (modelListJsonObj === null) {
			return;
		}

		var length = modelListJsonObj.length;
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<length; i++) {
			itemObj = modelListJsonObj[i];
			jsxCode = this.renderOneModel(itemObj);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}

	render() {
		return (
			<div className="ModelListTopContainer">
				<ReactTooltip
					type="info"
					delayShow={500}
				/>
				<ModelListToolbar 
					modelName={this.props.modelName}
					chatAppUserType={this.props.chatAppUserType}
				/>
				<div style={{height: "20px"}}></div>
				<div>Click on model to edit:</div>
				<div style={{height: "20px"}}></div>
				{this.renderAllModels()}
			</div>
		);
	}
}

export default function ModelListFunction(props) {
	const navigate = useNavigate();
	return <ModelList {...props} navigate={navigate} />;
}

