import React  from 'react';
import './App.css';
import './Document.css';
//import DocumentLang from './DocumentLang.json';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import DocumentViewToolbar from './DocumentViewToolbar.js';

import { useNavigate } from "react-router-dom";

class DocumentView extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			txtFileText: "",
			questionListJsonObj: null,
		};
		
		this.vectorServerGetFilenameQuestionList = this.vectorServerGetFilenameQuestionList.bind(this);
		this.serverDownloadTextFile = this.serverDownloadTextFile.bind(this);
		this.serverDeleteDocument = this.serverDeleteDocument.bind(this);
		this.handleDeleteDocument = this.handleDeleteDocument.bind(this);
		this.renderOneQuestion = this.renderOneQuestion.bind(this);
		this.renderAllQuestions = this.renderAllQuestions.bind(this);
		this.renderFile = this.renderFile.bind(this);
	}
	
	componentDidMount() {
		var modelID = this.props.modelID;
		var filename = this.props.documentFilename;
		this.vectorServerGetFilenameQuestionList(modelID, filename);
		this.serverDownloadTextFile(modelID, filename);
	}
	
	async vectorServerGetFilenameQuestionList(modelID, filename) {

		var vectorBaseURLAPI = this.props.vectorBaseURLAPI;
		var url = vectorBaseURLAPI + "/getfilenamequestionlist?modelid=" + modelID + "&filename=" + encodeURIComponent(filename);
		
		const res = await fetch(url);
		const questionListJsonObj = await res.json();

		this.setState({
			questionListJsonObj: questionListJsonObj,
		});
	}
	
	async serverDownloadTextFile(modelID, filename) {
		
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/model/" + modelID + "/txt/" + filename + ".txt";
		
		var res = await fetch(url);
		var txtFileText = await res.text();

		this.setState({
			txtFileText: txtFileText,
		});
	}
	
	async serverDeleteDocument(modelID, filename) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/deletedocument?modelid=" + modelID + "&filename=" + encodeURIComponent(filename);

		var res = await fetch(url);
		await res.text();

		var title = "Delete document: " + filename;
		var changeJsonObj = {};
		changeJsonObj.section = "docs";
		changeJsonObj.operation = "delete";
		changeJsonObj.filename = filename;
		var changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);

		this.props.showToast("Document deleted");
		const navigate = this.props.navigate;
  		navigate("/docs");
	}
	
	handleDeleteDocument() {
		
		var questionListJsonObj = this.state.questionListJsonObj;
		if (questionListJsonObj.length > 0) {
			this.props.showToast("Cannot delete document - please delete all questions first.");
			return;
		}
		
		var modelID = this.props.modelID;
		var filename = this.props.documentFilename;
		confirmAlert({
			title: 'Delete document',
			message: 'Are you sure you want to delete this document?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => this.serverDeleteDocument(modelID, filename)
				},
				{
					label: 'No',
				}
			]
		});
	}
	
	renderOneQuestion(index, itemObj) {

		var questionText = itemObj.questionText;

		return (
			<div key={index} className="DocumentViewFileText">
				{questionText}
			</div>
		);
	}
	
	renderAllQuestions() {

		var questionListJsonObj = this.state.questionListJsonObj;

		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<questionListJsonObj.length; i++) {
			itemObj = questionListJsonObj[i];
			jsxCode = this.renderOneQuestion(i, itemObj);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}
	
	renderFile() {

		var txtFileText = this.state.txtFileText;

		return (
			<div>
				<div style={{height: "20px"}}></div>
				<div className="DocumentViewFileText">
					{this.props.convertTextNewlineToBr(txtFileText, "txtFileText", "")}
				</div>
			</div>
		);
	}
	
	render() {

		var questionListJsonObj = this.state.questionListJsonObj;
		if (questionListJsonObj === null) {
			return;
		}

		var filename = this.props.documentFilename;

		return (
			<div>
				<DocumentViewToolbar 
					modelName={this.props.modelName}
					chatAppUserType={this.props.chatAppUserType}
					handleDeleteDocument={this.handleDeleteDocument}
				/>
				<div className="DocumentViewTopContainer">
					<div style={{height: "10px"}}></div>
					<div className="DocumentViewFilename">{filename}</div>
					<div style={{height: "10px"}}></div>
					<div className="DocumentViewFileText">
						{this.renderAllQuestions()}
					</div>
					<div style={{height: "20px"}}></div>
					<hr/>
					{this.renderFile()}
					<div style={{height: "100px"}}></div>
				</div>
			</div>
		);
	}
}

export default function DocumentViewFunction(props) {
	const navigate = useNavigate();
	return <DocumentView {...props} navigate={navigate} />;
}

