import React  from 'react';
import './App.css';
import './QuestionSample.css';
import QuestionSampleLang from './QuestionSampleLang.json';

import { useNavigate } from "react-router-dom";

class QuestionSampleCategoryNew extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			categoryTitle: "",
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onQuestionSampleCategoryNameChanged = this.onQuestionSampleCategoryNameChanged.bind(this);
		this.serverInsertQuestionSampleCategoryRecord = this.serverInsertQuestionSampleCategoryRecord.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	onQuestionSampleCategoryNameChanged(value) {
		this.setState({categoryTitle: value});
	}
	
	async serverInsertQuestionSampleCategoryRecord(modelID, categoryTitle) {
		
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/insertquestionsamplecategoryrecord?modelid=" + modelID;
		url = url + "&categorytitle=" + encodeURIComponent(categoryTitle);
		
		const res = await fetch(url);
		await res.text();

		this.props.showToast(this.props.getLang(QuestionSampleLang, "newQuestionSampleCategoryCreated"));
		const navigate = this.props.navigate;
  		navigate("/samplequestions");
	}
		
	onClickSaveButton() {
		
		var categoryTitle = this.state.categoryTitle;
		if (categoryTitle === "") {
			this.props.showToast("Category title cannot be empty");
			return;
		}

		var modelID = this.props.modelID;
		this.serverInsertQuestionSampleCategoryRecord(modelID, categoryTitle);

		/*var title = "New question category: " + categoryTitle;
		var changeJsonObj = {};
		changeJsonObj.section = "questionsamplecategory";
		changeJsonObj.operation = "new";
		changeJsonObj.value = categoryTitle;
		var changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);*/
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/samplequestions");
	}
	
	render() {		

		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(QuestionSampleLang, "newQuestionSampleCategory")}</div>
				<div className="ViewTopText">{this.props.getLang(QuestionSampleLang, "newQuestionSampleCategoryInfoText")}</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(QuestionSampleLang, "questionCategoryInfo")}</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<p></p>
						<fieldset className="QuestionSampleNewFieldset">
							<legend className="QuestionSampleNewQuestionSampleLegend">{this.props.getLang(QuestionSampleLang, "questionSampleCategory")}</legend> 
							<input
								className="QuestionSampleNewQuestionSampleInput" 
								id="questionSample" 
								type="text" 
								autoFocus={true}
								onChange={(event) => this.onQuestionSampleCategoryNameChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(QuestionSampleLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(QuestionSampleLang, "save")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function QuestionSampleCategoryNewFunction(props) {
	const navigate = useNavigate();
	return <QuestionSampleCategoryNew {...props} navigate={navigate} />;
}

