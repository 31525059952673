import React  from 'react';
import './App.css';
import './Model.css';

import { useNavigate } from "react-router-dom";

class ModelEdit extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			modelName: "",
			helloMessage: "",
			systemMessage: "",
			learn: 0,
			loginRequired: 0,
			llmModel: "",
			llmDocCount: 1,
			urlShortcut: "",
			url: "",
			mobAppCode: "",
			head: 0,
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onModelNameChanged = this.onModelNameChanged.bind(this);
		this.onHelloMessageChanged = this.onHelloMessageChanged.bind(this);
		this.onSystemMessageChanged = this.onSystemMessageChanged.bind(this);
		this.onLlmModelChanged = this.onLlmModelChanged.bind(this);		
		this.onLlmDocCountChanged = this.onLlmDocCountChanged.bind(this);		
		this.onUrlShortcutChanged = this.onUrlShortcutChanged.bind(this);		
		this.onUrlChanged = this.onUrlChanged.bind(this);		
		this.onHeadCheckboxClick = this.onHeadCheckboxClick.bind(this);		
		this.onLearnCheckboxClick = this.onLearnCheckboxClick.bind(this);		
		this.onLoginRequiredCheckboxClick = this.onLoginRequiredCheckboxClick.bind(this);		
		this.serverGetModelData = this.serverGetModelData.bind(this);
		this.serverSetModelData = this.serverSetModelData.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	componentDidMount() {
		var modelID = this.props.modelID;
		this.serverGetModelData(modelID);
	}
	
	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	onModelNameChanged(value) {
		this.setState({modelName: value});
	}
	
	onHelloMessageChanged(value) {
		this.setState({helloMessage: value});
	}
	
	onSystemMessageChanged(value) {
		this.setState({systemMessage: value});
	}
	
	onLlmModelChanged(value) {
		var llmModel = value;
		this.setState({llmModel: llmModel});
	}
	
	onLlmDocCountChanged(value) {
		value = parseInt(value);
		if (isNaN(value)) {
			value = 1;
		}
		this.setState({llmDocCount: value});
	}
	
	onUrlShortcutChanged(value) {
		this.setState({urlShortcut: value});
	}
	
	onUrlChanged(value) {
		this.setState({url: value});
	}
	
	onHeadCheckboxClick() {
		var head = this.state.head;
		if (head === 0) {
			head = 1;
		} else {
			head = 0;
		}
		this.setState({head: head});
	}
	
	onLearnCheckboxClick() {
		var learn = this.state.learn;
		if (learn === 0) {
			learn = 1;
		} else {
			learn = 0;
		}
		this.setState({learn: learn});
	}
	
	onLoginRequiredCheckboxClick() {
		var loginRequired = this.state.loginRequired;
		if (loginRequired === 0) {
			loginRequired = 1;
		} else {
			loginRequired = 0;
		}
		this.setState({loginRequired: loginRequired});
	}
	
	async serverGetModelData(modelID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getmodeldata?modelid=" + modelID;

		const res = await fetch(url);
		var jsonObj = await res.json();

		var modelName = jsonObj.modelName;
		var helloMessage = jsonObj.helloMessage;
		var systemMessage = jsonObj.systemMessage;
		var learn = jsonObj.learn;
		var loginRequired = jsonObj.loginRequired;
		var llmModel = jsonObj.llmModel;
		var llmDocCount = jsonObj.llmDocCount;
		var urlShortcut = jsonObj.urlShortcut;
		var url = jsonObj.url;
		var mobAppCode = jsonObj.mobAppCode;
		var head = jsonObj.head;
		
		this.setState({
			modelName: modelName,
			helloMessage: helloMessage,
			systemMessage: systemMessage,
			learn: learn,
			loginRequired: loginRequired,
			llmModel: llmModel,
			llmDocCount: llmDocCount,
			urlShortcut: urlShortcut,
			url: url,
			mobAppCode: mobAppCode,
			head: head,
		});
	}
	
	async serverSetModelData(modelID, modelName, helloMessage, systemMessage, learn, loginRequired, llmModel, llmDocCount, urlShortcut, modelUrl, head) {
		
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/setmodeldata?modelid=" + modelID;
		url = url + "&modelname=" + encodeURIComponent(modelName);
		url = url + "&hellomessage=" + encodeURIComponent(helloMessage);
		url = url + "&systemmessage=" + encodeURIComponent(systemMessage);
		url = url + "&learn=" + learn;
		url = url + "&loginrequired=" + loginRequired;
		url = url + "&llmmodel=" + encodeURIComponent(llmModel);
		url = url + "&llmdoccount=" + llmDocCount;
		url = url + "&urlshortcut=" + encodeURIComponent(urlShortcut);
		url = url + "&url=" + encodeURIComponent(modelUrl);
		url = url + "&head=" + head;

		const res = await fetch(url);
		await res.text();

		this.props.showToast("Model saved");
		const navigate = this.props.navigate;
  		navigate("/models");
	}
		
	onClickSaveButton() {
		
		var modelName = this.state.modelName;
		modelName = modelName.trim();
		if (modelName === "") {
			this.props.showToast("Model name cannot be empty");
			return;
		}

		var modelID = this.props.modelID;
		var helloMessage = this.state.helloMessage;
		var systemMessage = this.state.systemMessage;
		var learn = this.state.learn;
		var loginRequired = this.state.loginRequired;
		var llmModel = this.state.llmModel;
		var llmDocCount = this.state.llmDocCount;
		var urlShortcut = this.state.urlShortcut;
		var url = this.state.url;
		var head = this.state.head;
		this.serverSetModelData(modelID, modelName, helloMessage, systemMessage, learn, loginRequired, llmModel, llmDocCount, urlShortcut, url, head);

		var title;
		var changeJsonObj;
		var changeJSON;

		title = "Edit model: " + modelName;
		changeJsonObj = {};
		changeJsonObj.section = "model";
		changeJsonObj.operation = "edit";
		changeJsonObj.modelID = modelID;
		changeJsonObj.modelName = modelName;
		var changeJSON = JSON.stringify(changeJsonObj);
		this.props.serverInsertChangeLogRecord(title, changeJSON);
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/models");
	}
	
	render() {
		
		var headCheckedYN = false;
		if (this.state.head === 1) {
			headCheckedYN = true;
		}
		
		var learnCheckedYN = false;
		if (this.state.learn === 1) {
			learnCheckedYN = true;
		}
		
		var loginRequiredCheckedYN = false;
		if (this.state.loginRequired === 1) {
			loginRequiredCheckedYN = true;
		}

		var chatgptCheckedYN = false;
		var llama27bCheckedYN = false;
		if (this.state.llmModel === "chatgpt-3.5") {
			chatgptCheckedYN = true;
		} else {
			llama27bCheckedYN = true;
		}

		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">Edit model</div>
				<div className="ViewTopText">Enter model data and press Save button</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">Model info</div>

					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelNewFieldset">
							<legend className="ModelNewLegend">Model ID</legend> 
							<input
								className="ModelNewInput" 
								id="modelID" 
								type="text" 
								value={this.props.modelID}
								disabled={true}
							/>
						</fieldset>
					</div>

					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelNewFieldset">
							<legend className="ModelNewLegend">Mob app code</legend> 
							<input
								className="ModelNewInput" 
								id="mobAppCode" 
								type="text" 
								value={this.state.mobAppCode}
								disabled={true}
							/>
						</fieldset>
					</div>
					
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelNewFieldset">
							<legend className="ModelNewLegend">Model name</legend> 
							<input
								className="ModelNewInput" 
								id="modelName" 
								type="text" 
								value={this.state.modelName}
								autoFocus={true}
								onChange={(event) => this.onModelNameChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>

					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelNewFieldset">
							<legend className="ModelNewLegend">Hello message</legend> 
							<textarea
								className="ModelNewInput" 
								id="helloMessage" 
								type="text" 
								value={this.state.helloMessage}
								rows={5}
								onChange={(event) => this.onHelloMessageChanged(event.target.value)}
							/>
						</fieldset>
					</div>

					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelNewFieldset">
							<legend className="ModelNewLegend">System message</legend> 
							<input
								className="ModelNewInput" 
								id="systemMessage" 
								type="text" 
								value={this.state.systemMessage}
								onChange={(event) => this.onSystemMessageChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>

					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelNewFieldset">
							<legend className="ModelNewLegend">LLM Model</legend> 
							<input 
								type="checkbox" 
								className="ViewItemCheckbox" 
								id="chatgpt"
								checked={chatgptCheckedYN}
								onChange={(event) => this.onLlmModelChanged("chatgpt-3.5")}
							/>
							<label htmlFor="chatgpt" className="ViewItemCheckboxLabel">ChatGPT 3.5</label>
							<div style={{height: "1px"}}></div>
							<input 
								type="checkbox" 
								className="ViewItemCheckbox" 
								id="llama27b"
								checked={llama27bCheckedYN}
								onChange={(event) => this.onLlmModelChanged("llama2-7b")}
							/>
							<label htmlFor="llama27b" className="ViewItemCheckboxLabel">Llama2 7b</label>
						</fieldset>
					</div>

					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelNewFieldset">
							<legend className="ModelNewLegend">LLM doc count</legend> 
							<input
								className="ModelNewInput" 
								id="llmDocCount" 
								type="text" 
								value={this.state.llmDocCount}
								onChange={(event) => this.onLlmDocCountChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>

					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelNewFieldset">
							<legend className="ModelNewLegend">URL</legend> 
							<input
								className="ModelNewInput" 
								id="url" 
								type="text" 
								value={this.state.url}
								onChange={(event) => this.onUrlChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>

					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelNewFieldset">
							<legend className="ModelNewLegend">URL shortcut</legend> 
							<input
								className="ModelNewInput" 
								id="urlShortcut" 
								type="text" 
								value={this.state.urlShortcut}
								onChange={(event) => this.onUrlShortcutChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>

					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ModelNewFieldset">
							<legend className="ModelNewLegend">Other</legend> 
							<input 
								type="checkbox" 
								className="ViewItemCheckbox" 
								id="head"
								checked={headCheckedYN}
								onChange={(event) => this.onHeadCheckboxClick()}
							/>
							<label htmlFor="head" className="ViewItemCheckboxLabel">Head</label>
							<div style={{height: "1px"}}></div>
							<input 
								type="checkbox" 
								className="ViewItemCheckbox" 
								id="learn"
								checked={learnCheckedYN}
								onChange={(event) => this.onLearnCheckboxClick()}
							/>
							<label htmlFor="learn" className="ViewItemCheckboxLabel">Learn</label>
							<div style={{height: "1px"}}></div>
							<input 
								type="checkbox" 
								className="ViewItemCheckbox" 
								id="loginRequired"
								checked={loginRequiredCheckedYN}
								onChange={(event) => this.onLoginRequiredCheckboxClick()}
							/>
							<label htmlFor="loginRequired" className="ViewItemCheckboxLabel">Login required</label>
						</fieldset>
					</div>

					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>Cancel</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>Save</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function ModelEditFunction(props) {
	const navigate = useNavigate();
	return <ModelEdit {...props} navigate={navigate} />;
}

