import React  from 'react';
import './App.css';
import { useNavigate } from "react-router-dom";

class QuestionSampleListToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(page) {		
		const navigate = this.props.navigate;
  		navigate(page);
	}
	
	renderLink(renderYN, linkTitle, linkValue) {
		
		if (!renderYN) {
			return;
		}

		var classNameString = "QuestionSampleListToolbarLink";
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	render() {

		var modelName = this.props.modelName;
		var chatAppUserType = this.props.chatAppUserType;
		
		var newQuestionString = "New question";
		var reorderQuestionsString = "Reorder questions"
		var newCategoryString = "New category";
		var editCategoryString = "Edit category";
		var reorderCategoryString = "Reorder categories"

		var showRightYN = false;
		if (chatAppUserType === 100) {
			showRightYN = true;
		}
		var modelUserType = this.props.modelUserType;
		if (modelUserType >= 100) {
			showRightYN = true;
		}
		
		return (
			<div className="QuestionSampleListToolbarContainer1">
				<div className="QuestionSampleListToolbarLeft">
					<div 
						className="QuestionSampleListToolbarLink"
						onClick={() => this.handleSelectLink("/selectmodel")}
					>
						{modelName}
					</div>
				</div>
				<div className="QuestionSampleListToolbarRight">
					{showRightYN && (
						<div className="QuestionSampleListToolbarContainer2">
							{this.renderLink(true, newQuestionString, "/samplequestions/new")}
							<div className="DocumentViewToolbarText">|</div>
							{this.renderLink(true, reorderQuestionsString, "/samplequestions/reorder")}
							<div className="DocumentViewToolbarText">|</div>
							{this.renderLink(true, newCategoryString, "/samplequestioncategories/new")}
							<div className="DocumentViewToolbarText">|</div>
							{this.renderLink(true, editCategoryString, "/samplequestioncategories/edit")}
							<div className="DocumentViewToolbarText">|</div>
							{this.renderLink(true, reorderCategoryString, "/samplequestioncategories/reorder")}
						</div>
					)}
					{!showRightYN && (
						<div className="QuestionSampleListToolbarContainer2">
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default function QuestionSampleListToolbarFunction(props) {
	const navigate = useNavigate();
	return <QuestionSampleListToolbar {...props} navigate={navigate} />;
}

